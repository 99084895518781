import Vue from 'vue'
import VueRouter from 'vue-router'

import AuthLogin from '@/views/AuthLogin'
import AuthLogout from '@/views/AuthLogout'

import Home from '@/views/Home'

import User from '@/views/User'
import UserProfile from '@/views/UserProfile'
import UserSettings from '@/views/UserSettings'
import UserStudies from '@/views/UserStudies'
import UserCourses from '@/views/UserCourses'
import UserOffers from '@/views/UserOffers'
import UserEvents from '@/views/UserEvents'

import Users from '@/views/Users'

import Studies from '@/views/Studies'

import StudiesUniversity from '@/views/StudiesUniversity'
import StudiesFaculty from '@/views/StudiesFaculty'
import StudiesDegree from '@/views/StudiesDegree'
import StudiesCourse from '@/views/StudiesCourse'

import StudiesUniversityAdd from '@/views/StudiesUniversityAdd'
import StudiesFacultyAdd from '@/views/StudiesFacultyAdd'
import StudiesDegreeAdd from '@/views/StudiesDegreeAdd'
import StudiesCourseAdd from '@/views/StudiesCourseAdd'

import Domain from '@/views/Domain'
import Domains from '@/views/Domains'
import DomainsAdd from '@/views/DomainsAdd'

import GoogleDrives from '@/views/GoogleDrives'
import GoogleDriveAdd from '@/views/GoogleDriveAdd'

import Emails from '@/views/Emails'

import Company from '@/views/Company'
import CompanyProfile from '@/views/CompanyProfile'
import CompanySettings from '@/views/CompanySettings'
import CompanyOffers from '@/views/CompanyOffers'
import CompanyEvents from '@/views/CompanyEvents'
import CompanySubscriptions from '@/views/CompanySubscriptions'
import CompanyInvoiceVerify from '@/views/CompanyInvoiceVerify'

import Companies from '@/views/Companies'

import Offer from '@/views/Offer'
import OfferAbout from '@/views/OfferAbout'
import OfferBenefits from '@/views/OfferBenefits'
import OfferNeeds from '@/views/OfferNeeds'
import OfferActivity from '@/views/OfferActivity'
import OfferApplications from '@/views/OfferApplications'
import OfferTalentPool from '@/views/OfferTalentPool'

import Offers from '@/views/Offers'
import OfferCreate from '@/views/OfferCreate'

import Event from '@/views/Event'
import EventFeed from '@/views/EventFeed'
import EventDescription from '@/views/EventDescription'
import EventActivity from '@/views/EventActivity'
import EventAttendances from '@/views/EventAttendances'

import Events from '@/views/Events'
import EventCreate from '@/views/EventCreate'

import Subscription from '@/views/Subscription'
import Subscriptions from '@/views/Subscriptions'

import Configuration from '@/views/Configuration'

import ErrorView from '@/views/ErrorView'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/auth/login',
		name: 'AuthLogin',
		component: AuthLogin,
		meta: { requiresGuest: true }
	},
	{
		path: '/auth/logout',
		name: 'AuthLogout',
		component: AuthLogout
	},
	{
		path: '/users',
		name: 'Users',
		component: Users,
		meta: { requiresAuth: true }
	},
	{
		path: '/users/:id',
		name: 'User',
		component: User,
		meta: { requiresAuth: true },
		redirect: { name: 'UserProfile' },
		children: [
			{ path: 'profile', name: 'UserProfile', component: UserProfile, meta: { requiresAuth: true } },
			{ path: 'settings', name: 'UserSettings', component: UserSettings, meta: { requiresAuth: true } },
			{ path: 'studies', name: 'UserStudies', component: UserStudies, meta: { requiresAuth: true } },
			{ path: 'courses', name: 'UserCourses', component: UserCourses, meta: { requiresAuth: true } },
			{ path: 'offers', name: 'UserOffers', component: UserOffers, meta: { requiresAuth: true } },
			{ path: 'events', name: 'UserEvents', component: UserEvents, meta: { requiresAuth: true } }
		]
	},
	{
		path: '/studies',
		name: 'Studies',
		component: Studies,
		meta: { requiresAuth: true }
	},
	{
		path: '/university/add',
		name: 'StudiesUniversityAdd',
		component: StudiesUniversityAdd,
		meta: { requiresAuth: true }
	},
	{
		path: '/university/:id',
		name: 'StudiesUniversity',
		component: StudiesUniversity,
		meta: { requiresAuth: true }
	},
	{
		path: '/faculty/add',
		name: 'StudiesFacultyAdd',
		component: StudiesFacultyAdd,
		meta: { requiresAuth: true }
	},
	{
		path: '/faculty/:id',
		name: 'StudiesFaculty',
		component: StudiesFaculty,
		meta: { requiresAuth: true }
	},
	{
		path: '/degree/add',
		name: 'StudiesDegreeAdd',
		component: StudiesDegreeAdd,
		meta: { requiresAuth: true }
	},
	{
		path: '/degree/:id',
		name: 'StudiesDegree',
		component: StudiesDegree,
		meta: { requiresAuth: true }
	},
	{
		path: '/course/add',
		name: 'StudiesCourseAdd',
		component: StudiesCourseAdd,
		meta: { requiresAuth: true }
	},
	{
		path: '/course/:id',
		name: 'StudiesCourse',
		component: StudiesCourse,
		meta: { requiresAuth: true }
	},
	{
		path: '/domains',
		name: 'Domains',
		component: Domains,
		meta: { requiresAuth: true }
	},
	{
		path: '/domains/:id',
		name: 'Domain',
		component: Domain,
		meta: { requiresAuth: true }
	},
	{
		path: '/domains/add',
		name: 'DomainsAdd',
		component: DomainsAdd,
		meta: { requiresAuth: true }
	},
	{
		path: '/gDrives',
		name: 'GoogleDrives',
		component: GoogleDrives,
		meta: { requiresAuth: true }
	},
	{
		path: '/gDrives/add',
		name: 'GoogleDriveAdd',
		component: GoogleDriveAdd,
		meta: { requiresAuth: true }
	},
	{
		path: '/emails',
		name: 'Emails',
		component: Emails,
		meta: { requiresAuth: true }
	},
	{
		path: '/companies',
		name: 'Companies',
		component: Companies,
		meta: { requiresAuth: true }
	},
	{
		path: '/companies/:id',
		name: 'Company',
		component: Company,
		meta: { requiresAuth: true },
		redirect: { name: 'CompanyProfile' },
		children: [
			{ path: 'profile', name: 'CompanyProfile', component: CompanyProfile, meta: { requiresAuth: true } },
			{ path: 'settings', name: 'CompanySettings', component: CompanySettings, meta: { requiresAuth: true } },
			{ path: 'offers', name: 'CompanyOffers', component: CompanyOffers, meta: { requiresAuth: true } },
			{ path: 'events', name: 'CompanyEvents', component: CompanyEvents, meta: { requiresAuth: true } },
			{ path: 'subscriptions', name: 'CompanySubscriptions', component: CompanySubscriptions, meta: { requiresAuth: true } },
			{ path: 'invoice/verify', name: 'CompanyInvoiceVerify', component: CompanyInvoiceVerify, meta: { requiresAuth: true } }
		]
	},
	{
		path: '/offers',
		name: 'Offers',
		component: Offers,
		meta: { requiresAuth: true }
	},
	{
		path: '/offers/:id',
		name: 'Offer',
		component: Offer,
		meta: { requiresAuth: true },
		redirect: { name: 'OfferAbout' },
		children: [
			{ path: 'about', name: 'OfferAbout', component: OfferAbout, meta: { requiresAuth: true } },
			{ path: 'benefits', name: 'OfferBenefits', component: OfferBenefits, meta: { requiresAuth: true } },
			{ path: 'needs', name: 'OfferNeeds', component: OfferNeeds, meta: { requiresAuth: true } },
			{ path: 'activity', name: 'OfferActivity', component: OfferActivity, meta: { requiresAuth: true } },
			{ path: 'applications', name: 'OfferApplications', component: OfferApplications, meta: { requiresAuth: true } },
			{ path: 'talentPool', name: 'OfferTalentPool', component: OfferTalentPool, meta: { requiresAuth: true } }
		]
	},
	{
		path: '/offers/create',
		name: 'OfferCreate',
		component: OfferCreate,
		meta: { requiresAuth: true }
	},
	{
		path: '/events',
		name: 'Events',
		component: Events,
		meta: { requiresAuth: true }
	},
	{
		path: '/events/:id',
		name: 'Event',
		component: Event,
		meta: { requiresAuth: true },
		redirect: { name: 'EventFeed' },
		children: [
			{ path: 'feed', name: 'EventFeed', component: EventFeed, meta: { requiresAuth: true } },
			{ path: 'description', name: 'EventDescription', component: EventDescription, meta: { requiresAuth: true } },
			{ path: 'activity', name: 'EventActivity', component: EventActivity, meta: { requiresAuth: true } },
			{ path: 'attendances', name: 'EventAttendances', component: EventAttendances, meta: { requiresAuth: true } }
		]
	},
	{
		path: '/events/create',
		name: 'EventCreate',
		component: EventCreate,
		meta: { requiresAuth: true }
	},
	{
		path: '/subscriptions',
		name: 'Subscriptions',
		component: Subscriptions,
		meta: { requiresAuth: true }
	},
	{
		path: '/subscriptions/:id',
		name: 'Subscription',
		component: Subscription,
		meta: { requiresAuth: true }
	},
	{
		path: '/configuration',
		name: 'Configuration',
		component: Configuration,
		meta: { requiresAuth: true }
	},
	{
		path: '/error/:type',
		name: 'Error',
		component: ErrorView,
		props: true
	},
	{
		path: '/*',
		redirect: { name: 'Error', params: { type: '404' } }
	}
]

const router = new VueRouter({
	linkActiveClass: 'active',
	linkExactActiveClass: 'active',
	mode: 'history',
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	}
})

router.beforeEach((to, from, next) => {
	document.title = to.name

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!store.getters['auth/authenticated']) {
			next({
				name: 'AuthLogin',
				query: { redirect: to.fullPath }
			})
		} else {
			next()
		}
	}
	if (to.matched.some((record) => record.meta.requiresGuest)) {
		if (store.getters['auth/authenticated']) {
			next({ name: 'Home' })
		} else {
			next()
		}
	}

	next()
})

export default router
