<template>
	<v-card :loading="loading" color="orange darken-3" max-width="300" rounded="xl">
		<v-card-title>
			<span class="text-h5">Users (30 days)</span>
			<v-spacer />
			<v-btn icon :loading="loading" @click="fetchActiveUsers()">
				<v-icon>mdi-sync</v-icon>
			</v-btn>
		</v-card-title>
		<v-card-text class="text-center">
			<span class="text-body-1 font-weight-black">
				<v-progress-circular v-if="loading" indeterminate color="red" />
				{{ activeUsersNum }}
			</span>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'StatsUsersActive',
	data() {
		return {
			loading: true,
			activeUsersNum: 0
		}
	},
	created() {
		this.fetchActiveUsers()
	},
	methods: {
		fetchActiveUsers() {
			this.loading = true
			this.fetchStats('active_users_num')
				.then(({ success, data }) => {
					if (success) {
						this.activeUsersNum = data
					}
				})
				.then(() => {
					this.loading = false
				})
		},
		...mapActions('users', ['fetchStats'])
	}
}
</script>
