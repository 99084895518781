<template>
	<v-card class="mt-4" rounded="xl">
		<v-card-title>
			<v-row>
				<v-col sm="9">
					<span style="word-break: normal">{{ domain.name }} </span>
				</v-col>
				<v-col sm="3" class="d-flex justify-end">
					<v-btn icon :to="{ name: 'Domain', params: { id: domain.id } }">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-card-title>
	</v-card>
</template>

<script>
export default {
	name: 'CollapseDomain',
	props: {
		domain: {
			type: Object,
			required: true
		}
	}
}
</script>
