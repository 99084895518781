<template>
	<v-container fluid>
		<UniversityInfo :loading-parent="loading" />

		<UniversityFaculties :loading-parent="loading" />
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UniversityInfo from '@/components/universities/UniversityInfo.vue'
import UniversityFaculties from '@/components/universities/UniversityFaculties.vue'

export default {
	metaInfo() {
		return {
			title: this.$t('universities.university') + ' - ' + this.university.name
		}
	},
	data() {
		return {
			loading: true
		}
	},
	components: {
		UniversityInfo,
		UniversityFaculties
	},
	computed: {
		...mapGetters({
			university: 'universities/university'
		})
	},
	watch: {
		'$route.params.id': function(id) {
			this.loading = true
			this.fetchUniversity(id).then(() => {
				this.loading = false
			})
		}
	},
	created() {
		this.fetchUniversity(this.$route.params.id).then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('universities', ['fetchUniversity'])
	}
}
</script>

<style scoped></style>
