<template>
	<v-container fluid>
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('nav.communications') }}</span>
		</div>

		<v-divider class="my-2" />

		<ProgressBar :loading="loading" />

		<v-row class="mt-2">
			<v-col cols="12" xl="3" md="4" class="d-flex flex-column">
				<v-card class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
					<v-card-title>
						<span class="text-h5">{{ $t('nav.emails') }} Comunicados</span>
					</v-card-title>
					<v-card-text>
						<v-list rounded>
							<v-list-item v-for="item in emailsCommunique" :key="`email-${item.key}`" @click="prepareSendingEmail(item)">
								<v-list-item-icon>
									<v-icon></v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title v-text="item.name" />
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" xl="3" md="4" class="d-flex flex-column">
				<v-card class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
					<v-card-title>
						<span class="text-h5">{{ $t('nav.emails') }} SOS</span>
					</v-card-title>
					<v-card-text>
						<v-subheader>SOS Semester Start</v-subheader>
						<v-list rounded dense>
							<v-list-item v-for="item in emailsSOSSemesterStart" :key="`email-${item.key}`" @click="prepareSendingEmail(item)">
								<v-list-item-icon>
									<v-icon></v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title v-text="item.name" />
								</v-list-item-content>
							</v-list-item>
						</v-list>

						<v-subheader>Exams Final Start</v-subheader>
						<v-list rounded dense>
							<v-list-item v-for="item in emailsSOSExamsFinalStart" :key="`email-${item.key}`" @click="prepareSendingEmail(item)">
								<v-list-item-icon>
									<v-icon></v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title v-text="item.name" />
								</v-list-item-content>
							</v-list-item>
						</v-list>

						<v-subheader>Exams Final End</v-subheader>
						<v-list rounded dense>
							<v-list-item v-for="item in emailsSOSExamsFinalEnd" :key="`email-${item.key}`" @click="prepareSendingEmail(item)">
								<v-list-item-icon>
									<v-icon></v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title v-text="item.name" />
								</v-list-item-content>
							</v-list-item>
						</v-list>

						<v-subheader>SOS Exams Mid Term Start</v-subheader>
						<v-list rounded dense>
							<v-list-item v-for="item in emailsSOSExamsMidTermStart" :key="`email-${item.key}`" @click="prepareSendingEmail(item)">
								<v-list-item-icon>
									<v-icon></v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title v-text="item.name" />
								</v-list-item-content>
							</v-list-item>
						</v-list>

						<v-subheader>SOS Exams Mid Term End</v-subheader>
						<v-list rounded dense>
							<v-list-item v-for="item in emailsSOSExamsMidTermEnd" :key="`email-${item.key}`" @click="prepareSendingEmail(item)">
								<v-list-item-icon>
									<v-icon></v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title v-text="item.name" />
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" xl="3" md="4" class="d-flex flex-column">
				<v-card class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
					<v-card-title>
						<span class="text-h5">{{ $t('nav.emails') }} OverSOS Network</span>
					</v-card-title>
				</v-card>
			</v-col>

			<v-col cols="12" xl="3" md="4" class="d-flex flex-column">
				<v-card class="mx-auto flex-grow-1 flex-shrink-0 d-flex flex-column" style="width: 100%" rounded="xl">
					<v-card-title>
						<span class="text-h5">Newsletter</span>
					</v-card-title>
					<v-card-text>
						<v-subheader>{{ $t('nav.users') }}</v-subheader>
						<v-list rounded dense>
							<v-list-item v-for="item in newsletterUsers" :key="`email-${item.key}`" @click="prepareSendingNewsletter(item)">
								<v-list-item-icon>
									<v-icon></v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title v-text="item.name" />
								</v-list-item-content>
							</v-list-item>
						</v-list>

						<v-subheader>Employers</v-subheader>
						<v-list rounded dense>
							<v-list-item v-for="item in newsletterUsers" :key="`email-${item.key}`" @click="prepareSendingNewsletter(item)">
								<v-list-item-icon>
									<v-icon></v-icon>
								</v-list-item-icon>

								<v-list-item-content>
									<v-list-item-title v-text="item.name" />
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-dialog v-model="confirmEmail" persistent max-width="600px">
			<v-card rounded="xl">
				<v-card-title>
					{{ $t('settings.confirm') }}
				</v-card-title>
				<v-card-text>
					<p>Enviar emails</p>
					<p>{{ emailType ? emailType.name : 'Error - Ask Alex' }}</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn color="secondary" @click="cancelSendingEmail()">
						{{ $t('search.close') }}
					</v-btn>
					<v-btn color="primary" :loading="loading" :disabled="loading" @click="callSendEmails()">
						{{ $t('contact.send') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="confirmNewsletter" persistent max-width="600px">
			<v-card rounded="xl">
				<v-card-title>
					{{ $t('settings.confirm') }}
				</v-card-title>
				<v-card-text>
					<p>Enviar newsletter</p>
					<p>{{ newsletter ? newsletter.name : 'Error - Ask Alex' }}</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn color="secondary" @click="cancelSendingNewsletter()">
						{{ $t('search.close') }}
					</v-btn>
					<v-btn color="primary" :loading="loading" :disabled="loading" @click="callSendNewsletter()">
						{{ $t('contact.send') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: 'Emails'
		}
	},
	data() {
		return {
			loading: false,
			emailType: null,
			newsletter: null,
			confirmEmail: false,
			confirmNewsletter: false
		}
	},
	components: {
		ProgressBar: () => import('@/components/feedback/ProgressBar.vue')
	},
	computed: {
		emailsCommunique() {
			return this.availableEmailTypes.filter((type) => type.key.startsWith('test') || type.key.startsWith('communique_'))
		},
		emailsSOSSemesterStart() {
			return this.availableEmailTypes.filter((type) => type.key.startsWith('semester_start_'))
		},
		emailsSOSExamsFinalStart() {
			return this.availableEmailTypes.filter((type) => type.key.startsWith('exams_final_start_'))
		},
		emailsSOSExamsFinalEnd() {
			return this.availableEmailTypes.filter((type) => type.key.startsWith('exams_final_end_'))
		},
		emailsSOSExamsMidTermStart() {
			return this.availableEmailTypes.filter((type) => type.key.startsWith('exams_mid_term_start_'))
		},
		emailsSOSExamsMidTermEnd() {
			return this.availableEmailTypes.filter((type) => type.key.startsWith('exams_mid_term_end_'))
		},
		newsletterUsers() {
			return this.availableNewsletters.filter((type) => type.key.startsWith('users_'))
		},
		newsletterEmployers() {
			return this.availableNewsletters.filter((type) => type.key.startsWith('employers_'))
		},
		...mapGetters({
			availableEmailTypes: 'data/availableEmailTypes',
			availableNewsletters: 'data/availableNewsletters'
		})
	},
	created() {
		this.fetchAvailableEmailTypes()
		this.fetchAvailableNewsletters()
	},
	methods: {
		prepareSendingEmail(emailType) {
			this.confirmEmail = true
			this.emailType = emailType
		},
		cancelSendingEmail() {
			this.confirmEmail = false
			this.emailType = null
		},
		callSendEmails() {
			if (!this.emailType) {
				this.confirmEmail = false
				return
			}
			this.loading = true
			return this.sendEmails({ emailType: this.emailType.key }).then(() => {
				this.loading = false
				this.confirmEmail = false
			})
		},
		prepareSendingNewsletter(newsletter) {
			this.confirmNewsletter = true
			this.newsletter = newsletter
		},
		cancelSendingNewsletter() {
			this.confirmNewsletter = false
			this.newsletter = null
		},
		callSendNewsletter() {
			if (!this.newsletter) {
				this.confirmNewsletter = false
				return
			}
			this.loading = true
			return this.sendNewsletter({ newsletterID: this.newsletter.key }).then(() => {
				this.loading = false
				this.confirmNewsletter = false
			})
		},
		...mapActions('emails', ['sendEmails', 'sendNewsletter']),
		...mapActions('data', ['fetchAvailableEmailTypes', 'fetchAvailableNewsletters'])
	}
}
</script>
