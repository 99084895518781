<template>
	<v-container fluid>
		<h2>{{ $t('gdrive.addNewGDriveAccount') }}</h2>

		<v-form ref="form" v-model="valid">
			<v-text-field v-model="email" type="email" :label="$t('auth.email')" :counter="emailMaxLength" :rules="rules.email" required outlined />
		</v-form>
		<v-card-actions>
			<v-btn color="primary" @click="createNew()">
				{{ $t('settings.add') }}
			</v-btn>
			<v-btn color="primary" @click="createNewAndAnother()">
				{{ $t('settings.addAndAnother') }}
			</v-btn>
		</v-card-actions>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('gdrive.addNewGDriveAccount')
		}
	},
	data: function() {
		return {
			valid: false,
			email: '',
			rules: {
				email: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.indexOf('@') !== 0) || this.$t('rules.emailUsername'),
					(v) => (v && v.includes('@')) || this.$t('rules.emailAtSymbol'),
					(v) => (v && v.includes('.')) || this.$t('rules.emailPeriodSymbol'),
					(v) => (v && v.indexOf('.') <= v.length - 3) || this.$t('rules.emailDomain'),
					(v) => (v && /.+@.+\..+/.test(v)) || this.$t('rules.emailValid'),
					(v) => (v && v.length <= this.emailMaxLength) || this.$t('rules.length', { length: this.emailMaxLength })
				]
			},
			remainAfterwards: false
		}
	},
	computed: {
		emailMaxLength() {
			return 128
		}
	},
	methods: {
		createNew() {
			this.$refs.form.validate()
			if (!this.valid) return
			return this.addGDrive({
				email: this.email
			}).then((data) => {
				if (data.success && !this.remainAfterwards) {
					this.$router.push({ name: 'GoogleDrives' })
				}
				return data
			})
		},
		createNewAndAnother() {
			this.remainAfterwards = true
			this.createNew().then((data) => {
				if (data.success) {
					this.remainAfterwards = false
					window.scrollTo(0, 0)
				}
			})
		},
		...mapActions('gdrives', ['addGDrive'])
	}
}
</script>

<style scoped></style>
