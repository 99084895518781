export const namespaced = true

export const state = () => ({ gDrives: [], gDrive: null })

export const getters = {
	gDrives: (state) => state.gDrives,
	gDrive: (state) => state.gDrive
}

export const actions = {
	fetchGDrives({ commit, dispatch }) {
		commit('SET_GDRIVES', [])
		return this.$api
			.get('/gdrives')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_GDRIVES', data.gDrives)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchGDrive({ commit, dispatch }, gDriveID) {
		commit('SET_GDRIVE', null)
		return this.$api
			.get(`/gdrives/${gDriveID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_GDRIVE', data.gDrive)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	addGDrive({ dispatch }, email) {
		return this.$api
			.put('/gdrives/add', {
				email: email
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_GDRIVES: (state, gDrives) => (state.gDrives = gDrives),
	SET_GDRIVE: (state, gDrive) => (state.gDrive = gDrive)
}
