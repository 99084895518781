export const namespaced = true

export const state = () => ({ student: null, students: [] })

export const getters = {
	student: (state) => state.student,
	students: (state) => state.students
}

export const actions = {
	fetchStudents({ commit, dispatch }, { page, perPage }) {
		commit('SET_STUDENTS', [])
		return this.$api
			.get('/students', {
				params: {
					page,
					perPage
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_STUDENTS', data.students)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchStudentsByDegree({ commit, dispatch }, { degreeID, page, perPage }) {
		commit('SET_STUDENTS', [])
		return this.$api
			.get(`/students/degree/${degreeID}`, {
				params: {
					page,
					perPage
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_STUDENTS', data.students)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchStudentsByCourse({ commit, dispatch }, { courseID, page, perPage }) {
		commit('SET_STUDENTS', [])
		return this.$api
			.get(`/students/course/${courseID}`, {
				params: {
					page,
					perPage
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_STUDENTS', data.students)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_STUDENT: (state, student) => (state.student = student),
	SET_STUDENTS: (state, students) => (state.students = students)
}
