<template>
	<v-container fluid style="max-width: 1400px">
		<ProgressBar :loading="loading" />
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('universities.universities') }}</span>
		</div>

		<v-divider class="my-2" />

		<v-row>
			<v-col>
				<v-btn color="info" :to="{ name: 'StudiesUniversityAdd' }" class="float-right">
					{{ $t('universities.addNewUniversity') }}
				</v-btn>
			</v-col>
		</v-row>

		<UniversityCard v-for="university in universities" :key="university.id" :university="university" />
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('universities.universities')
		}
	},
	components: {
		UniversityCard: () => import('@/components/universities/UniversityCard.vue'),
		ProgressBar: () => import('@/components/feedback/ProgressBar.vue')
	},
	data() {
		return {
			loading: false
		}
	},
	computed: {
		...mapGetters({
			universities: 'universities/universities'
		})
	},
	created() {
		if (!this.universities.length) {
			this.loading = true
			this.fetchUniversities().then(() => {
				this.loading = false
			})
		}
	},
	methods: {
		...mapActions('universities', ['fetchUniversities'])
	}
}
</script>

<style scoped></style>
