<template>
	<v-container fluid>
		<h2>{{ $t('domain.editDomain') }}</h2>

		<v-form ref="form" v-model="valid">
			<v-text-field v-model="name" :label="$t('auth.name')" :counter="nameMaxLength" :rules="rules.name" required outlined />
			<v-textarea
				v-model="description"
				:label="$t('courses.description')"
				:counter="descriptionMaxLength"
				:rules="rules.description"
				required
				auto-grow
				outlined
			/>
			<v-checkbox v-model="studentOnly" :label="$t('domains.studentOnly')" />
		</v-form>
		<v-card-actions>
			<v-btn color="primary" @click="callEditDomain()">
				{{ $t('settings.edit') }}
			</v-btn>
		</v-card-actions>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('domain.editDomain')
		}
	},
	data: function() {
		return {
			valid: false,
			name: '',
			description: '',
			studentOnly: false,
			rules: {
				name: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.includes('.')) || this.$t('rules.emailPeriodSymbol'),
					(v) => (v && v.indexOf('.') <= v.length - 3) || this.$t('rules.emailDomain'),
					(v) => (v && /.+\..+/.test(v)) || this.$t('rules.emailValid'),
					(v) => (v && v.length <= this.nameMaxLength) || this.$t('rules.length', { length: this.nameMaxLength })
				],
				description: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.descriptionMaxLength) || this.$t('rules.length', { length: this.descriptionMaxLength })
				]
			},
			remainAfterwards: false
		}
	},
	watch: {
		'$route.params.id': function(id) {
			this.fetchDomain(id)
				.then(({ domain }) => {
					this.name = domain.name
					this.description = domain.description
					this.studentOnly = domain.studentOnly
				})
				.then(() => {
					this.loading = false
				})
		}
	},
	computed: {
		nameMaxLength() {
			return 128
		},
		descriptionMaxLength() {
			return 256
		}
	},
	created() {
		this.fetchDomain(this.$route.params.id)
			.then(({ domain }) => {
				this.name = domain.name
				this.description = domain.description
				this.studentOnly = domain.studentOnly
			})
			.then(() => {
				this.loading = false
			})
	},
	methods: {
		createNew() {
			this.$refs.form.validate()
			if (!this.valid) return
			return this.editDomain({
				name: this.name,
				description: this.description,
				studentOnly: this.studentOnly
			}).then((data) => {
				if (data.success && !this.remainAfterwards) {
					this.$router.push({ name: 'Domains' })
				}
				return data
			})
		},
		...mapActions('domains', ['fetchDomain', 'editDomain'])
	}
}
</script>
