export const namespaced = true

export const state = () => ({
	settings: []
})

export const getters = {
	settings: (state) => state.settings
}

export const actions = {
	fetchSettings({ commit, dispatch }) {
		commit('SET_SETTINGS', [])
		return this.$api
			.get('/config')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SETTINGS', data.settings)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	setSetting({ dispatch }, setting) {
		return this.$api
			.post(`/config/${setting.id}/set`, {
				value: setting.value
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_SETTINGS: (state, settings) => (state.settings = settings)
}
