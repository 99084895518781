<template>
	<v-container fluid>
		<h2>Google Drives</h2>

		<collapseGDrive v-for="gDrive in gDrives" :key="gDrive.id" :g-drive="gDrive" />
		<v-btn color="primary" :to="{ name: 'GoogleDriveAdd' }">
			{{ $t('gdrive.addNewGDriveAccount') }}
		</v-btn>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import collapseGDrive from '@/components/collapse/collapseGDrive.vue'

export default {
	metaInfo() {
		return {
			title: 'Google Drives'
		}
	},
	data() {
		return {
			loading: true
		}
	},
	components: {
		collapseGDrive
	},
	computed: {
		...mapGetters({
			gDrives: 'gdrives/gDrives'
		})
	},
	created() {
		this.fetchGDrives().then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('gdrives', ['fetchGDrives'])
	}
}
</script>

<style scoped></style>
