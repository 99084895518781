<template>
	<v-navigation-drawer :value="opened" fixed app color="sidebarBackground" @input="toogle">
		<div class="d-flex align-center px-10 py-2">
			<div class="flex-shrink-1">
				<v-btn plain fab active-class="" :to="{ name: 'Home' }">
					<v-avatar size="48" class="mr-4">
						<v-img src="@/assets/images/icon/logo-oversos-second-1x.png" />
					</v-avatar>
				</v-btn>
			</div>
			<span class="text-h5" style="font-family: Tahoma">OverSOS</span>
		</div>
		<v-list v-if="authenticated" nav dense rounded class="pl-8 pr-5">
			<v-list-item :to="{ name: 'Users' }" v-if="$vuetify.breakpoint.lgAndUp">
				<v-list-item-icon>
					<v-icon>mdi-account-supervisor</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $t('nav.users') }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item :to="{ name: 'Emails' }">
				<v-list-item-icon>
					<v-icon>mdi-account-voice</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $t('nav.communications') }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<v-list-item :to="{ name: 'Configuration' }" v-if="$vuetify.breakpoint.lgAndUp">
				<v-list-item-icon>
					<v-icon>mdi-cog</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $t('nav.configuration') }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>

			<template>
				<v-subheader>OverSOS Network</v-subheader>

				<v-list-item :to="{ name: 'Companies' }" v-if="$vuetify.breakpoint.lgAndUp">
					<v-list-item-icon>
						<v-icon>mdi-domain</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t('nav.companies') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item :to="{ name: 'Offers' }">
					<v-list-item-icon>
						<v-icon>mdi-briefcase</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t('offers.offers') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item :to="{ name: 'Events' }">
					<v-list-item-icon>
						<v-icon>mdi-calendar</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t('events.events') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item :to="{ name: 'Subscriptions' }">
					<v-list-item-icon>
						<v-icon>mdi-contactless-payment-circle</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t('subscriptions.subscriptions') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>

			<template>
				<v-subheader>SOS</v-subheader>

				<v-list-item :to="{ name: 'Studies' }" v-if="$vuetify.breakpoint.lgAndUp">
					<v-list-item-icon>
						<v-icon>mdi-school</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t('universities.universities') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item :to="{ name: 'GoogleDrives' }">
					<v-list-item-icon>
						<v-icon>mdi-google-drive</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Google Drive</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item :to="{ name: 'Domains' }">
					<v-list-item-icon>
						<v-icon>mdi-at</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>{{ $t('nav.domains') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
		</v-list>

		<v-footer padless fixed absolute color="sidebarBackground">
			<template v-if="$vuetify.breakpoint.lgAndUp">
				<div class="d-flex align-center px-3 py-3" style="width: 100%">
					<div class="flex-grow-0">
						<v-avatar size="48" class="mr-4">
							<v-img :src="currentUser.imgURL" />
						</v-avatar>
					</div>
					<div class="mr-2 d-flex flex-column flex-grow-1">
						<strong>{{ currentUser.name.first }}</strong>
						<small>{{ currentUser.name.last }}</small>
					</div>
					<div class="flex-grow-0">
						<SidebarMenu />
					</div>
				</div>
			</template>
			<template v-else>
				<v-row justify="center" no-gutters>
					<v-col class="py-1 text-center" cols="3">
						<v-btn icon small href="https://instagram.com/oversos.official" target="_blank">
							<v-icon>mdi-instagram</v-icon>
						</v-btn>
					</v-col>
					<v-col class="py-1 text-center" cols="3">
						<v-btn icon small href="https://facebook.com/oversos" target="_blank">
							<v-icon>mdi-facebook</v-icon>
						</v-btn>
					</v-col>
					<v-col class="py-1 text-center" cols="3">
						<v-btn icon small href="https://twitter.com/oversosofficial" target="_blank">
							<v-icon>mdi-twitter</v-icon>
						</v-btn>
					</v-col>
					<v-col class="py-1 text-center" cols="3">
						<v-btn icon small href="https://linkedin.com/company/oversos" target="_blank">
							<v-icon>mdi-linkedin</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				<v-row justify="center" no-gutters>
					<v-col class="py-1 text-center" cols="12"> {{ new Date().getFullYear() }} — &copy; <strong>OverSOS</strong> </v-col>
				</v-row>
			</template>
		</v-footer>
	</v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'Sidebar',
	data() {
		return {
			selectedItem: null
		}
	},
	components: {
		SidebarMenu: () => import('@/components/main/SidebarMenu.vue')
	},
	computed: {
		...mapGetters({
			authenticated: 'auth/authenticated',
			currentUser: 'user/currentUser',
			opened: 'sidebar/opened'
		})
	},
	beforeMount() {
		if (this.$vuetify.breakpoint.mdAndDown & this.opened) {
			this.close()
		} else if (this.$vuetify.breakpoint.lgAndUp & !this.opened) {
			this.open()
		}
	},
	methods: {
		...mapActions('sidebar', ['open', 'close', 'toogle'])
	}
}
</script>

<style scoped>
.v-list--nav.v-list--dense .v-list-item:not(:last-child):not(:only-child) {
	margin-bottom: 1px !important;
}
</style>
