<template>
	<v-container fluid style="max-width: 1400px">
		<v-alert border="top" elevation="8" type="warning" color="orange darken-3" max-width="500" dark class="mx-auto text-uppercase text-center">
			This feature is still in beta!
		</v-alert>

		<v-row>
			<v-col>
				<v-btn color="info" :to="{ name: 'EventCreate' }" class="float-right">
					{{ $t('offers.create') }}
				</v-btn>
			</v-col>
		</v-row>

		<div class="d-flex align-center">
			<v-icon class="mx-4">
				mdi-magnify
			</v-icon>
			<v-text-field v-model="title" :placeholder="$t('search.search')" />
			<v-dialog v-model="filtersEditMode" max-width="1600px">
				<template v-slot:activator="{ on }">
					<v-btn color="secondary" v-on="on" elevation="2" rounded small text>
						<v-icon class="mx-4">
							mdi-filter
						</v-icon>
						{{ $t('search.filter') }}
					</v-btn>
				</template>
				<v-card rounded="xl">
					<v-card-title>
						<span class="headline">{{ $t('search.filters') }}</span>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12" md="3" sm="12">
								<v-select
									v-model="filters.status"
									:label="$t('applications.status')"
									:items="statusOptions"
									item-text="name"
									item-value="key"
									chips
									multiple
									return-object
									outlined
								>
									<template v-slot:selection="data">
										<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
											{{ data.item.name }}
										</v-chip>
									</template>
								</v-select>
							</v-col>
							<v-col cols="12" md="6" sm="12">
								<v-select
									v-model="filters.eventTypes"
									:label="$t('events.eventType')"
									:items="availableEventTypes"
									item-text="name"
									item-value="id"
									chips
									multiple
									return-object
									outlined
								>
									<template v-slot:selection="data">
										<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
											{{ data.item.name }}
										</v-chip>
									</template>
								</v-select>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn color="blue darken-1" text @click="filtersSwitchEditMode()">
							{{ $t('search.close') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>

		<v-fade-transition group hide-on-leave>
			<template v-if="loadingEvent">
				<v-skeleton-loader v-for="i in 5" :key="i" type="list-item-avatar-three-line" class="mx-auto mb-5" max-width="1200" />
			</template>

			<template v-else-if="events.length">
				<template v-for="event in filteredEvents">
					<MyEventCard :key="event.id" :event="event" />
				</template>

				<template v-if="!filteredEvents.length">
					<h4 class="m-4 text-center" key="noResultsCriteria">
						{{ $t('search.noResultsCriteria') }}
					</h4>
				</template>
			</template>

			<template v-else>
				<div key="noItemsData" class="mx-auto">
					<div class="mt-2 mb-5 text-center">
						<v-icon size="128" role="img">
							mdi-package-variant
						</v-icon>
						<br />
						<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
					</div>
					<h4 class="m-4 text-center">
						{{ $t('events.myEventsHelp') }}
					</h4>
				</div>
			</template>
		</v-fade-transition>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function(letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function(match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	metaInfo() {
		return {
			title: this.$t('events.myEvents')
		}
	},
	data() {
		return {
			filtersEditMode: false,
			title: '',
			statusOptions: [
				{ key: '0', name: this.$t('offers.canceled') },
				{ key: '1', name: this.$t('offers.active') },
				{ key: '2', name: this.$t('events.finished') }
			],
			filters: {
				status: [],
				eventTypes: []
			}
		}
	},
	components: {
		MyEventCard: () => import('@/components/events/MyEventCard.vue')
	},
	watch: {
		company(val) {
			if (val) {
				this.setLoadingEvent(true)
				this.fetchEventsCompany({ companyID: val.id }).then(() => {
					this.setLoadingEvent(false)
				})
			}
		}
	},
	computed: {
		filteredEvents() {
			return this.events.filter(
				(event) =>
					(!this.filters.eventTypes.length || this.filters.eventTypes.map((e) => e.id).includes(event.type.id)) &&
					(!this.filters.status.length || this.filters.status.map((e) => e.key).includes(event.status.value)) &&
					removeAccents(event.title)
						.toLowerCase()
						.includes(removeAccents(this.title).toLowerCase())
			)
		},
		...mapGetters({
			company: 'companies/company',
			events: 'events/events',
			loadingEvent: 'events/loading',
			availableEventTypes: 'events/eventTypes'
		})
	},
	created() {
		this.fetchEventTypes()
		if (this.company?.id) {
			this.setLoadingEvent(true)
			this.fetchEventsCompany({ companyID: this.company.id }).then(() => {
				this.setLoadingEvent(false)
			})
		}
	},
	methods: {
		filtersSwitchEditMode() {
			this.filtersEditMode = !this.filtersEditMode
		},
		...mapActions('events', ['setLoadingEvent', 'fetchEventsCompany', 'fetchEventTypes'])
	}
}
</script>
