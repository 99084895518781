<template>
	<v-container fluid style="max-width: 1200px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('studies.studies') }}</span>
		</div>

		<v-divider class="my-2" />

		<Degrees :studies="studies" :student-emails="studentEmails" />
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.user.name.full
		}
	},
	data() {
		return {
			loading: false
		}
	},
	components: {
		Degrees: () => import('@/components/studies/Degrees.vue')
	},
	watch: {
		user(val) {
			if (val) {
				this.loading = true
				this.fetchStudentEmails({ userID: val.id })
				this.fetchStudies({ userID: val.id }).then(() => {
					this.loading = false
				})
			}
		}
	},
	computed: {
		...mapGetters({
			user: 'users/user',
			studies: 'studies/studies',
			studentEmails: 'studies/studentEmails'
		})
	},
	created() {
		if (this.user?.id) {
			this.loading = true
			this.fetchStudentEmails({ userID: this.user.id })
			this.fetchStudies({ userID: this.user.id }).then(() => {
				this.loading = false
			})
		}
	},
	methods: {
		...mapActions('studies', ['fetchStudies', 'fetchStudentEmails'])
	}
}
</script>
