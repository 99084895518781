export const namespaced = true

export const state = () => ({
	applications: []
})

export const getters = {
	applications: (state) => state.applications
}

export const actions = {
	fetchApplicationsOffer({ commit, dispatch }, { offerID }) {
		commit('SET_APPLICATIONS', [])
		return this.$api
			.get(`/applications/offer/${offerID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_APPLICATIONS', data.applications)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchApplicationsUser({ commit, dispatch }, { userID }) {
		commit('SET_APPLICATIONS', [])
		return this.$api
			.get(`/applications/user/${userID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_APPLICATIONS', data.applications)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	prioritizeApplication({ commit, dispatch }, { application, actionType }) {
		return this.$api
			.post(`/applications/${application.id}/prioritize`, {
				actionType
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_APPLICATION_STATUS', { application, status: data.status })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	discardApplication({ commit, dispatch }, { application, actionType }) {
		return this.$api
			.post(`/applications/${application.id}/discard`, {
				actionType
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_APPLICATION_STATUS', { application, status: data.status })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_APPLICATION: (state, application) => (state.application = application),
	SET_APPLICATIONS: (state, applications) => (state.applications = applications),
	SET_APPLICATION_STATUS: (state, { application, status }) => (state.applications[state.applications.indexOf(application)].status = status)
}
