<template>
	<v-container fluid>
		<h2>
			{{ $t('degrees.addNewDegree') }} for <strong>{{ faculty.acronym }}</strong>
		</h2>

		<v-form ref="form" v-model="valid">
			<v-row>
				<v-col cols="12" lg="6" md="6" xs="12">
					<v-card rounded="xl">
						<v-card-title>{{ $t('auth.name') }}</v-card-title>
						<v-card-text>
							<v-text-field v-model="nameEN" :label="$t('languages.english')" :counter="nameMaxLength" :rules="nameRules" outlined />
							<v-text-field v-model="nameES" :label="$t('languages.spanish')" :counter="nameMaxLength" :rules="nameRulesNotRequired" outlined />
							<v-text-field v-model="nameCA" :label="$t('languages.catalan')" :counter="nameMaxLength" :rules="nameRulesNotRequired" outlined />
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" lg="6" md="6" xs="12">
					<v-card rounded="xl">
						<v-card-title>{{ $t('courses.academical') }}</v-card-title>
						<v-card-text>
							<v-text-field :value="`[${faculty.acronym}] ${faculty.name}`" :label="$t('faculties.faculty')" outlined readonly />
							<v-select
								v-model="degreeType"
								:items="availableDegreeTypes"
								item-text="name"
								item-value="key"
								:label="$t('degrees.degreeType')"
								:rules="degreeTypeRules"
								return-object
								outlined
							/>
							<v-text-field v-model="acronym" :label="$t('courses.acronym')" :counter="acronymMaxLength" :rules="acronymRules" outlined />
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-form>
		<v-card-actions>
			<v-btn color="primary" @click="createNew()">
				{{ $t('settings.add') }}
			</v-btn>
			<v-btn color="primary" @click="createNewAndAnother()">
				{{ $t('settings.addAndAnother') }}
			</v-btn>
		</v-card-actions>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('degrees.addNewDegree')
		}
	},
	data() {
		return {
			nameEN: '',
			nameES: '',
			nameCA: '',
			nameRules: [
				(v) => !!v || 'Name is required.',
				(v) => (v && v.length <= this.nameMaxLength) || `Name must be less than ${this.nameMaxLength} characters`
			],
			nameRulesNotRequired: [(v) => v.length <= this.nameMaxLength || `Name must be less than ${this.nameMaxLength} characters`],
			degreeType: null,
			degreeTypeRules: [(v) => !!v || 'Degree type is required.'],
			acronym: '',
			acronymRules: [
				(v) => !!v || 'Acronym is required.',
				(v) => v.length <= this.acronymMaxLength || `Acronym must be less than ${this.acronymMaxLength} characters`
			],
			remainAfterwards: false
		}
	},
	computed: {
		nameMaxLength() {
			return 128
		},
		acronymMaxLength() {
			return 32
		},
		...mapGetters({
			faculty: 'faculties/faculty',
			gDrives: 'gdrives/gDrives',
			availableDegreeTypes: 'data/availableDegreeTypes'
		})
	},
	created() {
		this.fetchGDrives()
		this.fetchAvailableDegreeTypes()
	},
	methods: {
		createNew() {
			this.$refs.form.validate()
			if (!this.valid) return
			const argsDegreeType = this.degreeType ? this.degreeType.key : 'none'
			return this.addDegree({
				nameEN: this.nameEN,
				nameES: this.nameES,
				nameCA: this.nameCA,
				acronym: this.acronym,
				degreeType: argsDegreeType,
				facultyID: this.faculty.id
			}).then((data) => {
				if (data.success && !this.remainAfterwards) {
					this.$router.push({ name: 'StudiesFaculty', params: { id: this.faculty.id } })
				}
				return data
			})
		},
		createNewAndAnother() {
			this.remainAfterwards = true
			this.createNew().then((data) => {
				if (data.success) {
					this.nameEN = ''
					this.nameES = ''
					this.nameCA = ''
					this.acronym = ''
					this.remainAfterwards = false
					window.scrollTo(0, 0)
				}
			})
		},
		...mapActions('degrees', ['addDegree']),
		...mapActions('data', ['fetchAvailableDegreeTypes']),
		...mapActions('gdrives', ['fetchGDrives'])
	}
}
</script>
