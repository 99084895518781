<template>
	<v-card rounded="xl">
		<v-card-title @click="collapsed = !collapsed" style="cursor: pointer;">
			<v-row>
				<v-col cols="12" md="8" sm="12">
					<span style="word-break: normal">[{{ faculty.acronym }}] {{ faculty.name }} </span>
				</v-col>
				<v-col cols="12" md="4" sm="12" class="d-flex justify-space-between">
					<span><v-icon>mdi-school</v-icon> {{ faculty.studentsNum }}</span>
					<v-btn icon :to="{ name: 'StudiesFaculty', params: { id: faculty.id } }">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-card-title>

		<v-card-text v-if="!collapsed" class="px-2">
			<v-row v-for="degree in faculty.degrees" :key="degree.id" :degree="degree" class="mx-4">
				<v-col cols="12" md="8" sm="12">
					<span class="body-1" style="word-break: normal">{{ degree.type }} - [{{ degree.acronym }}] {{ degree.name }} </span>
				</v-col>
				<v-col cols="12" md="4" sm="12" class="d-flex justify-space-between">
					<span><v-icon>mdi-school</v-icon> {{ degree.studentsNum }}</span>
					<v-btn icon :to="{ name: 'StudiesDegree', params: { id: degree.id } }">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</v-col>
			</v-row>

			<v-alert v-if="!faculty.degrees.length">
				<h4>No degrees for this faculty</h4>
			</v-alert>
			<v-btn color="primary" @click="goToStudiesDegreeAdd" class="mt-4">
				Add new degree
			</v-btn>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'FacultyCard',
	props: {
		faculty: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			collapsed: true
		}
	},
	methods: {
		goToStudiesDegreeAdd() {
			this.fetchFaculty(this.faculty.id).then((data) => {
				if (data.success) {
					this.$router.push({ name: 'StudiesDegreeAdd' })
				}
			})
		},
		...mapActions('faculties', ['fetchFaculty'])
	}
}
</script>

<style lang="scss" scoped></style>
