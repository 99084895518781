<template>
	<v-container fluid>
		<h2>{{ $t('courses.course') }} - ID {{ course.id }}</h2>

		<v-row>
			<v-col cols="12" sm="4" xs="12">
				<div class="d-flex flex-column">
					<v-card rounded="xl">
						<v-card-title>{{ $t('auth.name') }}</v-card-title>
						<v-card-text>
							<v-form ref="formName" v-model="validName">
								<v-text-field v-model="nameEN" :label="$t('languages.english')" :counter="nameMaxLength" :rules="rules.name" outlined />
								<v-text-field v-model="nameES" :label="$t('languages.spanish')" :counter="nameMaxLength" :rules="rules.nameNotRequired" outlined />
								<v-text-field v-model="nameCA" :label="$t('languages.catalan')" :counter="nameMaxLength" :rules="rules.nameNotRequired" outlined />
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-btn color="primary" @click="callEditName()">
								{{ $t('settings.edit') }}
							</v-btn>
						</v-card-actions>
					</v-card>
					<v-card rounded="xl">
						<v-card-title>Google Drive</v-card-title>
						<v-card-text>
							<v-form ref="formGDrive" v-model="validGDrive">
								<v-select
									v-model="gDrive"
									:items="gDrives"
									item-text="email"
									item-value="id"
									:label="$t('gdrive.account')"
									:rules="rules.gDrive"
									outlined
									return-object
								/>
								<v-text-field
									v-model="gDriveFolderID"
									:label="$t('courses.folderID')"
									:counter="gDriveFolderIDMaxLength"
									:rules="rules.gDriveFolder"
									outlined
								/>
							</v-form>
						</v-card-text>
						<v-card-actions>
							<v-btn color="primary" @click="callEditGDrive()">
								{{ $t('settings.edit') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</div>
			</v-col>
			<v-col cols="12" sm="8" xs="12">
				<v-card rounded="xl">
					<v-card-title>{{ $t('courses.academical') }}</v-card-title>
					<v-card-text>
						<v-form ref="formAcademical" v-model="validAcademical">
							<v-row>
								<v-col cols="12" sm="9" xl="12">
									<v-select
										v-model="faculty"
										:items="universityFaculties"
										item-text="name"
										item-value="id"
										:label="$t('faculties.faculty')"
										:rules="rules.faculty"
										return-object
										outlined
										required
										@input="updateDegrees()"
									/>
								</v-col>
								<v-col cols="12" sm="3" xl="12">
									<v-text-field v-model="code" :label="$t('courses.code')" :counter="codeMaxLength" :rules="rules.code" outlined />
								</v-col>
							</v-row>
							<span class="text-subtitle-2">{{ $t('degrees.degrees') }}</span>
							<v-data-table
								:items="degrees"
								:loading="loading"
								:locale="$i18n.locale"
								disable-filtering
								disable-pagination
								disable-sort
								hide-default-footer
								class="elevation-1"
								dense
							>
								<template #item="{item}">
									<tr>
										<td>
											<b>[{{ item.acronym }}]</b> {{ item.name }}
										</td>
										<td class="text-center">
											<v-checkbox v-model="item.mandatory" :label="$t('courses.mandatory')" solo />
										</td>
										<td class="text-center">
											<v-select
												v-model="item.semesters"
												:items="availableSemesters"
												:menu-props="{ offsetY: true }"
												item-text="name"
												item-value="key"
												:label="$t('courses.semesters')"
												:rules="rules.semesters"
												return-object
												multiple
												chips
												solo
												dense
											/>
										</td>
										<td class="text-center">
											<v-btn icon color="red" @click="removeDegree(item)">
												<v-icon>mdi-close</v-icon>
											</v-btn>
										</td>
									</tr>
								</template>
							</v-data-table>

							<v-select
								v-model="newDegree"
								:items="availableDegrees"
								item-text="name"
								item-value="id"
								:label="$t('settings.add')"
								return-object
								outlined
								@input="addDegree()"
							/>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-btn color="primary" @click="callEditAcademical()">
							{{ $t('settings.edit') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" lg="6" md="6" xs="12">
				<v-card rounded="xl">
					<v-card-title>{{ $t('courses.shortDescription') }}</v-card-title>
					<v-card-text>
						<v-form ref="formShortDescription" v-model="validShortDescription">
							<v-textarea
								v-model="shortDescriptionEN"
								:label="$t('languages.english')"
								:counter="shortDescriptionMaxLength"
								:rules="rules.shortDescription"
								outlined
								auto-grow
							/>
							<v-textarea
								v-model="shortDescriptionES"
								:label="$t('languages.spanish')"
								:counter="shortDescriptionMaxLength"
								:rules="rules.shortDescriptionNotRequired"
								outlined
								auto-grow
							/>
							<v-textarea
								v-model="shortDescriptionCA"
								:label="$t('languages.catalan')"
								:counter="shortDescriptionMaxLength"
								:rules="rules.shortDescriptionNotRequired"
								outlined
								auto-grow
							/>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-btn color="primary" @click="callEditShortDescription()">
							{{ $t('settings.edit') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
			<v-col cols="12" lg="6" md="6" xs="12">
				<v-card rounded="xl">
					<v-card-title>{{ $t('courses.longDescription') }}</v-card-title>
					<v-card-text>
						<v-form ref="formLongDescription" v-model="validLongDescription">
							<v-textarea
								v-model="longDescriptionEN"
								:label="$t('languages.english')"
								:counter="longDescriptionMaxLength"
								:rules="rules.longDescription"
								outlined
								auto-grow
							/>
							<v-textarea
								v-model="longDescriptionES"
								:label="$t('languages.spanish')"
								:counter="longDescriptionMaxLength"
								:rules="rules.longDescriptionNotRequired"
								outlined
								auto-grow
							/>
							<v-textarea
								v-model="longDescriptionCA"
								:label="$t('languages.catalan')"
								:counter="longDescriptionMaxLength"
								:rules="rules.longDescriptionNotRequired"
								outlined
								auto-grow
							/>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-btn color="primary" @click="callEditLongDescription()">
							{{ $t('settings.edit') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>

		<v-card rounded="xl">
			<v-card-title>
				{{ $t('students.students') }}
				<v-spacer />
				<div><v-icon>mdi-school</v-icon> {{ course.studentsNum }}</div>
				<v-spacer />
				<v-btn icon @click="updateTable(page, perPage)">
					<v-icon>mdi-sync</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<div class="text-center">
					<v-pagination :value="page" :length="pages" @input="goToPage" />
				</div>
				<v-simple-table>
					<template v-slot:default>
						<thead>
							<tr>
								<th />
								<th>ID</th>
								<th>{{ $t('auth.name') }}</th>
								<th>{{ $t('auth.email') }}</th>
								<th />
							</tr>
						</thead>
						<tbody>
							<tr v-for="student in students" :key="student.id">
								<td>
									<v-btn icon :to="{ name: 'User', params: { id: student.user.id } }">
										<v-icon>mdi-pencil</v-icon>
									</v-btn>
								</td>
								<td>{{ student.id }}</td>
								<td>{{ student.user.fullname }}</td>
								<td>{{ student.email }}</td>
								<td>
									<i v-if="student.verified" class="far fa-check-circle" style="color: green" />
									<i v-else class="far fa-times-circle" style="color: red;" />
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<th />
								<th>ID</th>
								<th>{{ $t('auth.name') }}</th>
								<th>{{ $t('auth.email') }}</th>
								<th />
							</tr>
						</tfoot>
					</template>
				</v-simple-table>

				<div class="text-center">
					<v-pagination :value="page" :length="pages" @input="goToPage" />
				</div>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('courses.course') + ' - ' + this.course.name
		}
	},
	data() {
		return {
			validName: false,
			validAcademical: false,
			validGDrive: false,
			validShortDescription: false,
			validLongDescription: false,
			nameEN: '',
			nameES: '',
			nameCA: '',
			shortDescriptionEN: '',
			shortDescriptionES: '',
			shortDescriptionCA: '',
			longDescriptionEN: '',
			longDescriptionES: '',
			longDescriptionCA: '',
			faculty: null,
			newDegree: null,
			degrees: [],
			code: '',
			gDrive: null,
			gDriveFolderID: '',
			rules: {
				name: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.nameMaxLength) || this.$t('rules.length', { length: this.nameMaxLength })
				],
				nameNotRequired: [(v) => v.length <= this.nameMaxLength || this.$t('rules.length', { length: this.nameMaxLength })],
				shortDescription: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.shortDescriptionMaxLength) || this.$t('rules.length', { length: this.shortDescriptionMaxLength })
				],
				shortDescriptionNotRequired: [
					(v) => v.length <= this.shortDescriptionMaxLength || this.$t('rules.length', { length: this.shortDescriptionMaxLength })
				],
				longDescription: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.longDescriptionMaxLength) || this.$t('rules.length', { length: this.longDescriptionMaxLength })
				],
				longDescriptionNotRequired: [
					(v) => v.length <= this.longDescriptionMaxLength || this.$t('rules.length', { length: this.longDescriptionMaxLength })
				],
				faculty: [(v) => !!v || this.$t('rules.required')],
				code: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.codeMaxLength) || this.$t('rules.length', { length: this.codeMaxLength })
				],
				degrees: [(v) => !!v || this.$t('rules.required')],
				semesters: [(v) => !!v || this.$t('rules.required')],
				gDriveFolder: [(v) => !!v || this.$t('rules.required')]
			},
			loading: false,
			loadingStudents: false,
			page: 1,
			perPage: 25,
			pages: 1
		}
	},
	computed: {
		nameMaxLength() {
			return 128
		},
		shortDescriptionMaxLength() {
			return 256
		},
		longDescriptionMaxLength() {
			return 1024
		},
		codeMaxLength() {
			return 16
		},
		gDriveFolderIDMaxLength() {
			return 128
		},
		availableDegrees() {
			return this.facultyDegrees.filter((degree) => !this.degrees.map((x) => x.id).includes(degree.id))
		},
		...mapGetters({
			universityFaculties: 'faculties/universityFaculties',
			facultyDegrees: 'degrees/facultyDegrees',
			course: 'courses/course',
			students: 'students/students',
			gDrives: 'gdrives/gDrives',
			availableSemesters: 'data/availableSemesters'
		})
	},
	watch: {
		'$route.params.id': function(id) {
			this.loading = true
			this.fetchCourse(id).then(({ course }) => {
				this.nameEN = course.nameEN
				this.nameES = course.nameES
				this.nameCA = course.nameCA
				this.nameDE = course.nameDE
				this.nameFR = course.nameFR
				this.shortDescriptionEN = course.shortDescriptionEN
				this.shortDescriptionES = course.shortDescriptionES
				this.shortDescriptionCA = course.shortDescriptionCA
				this.shortDescriptionDE = course.shortDescriptionDE
				this.shortDescriptionFR = course.shortDescriptionFR
				this.longDescriptionEN = course.longDescriptionEN
				this.longDescriptionES = course.longDescriptionES
				this.longDescriptionCA = course.longDescriptionCA
				this.longDescriptionDE = course.longDescriptionDE
				this.longDescriptionFR = course.longDescriptionFR
				this.faculty = course.faculty
				this.degrees = course.degrees
				this.code = course.code
				this.gDrive = course.gDrive
				this.gDriveFolderID = course.gDriveFolderID
				this.page = 1
				this.perPage = 25
				this.pages = 1
				this.fetchDegreesByFaculty(course.faculty.id).then(() => {
					this.loading = false
				})
				this.loading = true
				this.fetchFacultiesByUniversity(course.faculty.university.id).then(() => {
					this.loading = false
				})
			})
		}
	},
	created() {
		this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
		this.perPage = this.$route.query.perPage ? parseInt(this.$route.query.perPage) : 25
		this.fetchGDrives().then(() => {
			this.loading = false
		})
		this.loading = true
		this.fetchSemesters().then(() => {
			this.loading = false
		})
		this.loading = true
		this.fetchCourse(this.$route.params.id)
			.then(({ course }) => {
				this.nameEN = course.nameEN
				this.nameES = course.nameES
				this.nameCA = course.nameCA
				this.nameDE = course.nameDE
				this.nameFR = course.nameFR
				this.shortDescriptionEN = course.shortDescriptionEN
				this.shortDescriptionES = course.shortDescriptionES
				this.shortDescriptionCA = course.shortDescriptionCA
				this.shortDescriptionDE = course.shortDescriptionDE
				this.shortDescriptionFR = course.shortDescriptionFR
				this.longDescriptionEN = course.longDescriptionEN
				this.longDescriptionES = course.longDescriptionES
				this.longDescriptionCA = course.longDescriptionCA
				this.longDescriptionDE = course.longDescriptionDE
				this.longDescriptionFR = course.longDescriptionFR
				this.faculty = course.faculty
				this.degrees = course.degrees
				this.code = course.code
				this.gDrive = course.gDrive
				this.gDriveFolderID = course.gDriveFolderID
				this.fetchDegreesByFaculty(course.faculty.id).then(() => {
					this.loading = false
				})
				this.loading = true
				this.fetchFacultiesByUniversity(course.faculty.university.id).then(() => {
					this.loading = false
				})
				if (this.$route.query.page && this.$route.query.perPage) {
					this.updateTable(this.page, this.perPage)
				}
			})
			.then(() => {
				this.loading = false
			})
	},
	methods: {
		facultyLabel({ acronym, name }) {
			return `[${acronym}] ${name}`
		},
		degreeLabel({ acronym, name }) {
			return `[${acronym}] ${name}`
		},
		goToPage(page) {
			this.updateTable(page, this.perPage)
		},
		addDegree() {
			this.degrees.push(this.newDegree)
			this.newDegree = null
		},
		removeDegree(degree) {
			this.degrees = this.degrees.filter((i) => i != degree)
		},
		updateDegrees() {
			this.loading = true
			this.fetchDegreesByFaculty(this.faculty.id).then(() => {
				this.degrees = []
				this.loading = false
			})
		},
		updateTable(toPage, perPage) {
			this.loadingStudents = true
			if (toPage !== this.page || perPage !== this.perPage) {
				this.$router.replace({ name: this.$route.name, params: { id: this.degree.id }, query: { page: toPage, perPage: perPage } })
			}
			this.fetchStudentsByCourse({ page: toPage, perPage: perPage, courseID: this.course.id })
				.then(({ pages }) => {
					this.page = toPage
					this.perPage = perPage
					this.pages = pages
				})
				.then(() => {
					this.loadingStudents = false
				})
		},
		callEditName() {
			this.$refs.formName.validate()
			if (!this.validName) return
			this.loading = true
			this.editName({
				courseID: this.course.id,
				nameEN: this.nameEN,
				nameES: this.nameES,
				nameCA: this.nameCA,
				nameDE: this.nameDE,
				nameFR: this.nameFR
			}).then(() => {
				this.loading = false
			})
		},
		callEditAcademical() {
			this.$refs.formAcademical.validate()
			if (!this.validAcademical) return
			this.loading = true
			this.editAcademical({
				courseID: this.course.id,
				facultyID: this.faculty.id,
				degrees: this.degrees,
				semesters: this.semesters,
				code: this.code,
				mandatory: this.mandatory
			}).then(() => {
				this.loading = false
			})
		},
		callEditGDrive() {
			this.$refs.formGDrive.validate()
			if (!this.validGDrive) return
			this.loading = true
			const argsGDriveID = this.gDrive ? this.gDrive.id : 0
			this.editGDrive({
				courseID: this.course.id,
				gDriveFolderID: this.gDriveFolderID,
				gDriveID: argsGDriveID
			}).then(() => {
				this.loading = false
			})
		},
		callEditShortDescription() {
			this.$refs.formShortDescription.validate()
			if (!this.validShortDescription) return
			this.loading = true
			this.editShortDescription({
				courseID: this.course.id,
				shortDescriptionEN: this.shortDescriptionEN,
				shortDescriptionES: this.shortDescriptionES,
				shortDescriptionCA: this.shortDescriptionCA,
				shortDescriptionDE: this.shortDescriptionDE,
				shortDescriptionFR: this.shortDescriptionFR
			}).then(() => {
				this.loading = false
			})
		},
		callEditLongDescription() {
			this.$refs.formLongDescription.validate()
			if (!this.validLongDescription) return
			this.loading = true
			this.editLongDescription({
				courseID: this.course.id,
				longDescriptionEN: this.longDescriptionEN,
				longDescriptionES: this.longDescriptionES,
				longDescriptionCA: this.longDescriptionCA,
				longDescriptionDE: this.longDescriptionDE,
				longDescriptionFR: this.longDescriptionFR
			}).then(() => {
				this.loading = false
			})
		},
		...mapActions('faculties', ['fetchFacultiesByUniversity']),
		...mapActions('degrees', ['fetchDegreesByFaculty']),
		...mapActions('courses', ['fetchCourse', 'editName', 'editAcademical', 'editGDrive', 'editShortDescription', 'editLongDescription']),
		...mapActions('students', ['fetchStudentsByCourse']),
		...mapActions('data', ['fetchSemesters']),
		...mapActions('gdrives', ['fetchGDrives'])
	}
}
</script>

<style scoped></style>
