<template>
	<v-container fluid style="max-width: 1400px">
		<v-fade-transition hide-on-leave>
			<template v-if="loadingCompany">
				<v-skeleton-loader key="skeleton" type="list-item-avatar" min-width="400" height="120" />
			</template>
			<template v-else>
				<v-row key="profileFirstRow">
					<v-col cols="12" md="2" sm="3" xs="5">
						<CompanyLogo :loading-parent="loadingCompany" />
					</v-col>
					<v-col cols="12" md="10" sm="9" xs="7">
						<CompanyInfo :loading-parent="loadingCompany" />
					</v-col>
				</v-row>
			</template>
		</v-fade-transition>
		<v-row>
			<v-col cols="12" lg="4" md="5" sm="5" xs="12" class="d-flex flex-column">
				<CompanySectors :loading-parent="loadingCompany" />
			</v-col>
			<v-col cols="12" lg="8" md="7" sm="7" xs="12">
				<CompanyDescription :loading-parent="loadingCompany" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.company.name
		}
	},
	components: {
		CompanyLogo: () => import('@/components/companies/CompanyLogo.vue'),
		CompanyInfo: () => import('@/components/companies/CompanyInfo.vue'),
		CompanySectors: () => import('@/components/companies/CompanySectors.vue'),
		CompanyDescription: () => import('@/components/companies/CompanyDescription.vue')
	},
	computed: {
		...mapGetters({
			company: 'companies/company',
			loadingCompany: 'companies/loading'
		})
	}
}
</script>
