<template>
	<v-container fluid style="max-width: 1600px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ company.name }}</span>
		</div>

		<v-divider class="my-2" />

		<v-chip-group v-model="chipSelected" column color="sidebarBackground" active-class="sidebarBackground" class="my-3">
			<v-chip value="CompanyProfile" :disabled="chipSelected == 'CompanyProfile'">
				<v-btn text :to="{ name: 'CompanyProfile', params: { id: company.id } }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-domain</v-icon>
					{{ $t('settings.profile') }}
				</v-btn>
			</v-chip>
			<v-chip value="CompanySettings" :disabled="chipSelected == 'CompanySettings'">
				<v-btn text :to="{ name: 'CompanySettings', params: { id: company.id } }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-cogs</v-icon>
					{{ $t('nav.settings') }}
				</v-btn>
			</v-chip>
			<v-chip value="CompanyOffers" :disabled="chipSelected == 'CompanyOffers'">
				<v-btn text :to="{ name: 'CompanyOffers', params: { id: company.id } }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-briefcase-outline</v-icon>
					{{ $t('offers.offers') }}
				</v-btn>
			</v-chip>
			<v-chip value="CompanyEvents" :disabled="chipSelected == 'CompanyEvents'">
				<v-btn text :to="{ name: 'CompanyEvents', params: { id: company.id } }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-calendar</v-icon>
					{{ $t('events.events') }}
				</v-btn>
			</v-chip>
			<v-chip value="CompanySubscriptions" :disabled="chipSelected == 'CompanySubscriptions'">
				<v-btn text :to="{ name: 'CompanySubscriptions', params: { id: company.id } }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-bell-ring</v-icon>
					{{ $t('subscriptions.subscriptions') }}
				</v-btn>
			</v-chip>
		</v-chip-group>

		<v-divider class="my-2" />

		<router-view />
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.company.name
		}
	},
	data() {
		return {
			chipSelected: 'CompanyProfile'
		}
	},
	watch: {
		'$route.params.id'(id) {
			this.setLoadingCompany(true)
			this.fetchCompany(id).then(() => {
				this.setLoadingCompany(false)
			})
		}
	},
	computed: {
		...mapGetters({
			company: 'companies/company'
		})
	},
	created() {
		this.chipSelected = this.$route.name
		this.setLoadingCompany(true)
		this.fetchCompany(this.$route.params.id).then(() => {
			this.setLoadingCompany(false)
		})
	},
	methods: {
		...mapActions('companies', ['setLoadingCompany', 'fetchCompany'])
	}
}
</script>

<style scoped>
.v-chip--disabled {
	opacity: 1;
}
</style>
