export const namespaced = true

export const state = () => ({})

export const getters = {}

export const actions = {
	switchAccountTheme({ commit, dispatch }, darkTheme) {
		return this.$api
			.post('/settings/account/darktheme', {
				darkTheme
			})
			.then(({ data }) => {
				this.$vuetify.framework.theme.dark = data.dark
				if (data.dark) {
					commit('user/SET_STYLE_THEME', 'dark', { root: true })
				} else {
					commit('user/SET_STYLE_THEME', 'light', { root: true })
				}
				if (!data.success) {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	changeLocale({ commit, dispatch }, locale) {
		return this.$api
			.post('/settings/account/locale', {
				locale: locale
			})
			.then(({ data }) => {
				if (data.success) {
					this.$i18n.locale = locale.key
					commit('user/UPDATE_LOCALE', locale, { root: true })
					dispatch('user/fetchAvailableLanguages', null, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {}
