export const namespaced = true

export const state = () => ({
	gDriveEmails: []
})

export const getters = {
	gDriveEmails: (state) => state.gDriveEmails
}

export const actions = {
	fetchGDriveEmailsUser({ commit, dispatch }, { userID }) {
		commit('SET_GDRIVE_EMAILS', [])
		return this.$api
			.get(`/gdriveemails/user/${userID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_GDRIVE_EMAILS', data.gDriveEmails)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_GDRIVE_EMAILS: (state, gDriveEmails) => (state.gDriveEmails = gDriveEmails)
}
