<template>
	<div>
		<div class="mt-1 d-flex justify-left">
			<span class="text-h4"> ID {{ university.id }} - {{ university.acronym }} </span>
		</div>
		<div class="d-flex">
			<span class="text-h3 mb-3">
				{{ university.name }}
			</span>
			<UniversityEditForm :loading-parent="loading || loadingParent" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UniversityEditForm from '@/components/universities/UniversityEditForm.vue'

export default {
	name: 'UniversityInfo',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true
		}
	},
	components: {
		UniversityEditForm
	},
	computed: {
		...mapGetters({
			university: 'universities/university'
		})
	},
	created() {
		this.loading = false
	},
	methods: {}
}
</script>
