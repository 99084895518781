<template>
	<v-bottom-navigation grow app class="d-flex justify-between align-center">
		<template v-if="authenticated">
			<v-btn text small plain :to="{ name: 'Users' }">
				<span>{{ $t('nav.users') }}</span>
				<v-icon>mdi-account-supervisor</v-icon>
			</v-btn>

			<v-btn text small plain :to="{ name: 'Studies' }">
				<span>{{ $t('studies.studies') }}</span>
				<v-icon>mdi-school</v-icon>
			</v-btn>

			<v-btn text small plain :to="{ name: 'Companies' }">
				<span>{{ $t('nav.companies') }}</span>
				<v-icon>mdi-domain</v-icon>
			</v-btn>

			<v-btn text small plain :to="{ name: 'Configuration' }">
				<span>{{ $t('nav.configuration') }}</span>
				<v-icon>mdi-cog</v-icon>
			</v-btn>
		</template>

		<v-btn text small plain @click.stop="opened ? close() : open()">
			<span>{{ $t('nav.menu') }}</span>
			<v-icon>mdi-menu</v-icon>
		</v-btn>
	</v-bottom-navigation>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'BottomNavbar',
	computed: {
		...mapGetters({
			authenticated: 'auth/authenticated',
			opened: 'sidebar/opened'
		})
	},
	methods: {
		...mapActions('sidebar', ['open', 'close'])
	}
}
</script>
