export const namespaced = true

export const state = () => ({
	skills: [],
	availableSkills: [],
	projects: [],
	languages: [],
	availableLanguages: [],
	sectors: [],
	availableSectors: []
})

export const getters = {
	skills: (state) => state.skills,
	availableSkills: (state) => state.availableSkills,
	projects: (state) => state.projects,
	languages: (state) => state.languages,
	availableLanguages: (state) => state.availableLanguages,
	sectors: (state) => state.sectors,
	availableSectors: (state) => state.availableSectors
}

export const actions = {
	fetchAvailableSkills({ commit, dispatch }, { keywords }) {
		return this.$api
			.get('/cv/skills/data', {
				params: {
					locale: this.$i18n.locale,
					keywords
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('UPDATE_AVAILABLE_SKILLS', data.skills)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchSkills({ commit, dispatch }, userID) {
		commit('SET_SKILLS', [])
		return this.$api
			.get(`/cv/${userID}/skills`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SKILLS', data.skills)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	removeSkill({ commit, dispatch }, { userID, skillID }) {
		return this.$api
			.delete(`/cv/${userID}/skills/${skillID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('REMOVE_SKILL', skillID)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	addSkill({ commit, dispatch }, { userID, skillID }) {
		return this.$api
			.put(`/cv/${userID}/skills`, {
				skillID
			})
			.then(({ data }) => {
				if (data.success) {
					commit('ADD_SKILL', data.skill)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	addSkillNew({ commit, dispatch }, { userID, search }) {
		return this.$api
			.put(`/cv/${userID}/skills/new`, {
				search
			})
			.then(({ data }) => {
				if (data.success) {
					commit('ADD_SKILL', data.skill)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchProjects({ commit, dispatch }, userID) {
		commit('SET_PROJECTS', [])
		return this.$api
			.get(`/cv/${userID}/projects`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_PROJECTS', data.projects)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	removeProject({ commit, dispatch }, { userID, projectID }) {
		return this.$api
			.delete(`/cv/${userID}/projects/${projectID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('REMOVE_PROJECT', projectID)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	addProject({ commit, dispatch }, { userID, project }) {
		return this.$api
			.put(`/cv/${userID}/projects`, {
				project
			})
			.then(({ data }) => {
				if (data.success) {
					commit('ADD_PROJECT', data.project)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	addProjectNew({ commit, dispatch }, { userID, project }) {
		return this.$api
			.put(`/cv/${userID}/projects/new`, {
				name: project.name,
				ended: project.ended,
				start: project.timestamp.start,
				end: project.timestamp.end,
				website: project.website,
				description: project.description
			})
			.then(({ data }) => {
				if (data.success) {
					commit('ADD_PROJECT', data.project)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableLanguages({ commit, dispatch }, { keywords }) {
		return this.$api
			.get('/cv/languages/data', {
				params: {
					locale: this.$i18n.locale,
					keywords
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('UPDATE_AVAILABLE_LANGUAGES', data.languages)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchLanguages({ commit, dispatch }, userID) {
		commit('SET_LANGUAGES', [])
		return this.$api
			.get(`/cv/${userID}/languages`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_LANGUAGES', data.languages)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	addLanguage({ commit, dispatch }, { userID, languageID, level }) {
		return this.$api
			.put(`/cv/${userID}/languages`, {
				languageID,
				level
			})
			.then(({ data }) => {
				if (data.success) {
					commit('ADD_LANGUAGE', data.language)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editLanguage({ commit, dispatch }, { userID, languageID, level }) {
		return this.$api
			.post(`/cv/${userID}/languages`, {
				languageID,
				level
			})
			.then(({ data }) => {
				if (data.success) {
					commit('EDIT_LANGUAGE', data.language)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	removeLanguage({ commit, dispatch }, { userID, languageID }) {
		return this.$api
			.delete(`/cv/${userID}/languages/${languageID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('REMOVE_LANGUAGE', languageID)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableSectors({ commit, dispatch }, { keywords }) {
		return this.$api
			.get('/cv/sectors/data', {
				params: {
					locale: this.$i18n.locale,
					keywords
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('UPDATE_AVAILABLE_SECTORS', data.sectors)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchSectors({ commit, dispatch }, { userID }) {
		commit('SET_SECTORS', [])
		return this.$api
			.get(`/cv/${userID}/sectors`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SECTORS', data.sectors)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	addSector({ commit, dispatch }, { userID, sectorID }) {
		return this.$api
			.put(`/cv/${userID}/sectors`, {
				sectorID
			})
			.then(({ data }) => {
				if (data.success) {
					commit('ADD_SECTOR', data.sector)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	removeSector({ commit, dispatch }, { userID, sectorID }) {
		return this.$api
			.delete(`/cv/${userID}/sectors/${sectorID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('REMOVE_SECTOR', sectorID)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_SKILLS: (state, skills) => (state.skills = skills),
	UPDATE_AVAILABLE_SKILLS: (state, availableSkills) => (state.availableSkills = Array.from(new Set(state.availableSkills.concat(availableSkills)))),
	ADD_SKILL: (state, skill) => state.skills.push(skill),
	REMOVE_SKILL: (state, skillID) => (state.skills = state.skills.filter((skill) => skill.id !== skillID)),
	SET_PROJECTS: (state, projects) => (state.projects = projects),
	ADD_PROJECT: (state, project) => state.projects.push(project),
	REMOVE_PROJECT: (state, projectID) => (state.projects = state.projects.filter((project) => project.id !== projectID)),
	SET_LANGUAGES: (state, languages) => (state.languages = languages),
	UPDATE_AVAILABLE_LANGUAGES: (state, availableLanguages) =>
		(state.availableLanguages = Array.from(new Set(state.availableLanguages.concat(availableLanguages)))),
	ADD_LANGUAGE: (state, language) => state.languages.push(language),
	EDIT_LANGUAGE: (state, language) => (state.languages[state.languages.findIndex((x) => x.id === language.id)] = language),
	REMOVE_LANGUAGE: (state, languageID) => (state.languages = state.languages.filter((language) => language.id !== languageID)),
	SET_SECTORS: (state, sectors) => (state.sectors = sectors),
	UPDATE_AVAILABLE_SECTORS: (state, availableSectors) =>
		(state.availableSectors = Array.from(new Set(state.availableSectors.concat(availableSectors)))),
	ADD_SECTOR: (state, sector) => state.sectors.push(sector),
	EDIT_SECTOR: (state, sector) => (state.sectors[state.sectors.findIndex((x) => x.id === sector.id)] = sector),
	REMOVE_SECTOR: (state, sectorID) => (state.sectors = state.sectors.filter((sector) => sector.id !== sectorID))
}
