<template>
	<v-container fluid style="max-width: 1400px">
		<v-row>
			<v-col cols="12" sm="6" xs="12">
				<v-fade-transition hide-on-leave>
					<template v-if="loading">
						<v-skeleton-loader type="card" />
					</template>
					<template v-else>
						<CompanyAdministrators :loading-parent="loading" />
					</template>
				</v-fade-transition>
			</v-col>
			<v-col cols="12" sm="6" xs="12">
				<v-fade-transition hide-on-leave>
					<template v-if="loading">
						<v-skeleton-loader type="card" />
					</template>
					<template v-else>
						<CompanyContact :loading-parent="loading" />
					</template>
				</v-fade-transition>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.company.name
		}
	},
	data() {
		return {
			loading: false
		}
	},
	components: {
		CompanyAdministrators: () => import('@/components/companies/CompanyAdministrators.vue'),
		CompanyContact: () => import('@/components/companies/CompanyContact.vue')
	},
	computed: {
		...mapGetters({
			company: 'companies/company'
		})
	}
}
</script>
