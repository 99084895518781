export const namespaced = true

export const state = () => ({ degrees: [], facultyDegrees: [], degree: null })

export const getters = {
	degrees: (state) => state.degrees,
	facultyDegrees: (state) => state.facultyDegrees,
	degree: (state) => state.degree
}

export const actions = {
	fetchDegrees({ commit, dispatch }) {
		commit('SET_DEGREES', [])
		return this.$api
			.get('/degrees')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_DEGREES', data.degrees)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchDegreesByFaculty({ commit, dispatch }, facultyID) {
		if (facultyID) {
			commit('SET_FACULTY_DEGREES', [])
			return this.$api
				.get(`/degrees/faculty/${facultyID}`)
				.then(({ data }) => {
					if (data.success) {
						commit('SET_FACULTY_DEGREES', data.degrees)
					}
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
					return data
				})
				.catch((error) => {
					console.log(error)
					dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
				})
		}
	},
	fetchDegree({ commit, dispatch }, degreeID) {
		commit('SET_DEGREE', null)
		return this.$api
			.get(`/degrees/${degreeID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_DEGREE', data.degree)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	addDegree({ dispatch }, args) {
		return this.$api
			.put('/degrees/add', {
				nameEN: args.nameEN,
				nameES: args.nameES,
				nameCA: args.nameCA,
				acronym: args.acronym,
				degreeType: args.degreeType,
				facultyID: args.facultyID
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editName({ dispatch }, args) {
		return this.$api
			.post(`/degrees/${args.degreeID}/edit/name`, {
				nameEN: args.nameEN,
				nameES: args.nameES,
				nameCA: args.nameCA
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editAcademical({ dispatch }, args) {
		return this.$api
			.post(`/degrees/${args.degreeID}/edit/academical`, {
				facultyID: args.facultyID,
				degreeType: args.degreeType,
				acronym: args.acronym,
				practicesTimetableURL: args.practicesTimetableURL
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editGDrive({ dispatch }, args) {
		return this.$api
			.post(`/degrees/${args.degreeID}/edit/gDrive`, {
				xlsxExamsID: args.xlsxExamsID,
				gDriveID: args.gDriveID
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchDegreesUser({ commit, dispatch }) {
		commit('SET_DEGREES', [])
		return this.$api
			.get('/degrees')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_DEGREES', data.degrees)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_DEGREES: (state, degrees) => (state.degrees = degrees),
	SET_FACULTY_DEGREES: (state, facultyDegrees) => (state.facultyDegrees = facultyDegrees),
	SET_DEGREE: (state, degree) => (state.degree = degree)
}
