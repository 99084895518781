<template>
	<v-container fluid>
		<h2 class="my-2 text-center">{{ $t('universities.addNewUniversity') }}</h2>
		<UniversityAddForm :loading-parent="loading" />
	</v-container>
</template>

<script>
import UniversityAddForm from '@/components/universities/UniversityAddForm.vue'

export default {
	metaInfo() {
		return {
			title: this.$t('universities.addNewUniversity')
		}
	},
	data: function() {
		return {
			loading: true
		}
	},
	components: {
		UniversityAddForm
	},
	computed: {},
	created() {
		this.loading = false
	},
	methods: {}
}
</script>

<style scoped></style>
