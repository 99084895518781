<template>
	<v-container fluid>
		<LoginForm color="transparent" :dark="false" v-if="!authenticated" />
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginForm from '@/components/auth/LoginForm.vue'

export default {
	metaInfo() {
		return {
			title: 'Home'
		}
	},
	components: {
		LoginForm
	},
	computed: {
		...mapGetters({
			authenticated: 'auth/authenticated'
		})
	}
}
</script>

<style scoped></style>
