<template>
	<v-container fluid style="max-width: 1200px">
		<v-row>
			<v-col cols="12" xl="6" lg="8" sm="12" class="d-flex flex-column">
				<UserLimits :loading-parent="loading" />
			</v-col>
			<v-col cols="12" xl="3" lg="4" sm="12" class="d-flex flex-column">
				<UserGoogleDriveEmails :g-drive-emails="user.gDriveEmails" />
			</v-col>
		</v-row>

		<UserStudentProfiles :loading-parent="loading" />
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.user.name.full
		}
	},
	data() {
		return {
			loading: false
		}
	},
	components: {
		UserStudentProfiles: () => import('@/components/users/UserStudentProfiles.vue'),
		UserLimits: () => import('@/components/users/UserLimits.vue'),
		UserGoogleDriveEmails: () => import('@/components/users/UserGoogleDriveEmails.vue')
	},
	computed: {
		...mapGetters({
			user: 'users/user'
		})
	}
}
</script>
