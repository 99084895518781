export const namespaced = true

export const state = () => ({ faculties: [], universityFaculties: [], faculty: null })

export const getters = {
	faculties: (state) => state.faculties,
	universityFaculties: (state) => state.universityFaculties,
	faculty: (state) => state.faculty
}

export const actions = {
	fetchFaculties({ commit, dispatch }) {
		commit('SET_FACULTIES', [])
		return this.$api
			.get('/faculties')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_FACULTIES', data.faculties)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchFacultiesByUniversity({ commit, dispatch }, universityID) {
		if (universityID) {
			commit('SET_UNIVERSITY_FACULTIES', [])
			return this.$api
				.get(`/faculties/university/${universityID}`)
				.then(({ data }) => {
					if (data.success) {
						commit('SET_UNIVERSITY_FACULTIES', data.faculties)
					}
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
					return data
				})
				.catch((error) => {
					console.log(error)
					dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
				})
		}
	},
	fetchFaculty({ commit, dispatch }, facultyID) {
		commit('SET_FACULTY', null)
		return this.$api
			.get(`/faculties/${facultyID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_FACULTY', data.faculty)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	addFaculty({ dispatch }, { universityID, name, acronym, discord, domains }) {
		return this.$api
			.put('/faculties/add', {
				universityID,
				name,
				acronym,
				discord,
				domains
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editFaculty({ dispatch }, { facultyID, name, acronym, discord, domains }) {
		return this.$api
			.post(`/faculties/${facultyID}/edit`, {
				name,
				acronym,
				discord,
				domains
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_FACULTIES: (state, faculties) => (state.faculties = faculties),
	SET_UNIVERSITY_FACULTIES: (state, universityFaculties) => (state.universityFaculties = universityFaculties),
	SET_FACULTY: (state, faculty) => (state.faculty = faculty)
}
