<template>
	<v-container fluid>
		<v-card max-width="1200" class="mx-auto mt-5" :loading="loading" rounded="xl">
			<v-card-title>
				{{ $t('subscriptions.subscriptions') }}
				<v-spacer />
			</v-card-title>
			<v-card-text>
				<SubscriptionList :loading-parent="loading" :subscriptions="subscriptions" />
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('subscriptions.subscriptions')
		}
	},
	data() {
		return {
			loading: false
		}
	},
	components: {
		SubscriptionList: () => import('@/components/subscriptions/SubscriptionList.vue')
	},
	created() {
		this.loading = true
		this.fetchSubscriptions().then(() => {
			this.loading = false
		})
	},
	computed: {
		...mapGetters({
			subscriptions: 'subscriptions/subscriptions'
		})
	},
	methods: {
		...mapActions('subscriptions', ['fetchSubscriptions'])
	}
}
</script>
