<template>
	<v-dialog v-model="editMode" persistent max-width="600px">
		<template v-slot:activator="{ on }">
			<v-btn icon color="primary" v-on="on">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('universities.university') }}</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-text-field v-model="name" :label="$t('auth.name')" :counter="nameMaxLength" :rules="rules.name" outlined />
					<v-text-field v-model="acronym" :label="$t('courses.acronym')" :counter="acronymMaxLength" :rules="rules.acronym" outlined />
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" text @click="switchEditMode()">{{ $t('search.close') }}</v-btn>
				<v-btn color="blue darken-1" text @click="callUpdate()">{{ $t('settings.edit') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UniversityEditForm',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true,
			editMode: false,
			valid: false,
			name: '',
			acronym: '',
			rules: {
				name: [
					(v) => !!v || 'Name is required.',
					(v) => (v && v.length <= this.nameMaxLength) || `Name must be less than ${this.nameMaxLength} characters`
				],
				acronym: [
					(v) => !!v || 'Acronym is required.',
					(v) => (v && v.length <= this.acronymMaxLength) || `Acronym must be less than ${this.acronymMaxLength} characters`
				]
			}
		}
	},
	computed: {
		nameMaxLength() {
			return 128
		},
		acronymMaxLength() {
			return 32
		},
		...mapGetters({
			university: 'universities/university'
		})
	},
	created() {
		this.name = this.university.name
		this.acronym = this.university.acronym
		this.loading = false
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		callUpdate() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.editUniversity({
				universityID: this.university.id,
				name: this.name,
				acronym: this.acronym
			}).then(() => {
				this.switchEditMode()
				this.loading = false
			})
		},
		...mapActions('universities', ['editUniversity'])
	}
}
</script>
