<template>
	<v-card max-width="1800" class="mx-auto" rounded="xl">
		<v-card-title>
			<span class="headline">{{ $t('faculties.faculties') }}</span>
			<v-spacer />
			<v-btn icon :to="{ name: 'StudiesFacultyAdd' }">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-card-title>
		<v-card-text>
			<FacultyCard v-for="faculty in university.faculties" :key="faculty.id" :faculty="faculty" />

			<v-alert v-if="!university.faculties.length">
				<h4>No faculties for this university</h4>
			</v-alert>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import FacultyCard from '@/components/faculties/FacultyCard.vue'

export default {
	name: 'UniversityFaculties',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true
		}
	},
	components: {
		FacultyCard
	},
	computed: {
		...mapGetters({
			university: 'universities/university'
		})
	},
	created() {
		this.loading = false
	},
	methods: {}
}
</script>
