<template>
	<v-footer padless absolute v-if="$vuetify.breakpoint.lgAndUp">
		<v-row justify="center" no-gutters>
			<v-col class="py-1 text-center" cols="3">
				<v-btn icon href="https://instagram.com/oversos.official" target="_blank">
					<v-icon>mdi-instagram</v-icon>
				</v-btn>
			</v-col>
			<v-col class="py-1 text-center" cols="3">
				<v-btn icon href="https://facebook.com/oversos" target="_blank">
					<v-icon>mdi-facebook</v-icon>
				</v-btn>
			</v-col>
			<v-col class="py-1 text-center" cols="3">
				<v-btn icon href="https://twitter.com/oversosofficial" target="_blank">
					<v-icon>mdi-twitter</v-icon>
				</v-btn>
			</v-col>
			<v-col class="py-1 text-center" cols="3">
				<v-btn icon href="https://linkedin.com/company/oversos" target="_blank">
					<v-icon>mdi-linkedin</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row justify="center" no-gutters>
			<v-col class="py-1 text-center" cols="12"> {{ new Date().getFullYear() }} — &copy; <strong>OverSOS</strong> </v-col>
		</v-row>
	</v-footer>
</template>

<script>
export default {
	name: 'Footer'
}
</script>
