<template>
	<v-card class="mt-4" rounded="xl">
		<v-card-title>
			<v-row>
				<v-col sm="9">
					<span style="word-break: normal">{{ stripedEmail }} </span>
				</v-col>
				<v-col sm="3" class="d-flex justify-end">
					<span>
						<v-icon :color="gDrive.hasCredentials ? 'green' : 'red'">{{ gDrive.hasCredentials ? 'mdi-check' : 'mdi-close-circle-outline' }} </v-icon>
					</span>
					<v-btn icon @click="updateCredentials()">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-card-title>
	</v-card>
</template>

<script>
export default {
	name: 'CollapseGDrive',
	props: {
		gDrive: {
			type: Object,
			required: true
		}
	},
	computed: {
		stripedEmail() {
			return this.gDrive.email.substring(0, this.gDrive.email.indexOf('@'))
		}
	},
	methods: {
		updateCredentials() {
			this.$api.get(`/gdrives/google_oauth2/ask/${this.gDrive.id}`).then(({ data }) => {
				if (data.success) {
					window.location = data.authorizationURL
				}
			})
		}
	}
}
</script>
