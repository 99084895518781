<template>
	<v-container fluid>
		<h2>
			{{ $t('courses.addNewCourse') }} for <strong>{{ degree.acronym }}</strong>
		</h2>

		<v-form ref="form" v-model="valid">
			<v-row>
				<v-col cols="12" lg="4" sm="6" xs="12">
					<v-card rounded="xl">
						<v-card-title>{{ $t('auth.name') }}</v-card-title>
						<v-card-text>
							<v-text-field v-model="nameEN" :label="$t('languages.english')" :counter="nameMaxLength" :rules="nameRules" outlined />
							<v-text-field v-model="nameES" :label="$t('languages.spanish')" :counter="nameMaxLength" :rules="nameRulesNotRequired" outlined />
							<v-text-field v-model="nameCA" :label="$t('languages.catalan')" :counter="nameMaxLength" :rules="nameRulesNotRequired" outlined />
							<v-text-field v-model="nameDE" :label="$t('languages.german')" :counter="nameMaxLength" :rules="nameRulesNotRequired" outlined />
							<v-text-field v-model="nameFR" :label="$t('languages.french')" :counter="nameMaxLength" :rules="nameRulesNotRequired" outlined />
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" lg="4" sm="6" xs="12">
					<v-card rounded="xl">
						<v-card-title>{{ $t('courses.academical') }}</v-card-title>
						<v-card-text>
							<v-text-field :value="`[${degree.faculty.acronym}] ${degree.faculty.name}`" :label="$t('faculties.faculty')" outlined readonly />
							<v-text-field :value="`[${degree.type.name}] (${degree.acronym}) ${degree.name}`" :label="$t('degrees.degree')" outlined readonly />
							<v-select
								v-model="semesters"
								:items="availableSemesters"
								item-text="name"
								item-value="key"
								:label="$t('courses.semesters')"
								:rules="semestersRules"
								return-object
								outlined
								multiple
								chips
							/>
							<v-text-field v-model="code" :label="$t('courses.code')" :counter="codeMaxLength" :rules="codeRules" outlined />
							<v-checkbox v-model="mandatory" :label="$t('courses.mandatory')" outlined />
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" lg="4" sm="6" xs="12">
					<v-card rounded="xl">
						<v-card-title>Google Drive</v-card-title>
						<v-card-text>
							<v-select v-model="gDrive" :items="gDrives" item-text="email" item-value="id" :label="$t('gdrive.account')" outlined return-object />
							<v-text-field
								v-model="gDriveFolderID"
								:label="$t('courses.folderID')"
								:counter="gDriveFolderIDMaxLength"
								:rules="gDriveFolderIDRules"
								outlined
							/>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12" lg="6" md="6" xs="12">
					<v-card rounded="xl">
						<v-card-title>{{ $t('courses.shortDescription') }}</v-card-title>
						<v-card-text>
							<v-textarea
								v-model="shortDescriptionEN"
								:label="$t('languages.english')"
								:counter="shortDescriptionMaxLength"
								:rules="shortDescriptionRules"
								outlined
								auto-grow
							/>
							<v-textarea
								v-model="shortDescriptionES"
								:label="$t('languages.spanish')"
								:counter="shortDescriptionMaxLength"
								:rules="shortDescriptionRulesNotRequired"
								outlined
								auto-grow
							/>
							<v-textarea
								v-model="shortDescriptionCA"
								:label="$t('languages.catalan')"
								:counter="shortDescriptionMaxLength"
								:rules="shortDescriptionRulesNotRequired"
								outlined
								auto-grow
							/>
							<v-textarea
								v-model="shortDescriptionDE"
								:label="$t('languages.german')"
								:counter="shortDescriptionMaxLength"
								:rules="shortDescriptionRulesNotRequired"
								outlined
								auto-grow
							/>
							<v-textarea
								v-model="shortDescriptionFR"
								:label="$t('languages.french')"
								:counter="shortDescriptionMaxLength"
								:rules="shortDescriptionRulesNotRequired"
								outlined
								auto-grow
							/>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" lg="6" md="6" xs="12">
					<v-card rounded="xl">
						<v-card-title>{{ $t('courses.longDescription') }}</v-card-title>
						<v-card-text>
							<v-textarea
								v-model="longDescriptionEN"
								:label="$t('languages.english')"
								:counter="longDescriptionMaxLength"
								:rules="longDescriptionRules"
								outlined
								auto-grow
							/>
							<v-textarea
								v-model="longDescriptionES"
								:label="$t('languages.spanish')"
								:counter="longDescriptionMaxLength"
								:rules="longDescriptionRulesNotRequired"
								outlined
								auto-grow
							/>
							<v-textarea
								v-model="longDescriptionCA"
								:label="$t('languages.catalan')"
								:counter="longDescriptionMaxLength"
								:rules="longDescriptionRulesNotRequired"
								outlined
								auto-grow
							/>
							<v-textarea
								v-model="longDescriptionDE"
								:label="$t('languages.german')"
								:counter="longDescriptionMaxLength"
								:rules="longDescriptionRulesNotRequired"
								outlined
								auto-grow
							/>
							<v-textarea
								v-model="longDescriptionFR"
								:label="$t('languages.french')"
								:counter="longDescriptionMaxLength"
								:rules="longDescriptionRulesNotRequired"
								outlined
								auto-grow
							/>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-form>
		<v-card-actions>
			<v-btn color="primary" @click="createNew()">
				{{ $t('settings.add') }}
			</v-btn>
			<v-btn color="primary" @click="createNewAndAnother()">
				{{ $t('settings.addAndAnother') }}
			</v-btn>
		</v-card-actions>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('courses.addNewCourse')
		}
	},
	data() {
		return {
			loading: true,
			valid: false,
			nameEN: '',
			nameES: '',
			nameCA: '',
			nameDE: '',
			nameFR: '',
			nameRules: [
				(v) => !!v || 'Name is required.',
				(v) => (v && v.length <= this.nameMaxLength) || `Name must be less than ${this.nameMaxLength} characters`
			],
			nameRulesNotRequired: [(v) => v.length <= this.nameMaxLength || `Name must be less than ${this.nameMaxLength} characters`],
			shortDescriptionEN: '',
			shortDescriptionES: '',
			shortDescriptionCA: '',
			shortDescriptionDE: '',
			shortDescriptionFR: '',
			shortDescriptionRules: [
				(v) => !!v || 'Short description is required.',
				(v) => (v && v.length <= this.shortDescriptionMaxLength) || `Short description must be less than ${this.shortDescriptionMaxLength} characters`
			],
			shortDescriptionRulesNotRequired: [
				(v) => v.length <= this.shortDescriptionMaxLength || `Short description must be less than ${this.shortDescriptionMaxLength} characters`
			],
			longDescriptionEN: '',
			longDescriptionES: '',
			longDescriptionCA: '',
			longDescriptionDE: '',
			longDescriptionFR: '',
			longDescriptionRules: [
				(v) => !!v || 'Long description is required.',
				(v) => (v && v.length <= this.longDescriptionMaxLength) || `Long description must be less than ${this.longDescriptionMaxLength} characters`
			],
			longDescriptionRulesNotRequired: [
				(v) => v.length <= this.longDescriptionMaxLength || `Long description must be less than ${this.longDescriptionMaxLength} characters`
			],
			semesters: [],
			semestersRules: [(v) => !!v.length || 'Semesters is required.'],
			code: '',
			codeRules: [(v) => v.length <= this.codeMaxLength || `Code must be less than ${this.codeMaxLength} characters`],
			mandatory: false,
			gDrive: null,
			gDriveFolderID: '',
			gDriveFolderIDRules: [
				(v) => v.length <= this.gDriveFolderIDMaxLength || `Folder ID must be less than ${this.gDriveFolderIDMaxLength} characters`
			],
			remainAfterwards: false
		}
	},
	computed: {
		nameMaxLength() {
			return 128
		},
		shortDescriptionMaxLength() {
			return 256
		},
		longDescriptionMaxLength() {
			return 1024
		},
		codeMaxLength() {
			return 16
		},
		gDriveFolderIDMaxLength() {
			return 128
		},
		...mapGetters({
			degree: 'degrees/degree',
			gDrives: 'gdrives/gDrives',
			availableSemesters: 'data/availableSemesters'
		})
	},
	created() {
		this.fetchGDrives()
		this.fetchSemesters()
	},
	methods: {
		createNew() {
			this.$refs.form.validate()
			if (!this.valid) return
			const gDriveID = this.gDrive ? this.gDrive.id : 0
			const facultyID = this.degree ? this.degree.faculty.id : 0
			const degreeID = this.degree ? this.degree.id : 0
			return this.addCourse({
				nameEN: this.nameEN,
				nameES: this.nameES,
				nameCA: this.nameCA,
				nameDE: this.nameDE,
				nameFR: this.nameFR,
				shortDescriptionEN: this.shortDescriptionEN,
				shortDescriptionES: this.shortDescriptionES,
				shortDescriptionCA: this.shortDescriptionCA,
				shortDescriptionDE: this.shortDescriptionDE,
				shortDescriptionFR: this.shortDescriptionFR,
				longDescriptionEN: this.longDescriptionEN,
				longDescriptionES: this.longDescriptionES,
				longDescriptionCA: this.longDescriptionCA,
				longDescriptionDE: this.longDescriptionDE,
				longDescriptionFR: this.longDescriptionFR,
				facultyID,
				degreeID,
				semesters: this.semesters,
				code: this.code,
				mandatory: this.mandatory,
				gDriveID,
				gDriveFolderID: this.gDriveFolderID
			}).then((data) => {
				if (data.success && !this.remainAfterwards) {
					this.$router.push({ name: 'StudiesDegree', params: { id: this.degree.id } })
				}
				return data
			})
		},
		createNewAndAnother() {
			this.remainAfterwards = true
			this.createNew().then((data) => {
				if (data.success) {
					this.nameEN = ''
					this.nameES = ''
					this.nameCA = ''
					this.nameDE = ''
					this.nameFR = ''
					this.shortDescriptionEN = ''
					this.shortDescriptionES = ''
					this.shortDescriptionCA = ''
					this.shortDescriptionDE = ''
					this.shortDescriptionFR = ''
					this.longDescriptionEN = ''
					this.longDescriptionES = ''
					this.longDescriptionCA = ''
					this.longDescriptionDE = ''
					this.longDescriptionFR = ''
					this.code = ''
					this.gDriveFolderID = ''
					this.action_response += ' | You can now add another'
					this.remainAfterwards = false
					window.scrollTo(0, 0)
				}
			})
		},
		...mapActions('courses', ['addCourse']),
		...mapActions('data', ['fetchSemesters']),
		...mapActions('gdrives', ['fetchGDrives'])
	}
}
</script>
