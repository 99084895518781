<template>
	<v-container fluid>
		<h2>Domains</h2>

		<collapseDomain v-for="domain in domains" :key="domain.id" :domain="domain" />
		<v-btn color="primary" :to="{ name: 'DomainsAdd' }">
			{{ $t('domains.addNewDomain') }}
		</v-btn>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import collapseDomain from '@/components/collapse/collapseDomain.vue'

export default {
	metaInfo() {
		return {
			title: 'Domains'
		}
	},
	data() {
		return {
			loading: true
		}
	},
	components: {
		collapseDomain
	},
	computed: {
		...mapGetters({
			domains: 'domains/domains'
		})
	},
	created() {
		this.fetchDomains().then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('domains', ['fetchDomains'])
	}
}
</script>

<style scoped></style>
