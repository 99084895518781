<template>
	<div class="d-flex justify-space-between">
		<div>
			<div class="mt-1 d-flex justify-left">
				<span class="text-h4"> ID {{ faculty.id }} - {{ faculty.acronym }} </span>
			</div>
			<div class="d-flex">
				<span class="text-h3 mb-3">
					{{ faculty.name }}
				</span>
				<FacultyEditForm :loading-parent="loading || loadingParent" />
			</div>
			<div class="mt-1 d-flex justify-left">
				<span class="text-h5"> [{{ faculty.university.acronym }}] {{ faculty.university.name }} </span>
			</div>
			<div class="mt-1 d-flex justify-left">
				<span class="text-h5"><v-icon small color="#7289da">mdi-discord</v-icon> {{ faculty.discord }} </span>
			</div>
		</div>
		<div>
			<v-row class="mt-4">
				<v-col>
					<v-chip :ripple="false" v-for="domain in faculty.domains" :key="domain.id" class="mx-1 my-1">
						{{ domain.name }}
					</v-chip>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import FacultyEditForm from '@/components/faculties/FacultyEditForm.vue'

export default {
	name: 'FacultyInfo',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true
		}
	},
	components: {
		FacultyEditForm
	},
	computed: {
		...mapGetters({
			faculty: 'faculties/faculty'
		})
	},
	created() {
		this.loading = false
	},
	methods: {}
}
</script>
