<template>
	<v-container fluid style="max-width: 1600px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('offers.offers') }}</span>
		</div>
		<div class="mx-2 my-3">
			<span class="text-h5">{{ $t('offers.searchOffers') }}</span>
		</div>

		<v-divider class="my-2" />

		<div class="d-flex align-center">
			<v-icon class="mx-4">
				mdi-magnify
			</v-icon>
			<v-text-field v-model="title" :placeholder="$t('search.search')" />
			<v-dialog v-model="filtersEditMode" max-width="1600px">
				<template v-slot:activator="{ on }">
					<v-btn color="secondary" v-on="on" elevation="2" rounded small text>
						<v-icon class="mx-4">
							mdi-filter
						</v-icon>
						{{ $t('search.filter') }}
					</v-btn>
				</template>
				<v-card rounded="xl">
					<v-card-title>
						<span class="headline">{{ $t('search.filters') }}</span>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12" md="6" sm="12">
								<v-select
									v-model="filters.thesisOptions"
									:label="$t('offers.thesisOption')"
									:items="availableThesisOptions"
									item-text="name"
									item-value="key"
									chips
									multiple
									return-object
									outlined
								>
									<template v-slot:selection="data">
										<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
											{{ data.item.name }}
										</v-chip>
									</template>
								</v-select>
							</v-col>
							<v-col cols="12" md="3" sm="12">
								<v-select
									v-model="filters.status"
									:label="$t('applications.status')"
									:items="statusOptions"
									item-text="name"
									item-value="key"
									chips
									multiple
									return-object
									outlined
								>
									<template v-slot:selection="data">
										<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
											{{ data.item.name }}
										</v-chip>
									</template>
								</v-select>
							</v-col>
							<v-col cols="12" md="3" sm="12">
								<v-select
									v-model="filters.fullParttime"
									:label="$t('offers.fullParttime')"
									:items="fullParttimeOptions"
									item-text="name"
									item-value="key"
									chips
									multiple
									return-object
									outlined
								>
									<template v-slot:selection="data">
										<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
											{{ data.item.name }}
										</v-chip>
									</template>
								</v-select>
							</v-col>
							<v-col cols="12" md="6" sm="12">
								<v-select
									v-model="filters.offerTypes"
									:label="$t('offers.offerType')"
									:items="availableOfferTypes"
									item-text="name"
									item-value="key"
									chips
									multiple
									return-object
									outlined
								>
									<template v-slot:selection="data">
										<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
											{{ data.item.name }}
										</v-chip>
									</template>
								</v-select>
							</v-col>
							<v-col cols="12" md="12" sm="12">
								<v-autocomplete
									v-model="filters.sectors"
									:label="$t('companies.sectors')"
									:items="availableSectors"
									item-text="name"
									item-value="key"
									chips
									multiple
									return-object
									outlined
									clearable
								>
									<template #selection="{ attrs, item, select, selected }">
										<v-chip v-bind="attrs" :input-value="selected" close @click.stop="select" @click:close="deselectSector(item)">
											{{ item.name }}
										</v-chip>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col cols="12" md="12" sm="12">
								<v-autocomplete
									v-model="filters.companies"
									:label="$t('companies.company')"
									:items="availableCompanies"
									item-text="name"
									item-value="name"
									chips
									multiple
									return-object
									outlined
									clearable
								>
									<template #selection="{ attrs, item, select, selected }">
										<v-chip v-bind="attrs" :input-value="selected" close @click.stop="select" @click:close="deselectCompany(item)">
											{{ item.name }}
										</v-chip>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn color="blue darken-1" text @click="filtersSwitchEditMode()">
							{{ $t('search.close') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>

		<v-fade-transition group hide-on-leave>
			<template v-if="loadingOffer">
				<v-row key="loadingResults" no-gutters>
					<v-col cols="12" xl="3" lg="4" md="6" sm="12" v-for="i in 12" :key="`loadingResult${i}`" class="px-1">
						<v-skeleton-loader type="card" class="my-2 mx-auto flex-grow-1 rounded-xl" max-width="1200" />
					</v-col>
				</v-row>
			</template>

			<template v-else-if="offers.length">
				<v-row key="filteresResults" no-gutters>
					<v-col cols="12" xl="3" lg="4" md="6" sm="12" v-for="offer in filteredOffers" :key="`offer${offer.id}`" class="px-1 d-flex flex-column">
						<OfferSearchCard :offer="offer" />
					</v-col>
				</v-row>

				<template v-if="!filteredOffers.length">
					<h4 class="m-4 text-center" key="noResultsCriteria">
						{{ $t('search.noResultsCriteria') }}
					</h4>
				</template>
			</template>

			<template v-else>
				<div key="noItemsData" class="mx-auto">
					<div class="mt-2 mb-5 text-center">
						<v-icon size="128" role="img">
							mdi-package-variant
						</v-icon>
						<br />
						<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
					</div>
				</div>
			</template>
		</v-fade-transition>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function(letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function(match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	metaInfo() {
		return {
			title: this.$t('offers.myOffers')
		}
	},
	data() {
		return {
			filtersEditMode: false,
			title: '',
			statusOptions: [
				{ key: '0', name: this.$t('offers.blocked') },
				{ key: '1', name: this.$t('offers.active') },
				{ key: '2', name: this.$t('offers.solved') },
				{ key: '3', name: this.$t('offers.expired') }
			],
			fullParttimeOptions: [
				{ key: 0, name: this.$t('offers.parttime') },
				{ key: 1, name: this.$t('offers.fulltime') }
			],
			filters: {
				status: [{ key: '1', name: this.$t('offers.active') }],
				fullParttime: [],
				thesisOptions: [],
				offerTypes: [],
				companies: [],
				sectors: [],
				location: ''
			}
		}
	},
	components: {
		OfferSearchCard: () => import('@/components/offers/OfferSearchCard.vue')
	},
	computed: {
		filteredOffers() {
			return this.offers.filter(
				(offer) =>
					(!this.filters.thesisOptions.length || offer.info.thesisOptions.some((element) => this.thesisOptions_key.includes(element.key))) &&
					(!this.filters.offerTypes.length || this.offerTypes_key.includes(offer.info.type.key)) &&
					(!this.filters.fullParttime.length || this.fullParttime_key.includes(offer.info.time.key)) &&
					(!this.filters.companies.length || this.companiesName.includes(offer.company.name) || this.companiesName.includes(offer.companyName)) &&
					(!this.filters.sectors.length || offer.info.sectors.some((element) => this.sectors_id.includes(element.id))) &&
					(!this.filters.status.length || this.filters.status.map((e) => e.key).includes(offer.status.value)) &&
					(removeAccents(offer.info.location.city)
						.toLowerCase()
						.includes(removeAccents(this.filters.location).toLowerCase()) ||
						removeAccents(offer.info.location.province)
							.toLowerCase()
							.includes(removeAccents(this.filters.location).toLowerCase()) ||
						removeAccents(offer.info.location.country.name)
							.toLowerCase()
							.includes(removeAccents(this.filters.location).toLowerCase())) &&
					(removeAccents(offer.title)
						.toLowerCase()
						.includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(offer.info.location.city)
							.toLowerCase()
							.includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(offer.info.location.province)
							.toLowerCase()
							.includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(offer.info.location.country.name)
							.toLowerCase()
							.includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(offer.company.name)
							.toLowerCase()
							.includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(offer.companyName)
							.toLowerCase()
							.includes(removeAccents(this.title).toLowerCase()))
			)
		},
		thesisOptions_key() {
			return this.filters.thesisOptions.map((element) => element.key)
		},
		offerTypes_key() {
			return this.filters.offerTypes.map((element) => element.key)
		},
		fullParttime_key() {
			return this.filters.fullParttime.map((element) => element.key)
		},
		companiesName() {
			return this.filters.companies.map((element) => element.name)
		},
		sectors_id() {
			return this.filters.sectors.map((element) => element.id)
		},
		...mapGetters({
			offers: 'offers/offers',
			loadingOffer: 'offers/loading',
			availableCompanies: 'companies/companies',
			availableOfferTypes: 'data/availableOfferTypes',
			availableThesisOptions: 'data/availableThesisOptions',
			availableSectors: 'cv/availableSectors'
		})
	},
	created() {
		this.fetchAvailableOfferTypes()
		this.fetchAvailableThesisOptions()
		this.fetchAvailableSectors({ keywords: '' })
		this.fetchCompaniesFilter()
		this.setLoadingOffer(true)
		this.fetchOffers().then(() => {
			this.setLoadingOffer(false)
		})
	},
	methods: {
		filtersSwitchEditMode() {
			this.filtersEditMode = !this.filtersEditMode
		},
		deselectSector(item) {
			this.filters.sectors = this.filters.sectors.filter((i) => i != item)
		},
		deselectCompany(item) {
			this.filters.companies = this.filters.companies.filter((i) => i != item)
		},
		...mapActions('companies', ['fetchCompaniesFilter']),
		...mapActions('offers', ['setLoadingOffer', 'fetchOffers']),
		...mapActions('cv', ['fetchAvailableSectors']),
		...mapActions('data', ['fetchAvailableOfferTypes', 'fetchAvailableThesisOptions'])
	}
}
</script>
