<template>
	<v-container fluid>
		<h2>{{ $t('nav.users') }}</h2>
		<StatsUsersActive />

		<UsersTable />
	</v-container>
</template>

<script>
import StatsUsersActive from '@/components/stats/StatsUsersActive.vue'
import UsersTable from '@/components/users/UsersTable.vue'

export default {
	metaInfo() {
		return {
			title: this.$t('nav.users')
		}
	},
	components: {
		StatsUsersActive,
		UsersTable
	}
}
</script>

<style scoped></style>
