<template>
	<v-container fluid>
		<v-card max-width="600" class="mx-auto mt-5" rounded="xl">
			<v-card-title>
				<h1 class="display-1">
					{{ $t('auth.verify') }}
				</h1>
			</v-card-title>
			<v-card-text>
				<p>{{ $t('invoices.verifyEmailSent', { email: company.invoice.email }) }}.</p>
				<small class="form-text text-muted">{{ $t('auth.verifyEmailSentDuration') }} {{ $t('auth.verifyEmailSentSpam') }}</small>
			</v-card-text>

			<v-divider />

			<v-card-actions>
				<v-btn color="primary" @click="callSendVerifyInvoiceEmail()">
					{{ $t('auth.resendEmail') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('invoices.invoiceVerification')
		}
	},
	computed: {
		...mapGetters({
			company: 'companies/company'
		})
	},
	methods: {
		callSendVerifyInvoiceEmail() {
			this.sendVerifyInvoiceEmail()
		},
		...mapActions('invoices', ['sendVerifyInvoiceEmail'])
	}
}
</script>
