<template>
	<v-app>
		<Sidebar />
		<Navbar />
		<v-main app>
			<div class="d-flex justify-space-between" :style="{ marginBottom: $vuetify.breakpoint.lgAndUp ? '50px' : 0 }">
				<div class="flex-grow-1">
					<router-view />
				</div>
			</div>

			<Dialog />
			<SnackBar />
			<Footer />
		</v-main>
		<BottomNavbar v-if="$vuetify.breakpoint.mdAndDown" />
	</v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Sidebar from '@/components/main/Sidebar.vue'
import Navbar from '@/components/main/Navbar.vue'
import Footer from '@/components/main/Footer.vue'
import BottomNavbar from '@/components/main/BottomNavbar.vue'
import Dialog from '@/components/feedback/Dialog.vue'
import SnackBar from '@/components/feedback/SnackBar.vue'

export default {
	metaInfo: {
		title: 'App',
		titleTemplate: '%s | Admin OverSOS',
		meta: [{ charset: 'utf-8' }]
	},
	components: {
		Sidebar,
		Navbar,
		Footer,
		Dialog,
		SnackBar,
		BottomNavbar
	},
	computed: {
		darkTheme() {
			return this.styleTheme === 'dark'
		},
		...mapGetters({
			authenticated: 'auth/authenticated',
			currentUser: 'user/currentUser',
			styleTheme: 'user/styleTheme'
		})
	},
	created() {
		if (this.authenticated) {
			this.refreshAccess().then(() => {
				this.update_locale()
			})
		} else {
			this.update_locale()
		}
		const lang = this.$route.query.lang
		if (lang) {
			if (['en', 'es', 'ca', 'de'].includes(lang)) {
				this.$i18n.locale = lang
			}
		}
	},
	watch: {
		styleTheme() {
			this.$vuetify.theme.dark = this.darkTheme
		}
	},
	methods: {
		update_locale() {
			if (this.authenticated) {
				this.$i18n.locale = this.currentUser.locale.key
				this.$moment.locale(this.currentUser.locale.key)
			} else {
				const lang = this.$route.query.lang
				// TODO: Not working, lang is undefined at refresh -.-
				if (lang) {
					if (['en', 'es', 'ca'].includes(lang)) {
						this.$i18n.locale = lang
						this.$moment.locale(lang)
					}
				} else {
					this.$i18n.locale = this.getBrowserLocale()
					this.$moment.locale(this.getBrowserLocale())
				}
			}
		},
		getBrowserLocale() {
			let language
			navigator.languages.forEach((locale) => {
				const localeLang = locale.split('-')[0]
				if (['en', 'es', 'ca'].includes(localeLang) && !language) {
					language = localeLang
				}
			})
			return language ? language : 'en'
		},
		...mapActions('auth', ['refreshAccess'])
	}
}
</script>

<style>
.v-chip__content {
	white-space: normal;
}
#app {
	background: var(--v-background-base);
}
.v-dialog .v-card {
	background: var(--v-background-base) !important;
}
.v-menu__content .v-card {
	background: var(--v-background-base) !important;
}
</style>
