<template>
	<v-container fluid>
		<FacultyInfo :loading-parent="loading" />

		<FacultyDegrees :loading-parent="loading" />
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FacultyInfo from '@/components/faculties/FacultyInfo.vue'
import FacultyDegrees from '@/components/faculties/FacultyDegrees.vue'

export default {
	metaInfo() {
		return {
			title: this.$t('faculties.faculty') + ' - ' + this.faculty.name
		}
	},
	data() {
		return {
			loading: true
		}
	},
	components: {
		FacultyInfo,
		FacultyDegrees
	},
	watch: {
		'$route.params.id': function(id) {
			this.loading = true
			this.fetchFaculty(id).then(() => {
				this.loading = false
			})
		}
	},
	computed: {
		...mapGetters({
			faculty: 'faculties/faculty'
		})
	},
	created() {
		this.fetchFaculty(this.$route.params.id).then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('faculties', ['fetchFaculty'])
	}
}
</script>
