export const namespaced = true

export const state = () => ({
	subscription: null,
	subscriptions: [],
	subscriptionTypes: []
})

export const getters = {
	subscription: (state) => state.subscription,
	subscriptions: (state) => state.subscriptions,
	subscriptionTypes: (state) => state.subscriptionTypes
}

export const actions = {
	fetchSubscription({ commit, dispatch }, subscriptionID) {
		commit('SET_SUBSCRIPTION', null)
		return this.$api
			.get(`/subscriptions/${subscriptionID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SUBSCRIPTION', data.subscription)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchSubscriptions({ commit, dispatch }) {
		commit('SET_SUBSCRIPTIONS', [])
		return this.$api
			.get('/subscriptions')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SUBSCRIPTIONS', data.subscriptions)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchSubscriptionsCompany({ commit, dispatch }, { companyID }) {
		commit('SET_SUBSCRIPTIONS', [])
		return this.$api
			.get(`/subscriptions/company/${companyID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SUBSCRIPTIONS', data.subscriptions)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchSubscriptionTypes({ commit, dispatch }) {
		commit('SET_SUBSCRIPTION_TYPES', [])
		return this.$api
			.get('/subscriptions/types')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SUBSCRIPTION_TYPES', data.subscriptionTypes)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	purchaseSubscription({ commit, dispatch }, subscription) {
		return this.$api
			.put('/subscriptions/purchase', {
				companyID: subscription.companyID,
				typeID: subscription.typeID,
				period: subscription.period
			})
			.then(({ data }) => {
				if (data.success) {
					commit('PURCHASE_SUBSCRIPTION', data.subscription)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	finishSubscription({ commit, dispatch }, subscriptionID) {
		return this.$api
			.post(`/subscriptions/${subscriptionID}/finish`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SUBSCRIPTION', data.subscription)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	cancelSubscription({ commit, dispatch }, subscriptionID) {
		return this.$api
			.post(`/subscriptions/${subscriptionID}/cancel`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SUBSCRIPTION', data.subscription)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	cancelPayment({ commit, dispatch }, paymentID) {
		return this.$api
			.post(`/subscriptions/payments/${paymentID}/cancel`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SUBSCRIPTION', data.subscription)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	pendingPayment({ commit, dispatch }, paymentID) {
		return this.$api
			.post(`/subscriptions/payments/${paymentID}/pending`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SUBSCRIPTION', data.subscription)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	paidPayment({ commit, dispatch }, paymentID) {
		return this.$api
			.post(`/subscriptions/payments/${paymentID}/paid`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SUBSCRIPTION', data.subscription)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	uploadInvoice({ commit, dispatch }, { paymentID, document }) {
		const formData = new FormData()
		formData.append('document', document, document.name)
		return this.$api
			.post(`/subscriptions/payments/${paymentID}/invoice`, formData)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SUBSCRIPTION', data.subscription)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	deleteInvoice({ commit, dispatch }, paymentID) {
		return this.$api
			.delete(`/subscriptions/payments/${paymentID}/invoice`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_SUBSCRIPTION', data.subscription)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	downloadInvoice({ dispatch }, paymentID) {
		return this.$api
			.get(`/subscriptions/payments/${paymentID}/invoice`, {
				responseType: 'blob'
			})
			.then(({ headers, data }) => {
				const filename = headers['content-disposition'].split('filename=')[1]
				const type = headers['content-type']
				const blob = new Blob([data], {
					type,
					encoding: 'UTF-8'
				})
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = filename
				link.click()
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	sendPaymentInstructions({ dispatch }, paymentID) {
		return this.$api
			.post(`/subscriptions/payments/${paymentID}/instructions`)
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_SUBSCRIPTION: (state, subscription) => (state.subscription = subscription),
	SET_SUBSCRIPTIONS: (state, subscriptions) => (state.subscriptions = subscriptions),
	SET_SUBSCRIPTION_TYPES: (state, subscriptionTypes) => (state.subscriptionTypes = subscriptionTypes),
	PURCHASE_SUBSCRIPTION: (state, subscription) => {
		state.subscription = subscription
		state.subscriptions.unshift(subscription)
	}
}
