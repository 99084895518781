export const namespaced = true

const companyDefault = {
	id: 0,
	imgURL: '',
	name: ''
}

export const state = () => ({
	loading: false,
	companies: [],
	company: companyDefault
})

export const getters = {
	loading: (state) => state.loading,
	companies: (state) => state.companies,
	company: (state) => state.company
}

export const actions = {
	setLoadingCompany({ commit }, loading) {
		commit('SET_LOADING', loading)
	},
	fetchCompanies({ commit, dispatch }) {
		commit('SET_COMPANIES', [])
		return this.$api
			.get('/companies')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_COMPANIES', data.companies)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchCompaniesFilter({ commit, dispatch }) {
		commit('SET_COMPANIES', [])
		return this.$api
			.get('/companies/filter')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_COMPANIES', data.companies)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchCompany({ commit, dispatch }, companyID) {
		commit('REMOVE_COMPANY')
		return this.$api
			.get(`/companies/${companyID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_COMPANY', data.company)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	inviteAdmin({ commit, dispatch }, { companyID, email }) {
		return this.$api
			.post(`/companies/${companyID}/invite`, {
				email
			})
			.then(({ data }) => {
				if (data.success) {
					commit('companies/INVITE_ADMIN', data.invitedAdmin, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	cancelInvitation({ commit, dispatch }, { companyID, employerID }) {
		return this.$api
			.post(`/companies/${companyID}/invite/${employerID}/cancel`)
			.then(({ data }) => {
				if (data.success) {
					commit('companies/REMOVE_ADMIN', employerID, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	removeAdmin({ commit, dispatch }, { companyID, employerID }) {
		return this.$api
			.post(`/companies/${companyID}/remove/${employerID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('companies/REMOVE_ADMIN', employerID, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	changeContact({ commit, dispatch }, { companyID, employer }) {
		return this.$api
			.post(`/companies/${companyID}/settings/contact`, {
				employerID: employer.id
			})
			.then(({ data }) => {
				if (data.success) {
					commit('companies/CHANGE_CONTACT', employer, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateCompanyLogo({ commit, dispatch }, { companyID, formData }) {
		return this.$api
			.post(`/companies/${companyID}/settings/logo`, formData)
			.then(({ data }) => {
				if (data.success) {
					commit('companies/UPDATE_IMG_URL', data.imgURL, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	deleteCompanyLogo({ commit, dispatch }, companyID) {
		return this.$api
			.delete(`/companies/${companyID}/settings/logo`)
			.then(({ data }) => {
				if (data.success) {
					commit('companies/UPDATE_IMG_URL', data.imgURL, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateCompanyInfo({ commit, dispatch }, { companyID, name, website, employees, freeTier }) {
		return this.$api
			.post(`/companies/${companyID}/settings/info`, {
				name,
				website,
				employees,
				freeTier
			})
			.then(({ data }) => {
				if (data.success) {
					commit('companies/SET_COMPANY', data.company, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateCompanySectors({ commit, dispatch }, { companyID, sectors }) {
		return this.$api
			.post(`/companies/${companyID}/settings/sectors`, {
				sectors
			})
			.then(({ data }) => {
				if (data.success) {
					commit('companies/SET_COMPANY', data.company, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateCompanyDescription({ commit, dispatch }, { companyID, description }) {
		return this.$api
			.post(`/companies/${companyID}/settings/description`, {
				description
			})
			.then(({ data }) => {
				if (data.success) {
					commit('companies/SET_COMPANY', data.company, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateCompanyInvoiceInfo(
		{ commit, dispatch },
		{ companyID, attention, companyName, email, address1, address2, postCode, city, province, country, taxCode }
	) {
		return this.$api
			.post(`/companies/${companyID}/settings/invoice`, {
				attention,
				companyName,
				email,
				address1,
				address2,
				postCode,
				city,
				province,
				country,
				taxCode
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_COMPANY', data.company)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	purchasePack({ commit, dispatch }, { companyID, pack }) {
		return this.$api
			.post(`/companies/${companyID}/purchase`, {
				pack
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_COMPANY', data.company)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	addSector({ commit, dispatch }, { companyID, sectorID }) {
		return this.$api
			.put(`/companies/${companyID}/sectors`, {
				sectorID
			})
			.then(({ data }) => {
				if (data.success) {
					commit('ADD_SECTOR', data.sector)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	removeSector({ commit, dispatch }, { companyID, sectorID }) {
		return this.$api
			.delete(`/companies/${companyID}/sectors/${sectorID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('REMOVE_SECTOR', sectorID)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_LOADING: (state, loading) => (state.loading = loading),
	SET_COMPANIES: (state, companies) => (state.companies = companies),
	SET_COMPANY: (state, company) => (state.company = company),
	REMOVE_COMPANY: (state) => (state.company = companyDefault),
	INVITE_ADMIN: (state, admin) => state.company.administrators.administrators.push(admin),
	REMOVE_ADMIN: (state, employerID) =>
		(state.company.administrators.administrators = state.company.administrators.administrators.filter((admin) => admin.id != employerID)),
	CHANGE_CONTACT: (state, contact) => (state.company.contact = contact),
	UPDATE_IMG_URL: (state, imgURL) => (state.company.imgURL = imgURL),
	ADD_SECTOR: (state, sector) => state.company.info.sectors.push(sector),
	EDIT_SECTOR: (state, sector) => (state.company.info.sectors[state.company.info.sectors.findIndex((x) => x.id === sector.id)] = sector),
	REMOVE_SECTOR: (state, sectorID) => (state.company.info.sectors = state.company.info.sectors.filter((sector) => sector.id !== sectorID))
}
