<template>
	<v-container fluid>
		<h2 class="my-2 text-center">{{ $t('faculties.addNewFaculty') }}</h2>
		<FacultyAddForm :loading-parent="loading" />
	</v-container>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: this.$t('faculties.addNewFaculty')
		}
	},
	data: function() {
		return {
			loading: false
		}
	},
	components: {
		FacultyAddForm: () => import('@/components/faculties/FacultyAddForm.vue')
	}
}
</script>
