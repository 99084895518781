<template>
	<v-container fluid>
		<h2>{{ $t('degrees.degree') }} - ID {{ degree.id }}</h2>

		<v-row>
			<v-col cols="12" lg="4" sm="6" xs="12">
				<v-card rounded="xl">
					<v-card-title>{{ $t('auth.name') }}</v-card-title>
					<v-card-text>
						<v-form ref="formName" v-model="validName">
							<v-text-field v-model="nameEN" :label="$t('languages.english')" :counter="nameMaxLength" :rules="rules.nameEN" outlined />
							<v-text-field v-model="nameES" :label="$t('languages.spanish')" :counter="nameMaxLength" :rules="rules.nameES" outlined />
							<v-text-field v-model="nameCA" :label="$t('languages.catalan')" :counter="nameMaxLength" :rules="rules.nameCA" outlined />
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-btn color="primary" @click="callEditName()">
							{{ $t('settings.edit') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
			<v-col cols="12" lg="4" sm="6" xs="12">
				<v-card rounded="xl">
					<v-card-title>{{ $t('courses.academical') }}</v-card-title>
					<v-card-text>
						<v-form ref="formAcademical" v-model="validAcademical">
							<v-text-field :value="`[${degree.faculty.acronym}] ${degree.faculty.name}`" :label="$t('faculties.faculty')" outlined readonly />
							<v-select
								v-model="degreeType"
								:items="availableDegreeTypes"
								item-text="name"
								item-value="key"
								:label="$t('degrees.degreeType')"
								:rules="rules.degreeType"
								return-object
								outlined
							/>
							<v-text-field v-model="acronym" :label="$t('courses.acronym')" :counter="acronymMaxLength" :rules="rules.acronym" outlined />
							<v-textarea
								v-model="practicesTimetableURL"
								:label="$t('degrees.practicesTimetableURL')"
								:counter="practicesTimetableURLMaxLength"
								:rules="rules.practicesTimetableURL"
								auto-grow
								outlined
							/>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-btn color="primary" @click="callEditAcademical()">
							{{ $t('settings.edit') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
			<v-col cols="12" lg="4" sm="6" xs="12">
				<v-card rounded="xl">
					<v-card-title>Google Drive</v-card-title>
					<v-card-text>
						<v-form ref="formGDrive" v-model="validGDrive">
							<v-select
								v-model="gDrive"
								:items="gDrives"
								item-text="email"
								item-value="id"
								:label="$t('gdrive.account')"
								outlined
								return-object
								:rules="rules.gDrive"
							/>
							<v-text-field
								v-model="xlsxExamsID"
								:label="$t('degrees.xlsxExamsID')"
								:counter="xlsxExamsIDMaxLength"
								:rules="rules.xlsxExamsID"
								outlined
							/>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-btn color="primary" @click="callEditGDrive()">
							{{ $t('settings.edit') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" lg="6" md="6" xs="12">
				<v-card rounded="xl">
					<v-card-title>
						{{ $t('students.students') }}
						<v-spacer />
						<div><v-icon>mdi-school</v-icon> {{ degree.studentsNum }}</div>
						<v-spacer />
						<v-btn icon @click="updateTable(page, perPage)">
							<v-icon>mdi-sync</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<template v-if="students.length">
							<div class="text-center">
								<v-pagination :value="page" :length="pages" @input="goToPage" />
							</div>
							<v-simple-table>
								<template v-slot:default>
									<thead>
										<tr>
											<th />
											<th>ID</th>
											<th>{{ $t('auth.name') }}</th>
											<th>{{ $t('auth.email') }}</th>
											<th />
										</tr>
									</thead>
									<tbody>
										<tr v-for="student in students" :key="student.id">
											<td>
												<v-btn icon :to="{ name: 'User', params: { id: student.user.id } }">
													<v-icon>mdi-pencil</v-icon>
												</v-btn>
											</td>
											<td>{{ student.id }}</td>
											<td>{{ student.user.fullname }}</td>
											<td>{{ student.email }}</td>
											<td>
												<i v-if="student.verified" class="far fa-check-circle" style="color: green" />
												<i v-else class="far fa-times-circle" style="color: red;" />
											</td>
										</tr>
									</tbody>
									<tfoot>
										<tr>
											<th />
											<th>ID</th>
											<th>{{ $t('auth.name') }}</th>
											<th>{{ $t('auth.email') }}</th>
											<th />
										</tr>
									</tfoot>
								</template>
							</v-simple-table>

							<div class="text-center">
								<v-pagination :value="page" :length="pages" @input="goToPage" />
							</div>
						</template>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" lg="6" md="6" xs="12">
				<v-card rounded="xl">
					<v-card-title>
						{{ $t('nav.courses') }}
						<v-spacer />
						<v-btn icon :to="{ name: 'StudiesCourseAdd' }">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<v-toolbar @click="mandatoryCollapsed = !mandatoryCollapsed">
							<v-toolbar-title>{{ $t('courses.mandatory') }}</v-toolbar-title>
						</v-toolbar>
						<template v-if="!mandatoryCollapsed">
							<div v-for="(semester, index) in degree.mandatoryCourses" :key="`mandatory${index}`">
								<h5 class="text-center">
									{{ semester.semester }}
								</h5>
								<v-row v-for="{ course, semesters } in semester.courses" :key="course.id">
									<v-col cols="12" md="6" sm="12">
										<span style="word-break: normal">{{ course.name }} </span>
									</v-col>
									<v-col cols="12" md="6" sm="12" class="d-flex justify-space-between">
										<span><v-icon>mdi-school</v-icon> {{ course.studentsNum }}</span>
										<span><v-icon>mdi-google-drive</v-icon> {{ course.gDriveEmailsNum }}</span>
										<div>
											<span v-for="(semesterCourse, semesterIndex) in semesters" :key="semesterCourse.key">
												{{ semesterCourse.name }}{{ isLastItem(semesterIndex, semesters) ? '' : '/' }}
											</span>
										</div>
										<v-btn icon :to="{ name: 'StudiesCourse', params: { id: course.id } }">
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
									</v-col>
								</v-row>
								<div v-if="!semester.courses.length" class="text-center">
									<span>{{ $t('courses.noCourses') }}</span>
								</div>
							</div>
						</template>

						<v-toolbar @click="electiveCollapsed = !electiveCollapsed">
							<v-toolbar-title>{{ $t('courses.elective') }}</v-toolbar-title>
						</v-toolbar>
						<template v-if="!electiveCollapsed">
							<div v-for="(semester, index) in degree.electiveCourses" :key="`elective${index}`">
								<h5 class="text-center">
									{{ semester.semester }}
								</h5>
								<v-row v-for="{ course, semesters } in semester.courses" :key="course.id">
									<v-col cols="12" md="6" sm="12">
										<span style="word-break: normal">{{ course.name }} </span>
									</v-col>
									<v-col cols="12" md="6" sm="12" class="d-flex justify-space-between">
										<span><v-icon>mdi-school</v-icon> {{ course.studentsNum }}</span>
										<span><v-icon>mdi-google-drive</v-icon> {{ course.gDriveEmailsNum }}</span>
										<div>
											<span v-for="(semesterCourse, semesterIndex) in semesters" :key="semesterCourse.key">
												{{ semesterCourse.name }}{{ isLastItem(semesterIndex, semesters) ? '' : '/' }}
											</span>
										</div>
										<v-btn icon :to="{ name: 'StudiesCourse', params: { id: course.id } }">
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
									</v-col>
								</v-row>
								<div v-if="!semester.courses.length" class="text-center">
									<span>{{ $t('courses.noCourses') }}</span>
								</div>
							</div>
						</template>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('degrees.degree') + ' - ' + this.degree.name
		}
	},
	data() {
		return {
			validName: false,
			validAcademical: false,
			validGDrive: false,
			nameEN: '',
			nameES: '',
			nameCA: '',
			degreeType: null,
			acronym: '',
			practicesTimetableURL: '',
			xlsxExamsID: '',
			gDrive: null,
			rules: {
				nameEN: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.nameMaxLength) || this.$t('rules.length', { length: this.nameMaxLength })
				],
				nameES: [(v) => v.length <= this.nameMaxLength || this.$t('rules.length', { length: this.nameMaxLength })],
				nameCA: [(v) => v.length <= this.nameMaxLength || this.$t('rules.length', { length: this.nameMaxLength })],
				degreeType: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && this.availableDegreeTypes.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported')
				],
				gDrive: [
					(v) => {
						if (v) return this.gDrives.map(({ id }) => id).includes(v.id) || this.$t('rules.notSupported')
						else return true
					}
				],
				acronym: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.acronymMaxLength) || this.$t('rules.length', { length: this.acronymMaxLength })
				],
				practicesTimetableURL: [
					(v) => v.length <= this.practicesTimetableURLMaxLength || this.$t('rules.length', { length: this.practicesTimetableURLMaxLength })
				],
				xlsxExamsIDRules: [(v) => v.length <= this.xlsxExamsIDRulesMaxLength || this.$t('rules.length', { length: this.xlsxExamsIDRulesMaxLength })]
			},
			loading: true,
			mandatoryCollapsed: true,
			electiveCollapsed: true,
			loadingStudents: false,
			page: 1,
			perPage: 25,
			pages: 1
		}
	},
	computed: {
		nameMaxLength() {
			return 128
		},
		acronymMaxLength() {
			return 32
		},
		practicesTimetableURLMaxLength() {
			return 255
		},
		xlsxExamsIDMaxLength() {
			return 128
		},
		...mapGetters({
			degree: 'degrees/degree',
			students: 'students/students',
			gDrives: 'gdrives/gDrives',
			availableDegreeTypes: 'data/availableDegreeTypes'
		})
	},
	watch: {
		'$route.params.id': function(id) {
			this.fetchDegree(id).then(({ degree }) => {
				this.nameEN = degree.nameEN
				this.nameES = degree.nameES
				this.nameCA = degree.nameCA
				this.acronym = degree.acronym
				this.practicesTimetableURL = degree.practicesTimetableURL
				this.xlsxExamsID = degree.xlsxExamsID
				this.degreeType = degree.type
				this.gDrive = degree.gDrive
				this.page = 1
				this.perPage = 25
				this.pages = 1
			})
		}
	},
	created() {
		this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
		this.perPage = this.$route.query.perPage ? parseInt(this.$route.query.perPage) : 25
		this.fetchGDrives().then(() => {
			this.loading = false
		})
		this.loading = true
		this.fetchAvailableDegreeTypes().then(() => {
			this.loading = false
		})
		this.loading = true
		this.fetchDegree(this.$route.params.id)
			.then(({ degree }) => {
				this.nameEN = degree.nameEN
				this.nameES = degree.nameES
				this.nameCA = degree.nameCA
				this.acronym = degree.acronym
				this.practicesTimetableURL = degree.practicesTimetableURL
				this.xlsxExamsID = degree.xlsxExamsID
				this.degreeType = degree.type
				this.gDrive = degree.gDrive
				if (this.$route.query.page && this.$route.query.perPage) {
					this.updateTable(this.page, this.perPage)
				}
			})
			.then(() => {
				this.loading = false
			})
	},
	methods: {
		isLastItem(index, list) {
			return index + 1 === list.length
		},
		goToPage(page) {
			this.updateTable(page, this.perPage)
		},
		updateTable(toPage, perPage) {
			this.loading = true
			if (toPage !== this.page || perPage !== this.perPage) {
				this.$router.replace({ name: this.$route.name, params: { id: this.degree.id }, query: { page: toPage, perPage: perPage } })
			}
			this.fetchStudentsByDegree({ page: toPage, perPage: perPage, degreeID: this.degree.id })
				.then(({ pages }) => {
					this.page = toPage
					this.perPage = perPage
					this.pages = pages
				})
				.then(() => {
					this.loading = false
				})
		},
		callEditName() {
			this.$refs.formName.validate()
			if (!this.validName) return
			this.loading = true
			this.editName({
				degreeID: this.degree.id,
				nameEN: this.nameEN,
				nameES: this.nameES,
				nameCA: this.nameCA
			}).then(() => {
				this.loading = false
			})
		},
		callEditAcademical() {
			this.$refs.formAcademical.validate()
			if (!this.validAcademical) return
			this.loading = true
			this.editAcademical({
				degreeID: this.degree.id,
				facultyID: this.degree.faculty.id,
				degreeType: this.degreeType ? this.degreeType.key : '',
				acronym: this.acronym,
				practicesTimetableURL: this.practicesTimetableURL
			}).then(() => {
				this.loading = false
			})
		},
		callEditGDrive() {
			this.$refs.formGDrive.validate()
			if (!this.validGDrive) return
			this.loading = true
			console.log(this.xlsxExamsID)
			this.editGDrive({
				degreeID: this.degree.id,
				xlsxExamsID: this.xlsxExamsID ? this.xlsxExamsID : '',
				gDriveID: this.gDrive ? this.gDrive.id : 0
			}).then(() => {
				this.loading = false
			})
		},
		...mapActions('degrees', ['fetchDegree', 'editName', 'editAcademical', 'editGDrive']),
		...mapActions('students', ['fetchStudentsByDegree']),
		...mapActions('data', ['fetchAvailableDegreeTypes']),
		...mapActions('gdrives', ['fetchGDrives'])
	}
}
</script>
