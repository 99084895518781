<template>
	<v-card max-width="1800" class="mx-auto" rounded="xl">
		<v-card-title>
			<span>{{ $t('degrees.degrees') }}</span>
			<v-spacer />
			<v-btn icon :to="{ name: 'StudiesDegreeAdd' }">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-card-title>
		<v-card-text>
			<v-row v-for="degree in faculty.degrees" :key="degree.id" :degree="degree">
				<v-col cols="12" md="8" sm="12">
					<span style="word-break: normal">{{ degree.type }} - [{{ degree.acronym }}] {{ degree.name }} </span>
				</v-col>
				<v-col cols="12" md="4" sm="12" class="d-flex justify-space-between">
					<span><v-icon>mdi-school</v-icon> {{ degree.studentsNum }}</span>
					<v-btn icon :to="{ name: 'StudiesDegree', params: { id: degree.id } }">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</v-col>
			</v-row>

			<v-alert v-if="!faculty.degrees.length">
				<h4>No degrees for this faculty</h4>
			</v-alert>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'FacultyDegrees',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true
		}
	},
	computed: {
		...mapGetters({
			faculty: 'faculties/faculty'
		})
	},
	created() {
		this.loading = false
	},
	methods: {}
}
</script>
