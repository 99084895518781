export const namespaced = true

export const state = () => ({
	availableLanguages: [],
	availableKnownLanguages: [],
	availableSemesters: [],
	availableOfferTypes: [],
	availableThesisOptions: [],
	availableDegreeTypes: [],
	availableUserTypes: [],
	availableEmployeeOptions: [],
	availablePacks: [],
	availableCountries: [],
	availableEmailTypes: [],
	availableNewsletters: [],
	availableSpainProvinces: [],
	availableDegrees: [],
	availableLanguageLevels: []
})

export const getters = {
	availableLanguages: (state) => state.availableLanguages,
	availableKnownLanguages: (state) => state.availableKnownLanguages,
	availableSemesters: (state) => state.availableSemesters,
	availableOfferTypes: (state) => state.availableOfferTypes,
	availableThesisOptions: (state) => state.availableThesisOptions,
	availableDegreeTypes: (state) => state.availableDegreeTypes,
	availableUserTypes: (state) => state.availableUserTypes,
	availableEmployeeOptions: (state) => state.availableEmployeeOptions,
	availablePacks: (state) => state.availablePacks,
	availableCountries: (state) => state.availableCountries,
	availableEmailTypes: (state) => state.availableEmailTypes,
	availableNewsletters: (state) => state.availableNewsletters,
	availableSpainProvinces: (state) => state.availableSpainProvinces,
	availableDegrees: (state) => state.availableDegrees,
	availableLanguageLevels: (state) => state.availableLanguageLevels
}

export const actions = {
	fetchAvailableLanguages({ commit, dispatch }) {
		return this.$api
			.get('/data/languages/web', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_LANGUAGES', data.languages)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableKnownLanguages({ commit, dispatch }) {
		return this.$api
			.get('/data/languages/known', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_KNOWN_LANGUAGES', data.languages)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchSemesters({ commit, dispatch }) {
		return this.$api
			.get('/data/semesters', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_SEMESTERS', data.semesters)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableOfferTypes({ commit, dispatch }) {
		return this.$api
			.get('/data/offerTypes', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_OFFER_TYPES', data.offerTypes)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableThesisOptions({ commit, dispatch }) {
		return this.$api
			.get('/data/thesisOptions', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_THESIS_OPTIONS', data.thesisOptions)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableDegreeTypes({ commit, dispatch }) {
		return this.$api
			.get('/data/degreeTypes', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_DEGREE_TYPES', data.degreeTypes)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableUserTypes({ commit, dispatch }) {
		return this.$api
			.get('/data/userTypes', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_USER_TYPES', data.userTypes)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableEmployeeOptions({ commit, dispatch }) {
		return this.$api
			.get('/data/employeeOptions', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_EMPLOYEE_OPTIONS', data.employeeOptions)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableCountries({ commit, dispatch }) {
		return this.$api
			.get('/data/countries', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_COUNTRIES', data.countries)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailablePacks({ commit, dispatch }) {
		return this.$api
			.get('/data/packs', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_PACKS', data.packs)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableEmailTypes({ commit, dispatch }) {
		return this.$api
			.get('/data/emailTypes', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_EMAIL_TYPES', data.emailTypes)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableNewsletters({ commit, dispatch }) {
		return this.$api
			.get('/data/newsletters', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_NEWSLETTERS', data.newsletters)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableSpainProvinces({ commit, dispatch }) {
		return this.$api
			.get('/data/provinces', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_SPAIN_PROVINCES', data.provinces)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableDegrees({ commit, dispatch }, { keywords }) {
		return this.$api
			.get('/data/degrees', {
				params: {
					locale: this.$i18n.locale,
					keywords
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('UPDATE_AVAILABLE_DEGREES', data.degrees)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchAvailableLanguageLevels({ commit, dispatch }) {
		return this.$api
			.get('/data/languageLevels', {
				params: {
					locale: this.$i18n.locale
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_AVAILABLE_LANGUAGE_LEVELS', data.languageLevels)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_AVAILABLE_LANGUAGES: (state, availableLanguages) => (state.availableLanguages = availableLanguages),
	SET_AVAILABLE_KNOWN_LANGUAGES: (state, availableKnownLanguages) => (state.availableKnownLanguages = availableKnownLanguages),
	SET_AVAILABLE_SEMESTERS: (state, availableSemesters) => (state.availableSemesters = availableSemesters),
	SET_AVAILABLE_OFFER_TYPES: (state, availableOfferTypes) => (state.availableOfferTypes = availableOfferTypes),
	SET_AVAILABLE_THESIS_OPTIONS: (state, availableThesisOptions) => (state.availableThesisOptions = availableThesisOptions),
	SET_AVAILABLE_DEGREE_TYPES: (state, availableDegreeTypes) => (state.availableDegreeTypes = availableDegreeTypes),
	SET_AVAILABLE_USER_TYPES: (state, availableUserTypes) => (state.availableUserTypes = availableUserTypes),
	SET_AVAILABLE_PACKS: (state, availablePacks) => (state.availablePacks = availablePacks),
	SET_AVAILABLE_EMPLOYEE_OPTIONS: (state, availableEmployeeOptions) => (state.availableEmployeeOptions = availableEmployeeOptions),
	SET_AVAILABLE_COUNTRIES: (state, availableCountries) => (state.availableCountries = availableCountries),
	SET_AVAILABLE_EMAIL_TYPES: (state, availableEmailTypes) => (state.availableEmailTypes = availableEmailTypes),
	SET_AVAILABLE_NEWSLETTERS: (state, availableNewsletters) => (state.availableNewsletters = availableNewsletters),
	SET_AVAILABLE_SPAIN_PROVINCES: (state, availableSpainProvinces) => (state.availableSpainProvinces = availableSpainProvinces),
	UPDATE_AVAILABLE_DEGREES: (state, availableDegrees) =>
		(state.availableDegrees = Array.from(new Set(state.availableDegrees.concat(availableDegrees)))),
	SET_AVAILABLE_LANGUAGE_LEVELS: (state, availableLanguageLevels) => (state.availableLanguageLevels = availableLanguageLevels)
}
