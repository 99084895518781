<template>
	<v-container fluid>
		<v-card max-width="800" class="mx-auto mt-5" rounded="xl">
			<v-card-title>
				<div>
					<span class="text-h4">{{ $t('subscriptions.subscription') }}:</span>
					<br />
					<span class="text-h4">{{ subscription ? subscription.type.name : '' }}</span>
				</div>
				<v-spacer />
				<v-btn v-if="subscription.status.active.value" text @click="callCancelSubscription(subscription.id)" color="error" outlined>
					{{ $t('offers.cancel') }}
				</v-btn>
			</v-card-title>
			<v-card-subtitle>
				<span class="text-subtitle-1">{{ subscription ? subscription.company.name : '' }}</span>
				<br />
				<span class="text-body-2">
					{{ humanTime(subscription.timestamp.start) }} - {{ subscription.timestamp.end ? humanTime(subscription.timestamp.start) : '' }}
				</span>
			</v-card-subtitle>
			<v-card-text>
				<v-alert
					v-if="subscription.status.canceled.value"
					border="top"
					elevation="8"
					type="warning"
					color="red darken-3"
					max-width="500"
					dark
					class="mx-auto text-uppercase text-center"
				>
					{{ $t('offers.canceled') }}
				</v-alert>
				<v-alert
					v-else-if="!subscription.status.active.value"
					border="top"
					elevation="8"
					type="warning"
					color="red darken-3"
					max-width="500"
					dark
					class="mx-auto text-uppercase text-center"
				>
					{{ $t('subscriptions.notActiveSubscription') }}
				</v-alert>
				<v-alert
					v-else-if="subscription.status.unpaid.value"
					border="top"
					elevation="8"
					type="warning"
					color="red darken-3"
					max-width="500"
					dark
					class="mx-auto text-uppercase text-center"
				>
					{{ $t('subscriptions.unpaidSubscription') }}
				</v-alert>
				<div class="mx-auto" style="max-width: 500px">
					<v-subheader>
						{{
							$t('offers.numberOfOffers', {
								num: subscription.limits.offers.num,
								total: subscription.limits.offers.maxPurchased,
								remaining: subscription.limits.offers.remaining
							})
						}}
					</v-subheader>
					<v-progress-linear
						:value="(subscription.limits.offers.num / subscription.limits.offers.maxPurchased) * 100"
						color="blue darken-1"
						height="25"
					>
						<template #default>
							<strong>{{ `${subscription.limits.offers.num}/${subscription.limits.offers.maxPurchased}` }}</strong>
						</template>
					</v-progress-linear>
					<br />
					<v-subheader>
						{{
							$t('events.numberOfEvents', {
								num: subscription.limits.events.num,
								total: subscription.limits.events.maxPurchased,
								remaining: subscription.limits.events.remaining
							})
						}}
					</v-subheader>
					<v-progress-linear
						:value="(subscription.limits.events.num / subscription.limits.events.maxPurchased) * 100"
						color="blue darken-1"
						height="25"
					>
						<template #default>
							<strong>{{ `${subscription.limits.events.num}/${subscription.limits.events.maxPurchased}` }}</strong>
						</template>
					</v-progress-linear>
					<br />
					<v-subheader>
						{{
							$t('companies.adminsInUse', {
								currentNumber: subscription.limits.admins.num,
								maxNumber: subscription.limits.admins.maxPurchased
							})
						}}
					</v-subheader>
					<v-progress-linear
						:value="(subscription.limits.admins.num / subscription.limits.admins.maxPurchased) * 100"
						color="blue darken-1"
						height="25"
					>
						<template #default>
							<strong>{{ `${subscription.limits.admins.num}/${subscription.limits.admins.maxPurchased}` }}</strong>
						</template>
					</v-progress-linear>
					<div class="text-center mt-5">
						<v-chip :color="subscription.limits.pool ? 'success darken-3' : 'red darken-3'" class="my-2">
							{{ $t('profile.brandTalentPool') }}: {{ subscription.limits.pool ? $t('offers.active') : $t('offers.inactive') }}
						</v-chip>
					</div>
					<br />
				</div>
				<p class="text-h5">{{ $t('subscriptions.payments') }}</p>
				<p class="text-body-1" v-if="subscription.period == 'yearly'">
					{{ $t('subscriptions.billedEachYear') }}
				</p>
				<p class="text-body-1" v-else-if="subscription.period == 'monthly'">
					{{ $t('subscriptions.billedEachMonth') }}
				</p>
				<div class="mx-auto">
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-right">{{ $t('offers.date') }}</th>
									<th class="text-center">{{ $t('subscriptions.price') }}</th>
									<th class="text-center">{{ $t('subscriptions.paid') }}</th>
									<th class="text-center">{{ $t('invoices.invoice') }}</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="payment in subscription.payments" :key="`payment${payment.id}`">
									<td class="text-right">{{ humanTime(payment.timestamp.due) }}</td>
									<td class="text-center">{{ formatPrice(payment.price) }}</td>
									<td class="text-center">
										<v-icon :color="payment.status.paid.value ? 'green' : 'red'">
											{{ payment.status.paid.value ? 'mdi-check-circle-outline' : 'mdi-close-circle' }}
										</v-icon>
										<span v-if="payment.status.paid.value">{{ humanTime(payment.timestamp.paid) }}</span>
										<span v-else-if="payment.status.pending.value">{{ $t('subscriptions.pending') }}</span>
										<span v-else>{{ $t('offers.canceled') }}</span>
									</td>
									<td class="text-center">
										<span v-if="payment.status.invoice.value" class="green--text">{{ $t('invoices.available') }}</span>
										<span v-else class="red--text">{{ $t('courses.notCurrentlyAvailable') }}</span>
									</td>
									<v-menu bottom right offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn dark icon v-bind="attrs" v-on="on">
												<v-icon>mdi-dots-vertical</v-icon>
											</v-btn>
										</template>

										<v-list>
											<v-list-item @click="callSendPaymentInstructions(payment.id)" v-if="!payment.status.paid.value">
												<v-list-item-title>{{ $t('subscriptions.sendInstructions') }}</v-list-item-title>
											</v-list-item>
											<v-list-item @click="callCancelPayment(payment.id)" v-if="!payment.status.canceled.value">
												<v-list-item-title>{{ $t('subscriptions.cancelPayment') }}</v-list-item-title>
											</v-list-item>
											<v-list-item @click="callPendingPayment(payment.id)" v-if="!payment.status.pending.value">
												<v-list-item-title>{{ $t('subscriptions.markAsPending') }}</v-list-item-title>
											</v-list-item>
											<v-list-item @click="callPaidPayment(payment.id)" v-if="!payment.status.paid.value">
												<v-list-item-title>{{ $t('subscriptions.markAsPaid') }}</v-list-item-title>
											</v-list-item>
											<v-list-item @click="callDownloadInvoice(payment.id)" :disabled="!payment.status.invoice.value">
												<v-list-item-title>{{ $t('subscriptions.downloadInvoice') }}</v-list-item-title>
											</v-list-item>
											<v-list-item @click="callDeleteInvoice(payment.id)" v-if="payment.status.invoice.value">
												<v-list-item-title>{{ $t('subscriptions.deleteInvoice') }}</v-list-item-title>
											</v-list-item>
											<SubscriptionInvoiceUpload :loading-parent="loading" :payment="payment" v-if="!payment.status.invoice.value" />
										</v-list>
									</v-menu>
								</tr>
								<template v-if="!subscription.status.canceled.value">
									<v-subheader>{{ $t('subscriptions.nextPayment') }}:</v-subheader>
									<tr>
										<td class="text-right">{{ humanTime(subscription.next) }}</td>
										<td class="text-center">{{ formatPrice(subscription.price.value) }}</td>
										<td />
										<td />
									</tr>
								</template>
							</tbody>
						</template>
					</v-simple-table>
				</div>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('subscriptions.subscription')
		}
	},
	data() {
		return {
			loading: false
		}
	},
	components: {
		SubscriptionInvoiceUpload: () => import('@/components/subscriptions/SubscriptionInvoiceUpload.vue')
	},
	watch: {
		'$route.params.id'(id) {
			this.loading = true
			this.fetchSubscription(id).then(() => {
				this.loading = false
			})
		}
	},
	computed: {
		...mapGetters({
			subscription: 'subscriptions/subscription'
		})
	},
	created() {
		this.loading = true
		this.fetchSubscription(this.$route.params.id).then(() => {
			this.loading = false
		})
	},
	methods: {
		callSendPaymentInstructions(paymentID) {
			this.sendPaymentInstructions(paymentID)
		},
		callFinishSubscription(subscriptionID) {
			this.finishSubscription(subscriptionID)
		},
		callActivateSubscription(subscriptionID) {
			this.activateSubscription(subscriptionID)
		},
		callCancelPayment(paymentID) {
			this.cancelPayment(paymentID)
		},
		callPendingPayment(paymentID) {
			this.pendingPayment(paymentID)
		},
		callPaidPayment(paymentID) {
			this.paidPayment(paymentID)
		},
		callCancelSubscription(subscriptionID) {
			this.cancelSubscription(subscriptionID)
		},
		callDownloadInvoice(paymentID) {
			this.downloadInvoice(paymentID)
		},
		callDeleteInvoice(paymentID) {
			this.deleteInvoice(paymentID)
		},
		humanTime(timestamp) {
			return this.$moment.utc(timestamp).format('ddd, DD MMM YYYY')
		},
		formatPrice(price) {
			return Number((Math.round(parseFloat(price) * 100) / 100).toFixed(2)).toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })
		},
		...mapActions('subscriptions', [
			'cancelSubscription',
			'finishSubscription',
			'activateSubscription',
			'fetchSubscription',
			'cancelPayment',
			'pendingPayment',
			'paidPayment',
			'downloadInvoice',
			'deleteInvoice',
			'sendPaymentInstructions'
		])
	}
}
</script>
