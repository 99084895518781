export const namespaced = true

export const state = () => ({})

export const getters = {}

export const actions = {
	sendEmails({ dispatch }, { emailType }) {
		return this.$api
			.get(`/emails/${emailType}`)
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	sendNewsletter({ dispatch }, { newsletterID }) {
		return this.$api
			.get(`/emails/newsletter/${newsletterID}`)
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {}
