export const namespaced = true

export const state = () => ({ domains: [], domain: null })

export const getters = {
	domains: (state) => state.domains,
	domain: (state) => state.domain
}

export const actions = {
	fetchDomains({ commit, dispatch }) {
		commit('SET_DOMAINS', [])
		return this.$api
			.get('/domains')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_DOMAINS', data.domains)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchDomain({ commit, dispatch }, domainID) {
		commit('SET_DOMAIN', null)
		return this.$api
			.get(`/domains/${domainID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_DOMAIN', data.domain)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	addDomain({ dispatch }, args) {
		return this.$api
			.put('/domains/add', {
				name: args.name,
				description: args.description,
				studentOnly: args.studentOnly
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editDomain({ dispatch }, args) {
		return this.$api
			.post(`/domains/${args.domainID}/edit/name`, {
				name: args.name,
				description: args.description,
				studentOnly: args.studentOnly
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_DOMAINS: (state, domains) => (state.domains = domains),
	SET_DOMAIN: (state, domain) => (state.domain = domain)
}
