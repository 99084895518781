export const namespaced = true

export const state = () => ({
	user: null,
	users: [],
	talentPool: []
})

export const getters = {
	user: (state) => state.user,
	users: (state) => state.users,
	talentPool: (state) => state.talentPool
}

export const actions = {
	fetchUsers({ commit, dispatch }, { page, perPage, search, verified }) {
		commit('SET_USERS', [])
		return this.$api
			.get('/users', {
				params: {
					page,
					perPage,
					search,
					verified
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_USERS', data.users)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchUser({ commit, dispatch }, userID) {
		commit('SET_USER', null)
		return this.$api
			.get(`/users/${userID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_USER', data.user)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchStats({ dispatch }, stats_type) {
		return this.$api
			.get(`/users/stats/${stats_type}`)
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateUserPicture({ commit, dispatch }, { userID, formData }) {
		return this.$api
			.post(`/users/${userID}/picture`, formData)
			.then(({ data }) => {
				if (data.success) {
					commit('users/UPDATE_IMG_URL', data.imgURL, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	deleteUserPicture({ commit, dispatch }, userID) {
		return this.$api
			.delete(`/users/${userID}/picture`)
			.then(({ data }) => {
				if (data.success) {
					commit('users/UPDATE_IMG_URL', data.imgURL, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateUserInfo({ commit, dispatch }, { userID, name }) {
		return this.$api
			.post(`/users/${userID}/info`, {
				name
			})
			.then(({ data }) => {
				if (data.success) {
					commit('users/SET_USER', data.user, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateUserDescription({ commit, dispatch }, { userID, description }) {
		return this.$api
			.post(`/users/${userID}/description`, {
				description
			})
			.then(({ data }) => {
				if (data.success) {
					commit('users/SET_USER', data.user, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateUserInterests({ commit, dispatch }, { userID, lookingFor }) {
		return this.$api
			.post(`/users/${userID}/interests`, {
				lookingFor
			})
			.then(({ data }) => {
				if (data.success) {
					commit('users/SET_USER', data.user, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchTalentPool({ commit, dispatch }, { offerID, page, itemsPerPage, search, skills, languages, sectors, degrees, cv, records, motivation }) {
		commit('SET_TALENT_POOL', [])
		return this.$api
			.get(`/users/pool`, {
				params: {
					offerID,
					page,
					itemsPerPage,
					search,
					skills,
					languages,
					sectors,
					degrees,
					cv,
					records,
					motivation
				}
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_TALENT_POOL', data.users)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	includeUserInOffer({ dispatch }, { userID, offerID }) {
		return this.$api
			.post(`/users/${userID}/includeInOffer/${offerID}`)
			.then(({ data }) => {
				if (data.success) {
					dispatch('applications/fetchApplications', offerID, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_USER: (state, user) => (state.user = user),
	SET_USERS: (state, users) => (state.users = users),
	SET_TALENT_POOL: (state, talentPool) => (state.talentPool = talentPool),
	UPDATE_LOCALE: (state, locale) => (state.user.locale = locale),
	UPDATE_IMG_URL: (state, imgURL) => (state.user.imgURL = imgURL)
}
