<template>
	<v-container fluid style="max-width: 1200px">
		<h2 class="text-center text-h3 mb-5">
			{{ $t('offers.createOffer') }}
		</h2>

		<div v-if="!category">
			<h5 class="text-center text-h5 mb-2">
				{{ $t('offers.chooseCategory') }}
			</h5>
			<div class="d-flex justify-space-around flex-wrap">
				<v-card flat color="blue darken-1" class="my-1 mx-1" max-width="350" @click="category = '1'">
					<v-card-title>
						<span class="mx-auto">{{ $t('offers.internal') }}</span>
					</v-card-title>
					<v-card-subtitle class="text-center">
						{{ $t('offers.forInternalManagement') }}
					</v-card-subtitle>
					<v-card-text class="text-justify">
						{{ $t('offers.InternalManagementExplanation') }}
					</v-card-text>
				</v-card>
				<v-card flat color="teal darken-1" class="my-1 mx-1" max-width="350" @click="category = '2'">
					<v-card-title>
						<span class="mx-auto">{{ $t('offers.external') }}</span>
					</v-card-title>
					<v-card-subtitle class="text-center">
						{{ $t('offers.forExternalManagement') }}
					</v-card-subtitle>
					<v-card-text class="text-justify">
						{{ $t('offers.ExternalManagementExplanation') }}
					</v-card-text>
				</v-card>
				<v-card flat color="purple darken-1" class="my-1 mx-1" max-width="350" @click="category = '3'">
					<v-card-title>
						<span class="mx-auto">{{ $t('offers.justExternal') }}</span>
					</v-card-title>
					<v-card-subtitle class="text-center">
						{{ $t('offers.forJustExternalManagement') }}
					</v-card-subtitle>
					<v-card-text class="text-justify">
						{{ $t('offers.justExternalManagementExplanation') }}
					</v-card-text>
				</v-card>
			</div>
		</div>

		<v-stepper v-if="category" v-model="stepperIndex" alt-labels>
			<v-stepper-header>
				<v-stepper-step :complete="stepperIndex > 1" step="1" :rules="[() => (formAboutSubmitted ? validAbout : true)]">
					{{ $t('offers.aboutTheOffer') }}
				</v-stepper-step>

				<v-divider />

				<v-stepper-step :complete="stepperIndex > 2" step="2" :rules="[() => (formBenefitsSubmitted ? validBenefits : true)]">
					{{ $t('offers.benefits') }}
				</v-stepper-step>

				<v-divider />

				<v-stepper-step :complete="stepperIndex > 3" step="3" :rules="[() => (formNeedsSubmitted ? validNeeds : true)]">
					{{ $t('offers.requisites') }}
				</v-stepper-step>

				<v-divider />

				<v-stepper-step step="4">
					{{ $t('auth.verify') }}
				</v-stepper-step>
			</v-stepper-header>

			<v-stepper-items>
				<v-stepper-content step="1">
					<v-card flat color="transparent">
						<v-card-text>
							<v-form ref="formAbout" v-model="validAbout">
								<v-text-field
									v-if="['2', '3'].includes(category)"
									v-model="externalLink"
									:label="$t('offers.externalLink')"
									:hint="$t('offers.externalLinkHelp')"
									:counter="externalLinkMaxLength"
									:rules="rules.externalLink"
									prepend-icon="mdi-earth"
								/>
								<v-text-field
									v-model="title"
									:label="$t('newsfeed.title')"
									:counter="titleMaxLength"
									:rules="rules.title"
									prepend-icon="mdi-format-title"
								/>
								<v-row>
									<v-col>
										<v-select
											v-model="offerType"
											:label="$t('offers.offerType')"
											:items="availableOfferTypes"
											item-text="name"
											item-value="key"
											:rules="rules.offerType"
											return-object
										/>
									</v-col>
									<v-col>
										<v-select
											v-model="fulltime"
											:label="$t('offers.fullParttime')"
											:items="fulltimeOptions"
											item-text="name"
											item-value="key"
											:rules="rules.fulltime"
											return-object
										/>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" lg="6">
										<v-text-field
											v-model="startingDate"
											:label="$t('offers.start')"
											:hint="$t('offers.startHelp')"
											persistent-hint
											:counter="startingDateMaxLength"
											:rules="rules.startingDate"
										/>
									</v-col>
									<v-col cols="12" lg="6">
										<v-text-field
											v-model="duration"
											:label="$t('offers.duration')"
											:hint="$t('offers.durationHelp')"
											persistent-hint
											:counter="durationMaxLength"
											:rules="rules.duration"
										/>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-text-field v-model="location.city" :label="$t('invoices.city')" :counter="cityMaxLength" :rules="rules.location.city" />
									</v-col>
									<v-col>
										<v-autocomplete
											v-model="location.province"
											:label="$t('invoices.province')"
											:items="availableSpainProvinces"
											:rules="rules.location.province"
											outlined
										/>
									</v-col>
									<v-col>
										<v-autocomplete
											v-model="location.country"
											:label="$t('invoices.country')"
											:items="availableCountries"
											item-text="name"
											item-value="key"
											:rules="rules.location.country"
											return-object
										/>
									</v-col>
								</v-row>
								<v-textarea
									v-model="description"
									:label="$t('courses.description')"
									:counter="descriptionMaxLength"
									:rules="rules.description"
									outlined
									auto-grow
								/>
							</v-form>
						</v-card-text>

						<v-card-actions>
							<v-btn color="primary" @click="validateAbout()">
								{{ $t('search.continue') }}
							</v-btn>

							<v-btn text :to="{ name: 'Offers' }">
								{{ $t('offers.cancel') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-stepper-content>

				<v-stepper-content step="2">
					<v-card flat color="transparent">
						<v-card-text>
							<v-form ref="formBenefits" v-model="validBenefits">
								<v-select
									v-model="thesisOptions"
									:label="$t('offers.thesisOptions')"
									:items="availableThesisOptions"
									item-text="name"
									item-value="key"
									:rules="rules.thesisOptions"
									chips
									multiple
									return-object
								>
									<template v-slot:selection="data">
										<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
											{{ data.item.name }}
										</v-chip>
									</template>
								</v-select>
								<v-text-field
									v-model="salary"
									:label="$t('offers.salary')"
									:hint="$t('offers.salaryHelp')"
									persistent-hint
									:counter="salaryMaxLength"
									:rules="rules.salary"
									prepend-icon="mdi-cash-multiple"
								/>
								<v-textarea
									class="mt-5"
									v-model="benefits"
									:label="$t('offers.benefits')"
									:counter="benefitsMaxLength"
									:rules="rules.benefits"
									outlined
									auto-grow
								/>
							</v-form>
						</v-card-text>

						<v-card-actions>
							<v-btn color="primary" @click="validateBenefits()">
								{{ $t('search.continue') }}
							</v-btn>

							<v-btn text @click="stepperIndex--">
								{{ $t('search.back') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-stepper-content>

				<v-stepper-content step="3">
					<v-card flat color="transparent">
						<v-card-text>
							<v-form ref="formNecessities" v-model="validNeeds">
								<v-autocomplete
									v-model="sectors"
									:items="availableSectors"
									:loading="loading"
									:search-input.sync="search"
									:filter="customFilter"
									hide-selected
									item-text="name"
									item-value="id"
									:label="$t('companies.sectors')"
									:placeholder="`${$t('search.search')}...`"
									:rules="rules.sectors"
									return-object
									chips
									multiple
									outlined
									deletable-chips
								>
									<template #no-data>
										<v-list-item v-if="search == null">
											<v-list-item-title>
												<strong>{{ $t('search.search') }}</strong>
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-else>
											<v-list-item-title>
												{{ $t('search.noResults') }}
											</v-list-item-title>
										</v-list-item>
									</template>
								</v-autocomplete>
								<v-textarea
									v-model="qualifications"
									:label="$t('offers.qualifications')"
									:counter="qualificationsMaxLength"
									:rules="rules.qualifications"
									outlined
									auto-grow
								/>
							</v-form>
						</v-card-text>

						<v-card-actions>
							<v-btn color="primary" @click="validateNecessities()">
								{{ $t('search.continue') }}
							</v-btn>

							<v-btn text @click="stepperIndex--">
								{{ $t('search.back') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-stepper-content>

				<v-stepper-content step="4">
					<v-card flat color="transparent">
						<v-card-text>
							<h5>{{ $t('offers.importantInformation') }}</h5>
							<p>{{ $t('offers.importantInformation1') }}</p>
							<p>{{ $t('offers.importantInformation2') }}</p>
							<p>{{ $t('offers.importantInformation3') }}</p>

							<v-row>
								<v-col cols="12" lg="6" sm="12">
									<h5>{{ $t('offers.otherCompanyNameTitle') }}</h5>
									<p>{{ $t('offers.otherCompanyNameText') }}</p>
									<v-form ref="formSupplementary" v-model="validSupplementary">
										<v-text-field
											v-model="companyName"
											:label="$t('auth.name')"
											:counter="nameMaxLength"
											:rules="rules.name"
											:placeholder="company.name"
											prepend-icon="mdi-format-title"
										/>
									</v-form>
								</v-col>

								<v-col cols="12" lg="6" sm="12">
									<h5>{{ $t('offers.otherLogoTitle') }}</h5>
									<p>{{ $t('offers.otherLogoText') }}</p>
									<v-form ref="formLogo" v-model="validLogo">
										<v-file-input v-model="logo" :label="$t('companies.logo')" @change="onLogoUpload" />
									</v-form>
									<div v-if="logoCompressed && logoUrl" class="text-center">
										<h3>{{ $t('explorer.readyToUpload') }}</h3>
										<p>{{ logo.name }}</p>
										<v-avatar size="160">
											<v-img :src="logoUrl" />
										</v-avatar>
									</div>
									<div v-else>
										<v-avatar size="160">
											<v-img :src="company.imgURL" />
										</v-avatar>
									</div>
								</v-col>
							</v-row>

							<v-row v-if="validNeeds">
								<v-col cols="12" lg="4">
									<v-card color="transparent">
										<v-card-title>
											<span>{{ $t('offers.aboutTheOffer') }}</span>
										</v-card-title>
										<v-card-text>
											<h4 v-if="['2', '3'].includes(category)"><v-icon>mdi-earth</v-icon> {{ externalLink }}</h4>
											<h3 class="text-uppercase">{{ title }}</h3>
											<p>{{ offerType.name }} - {{ fulltime.name }}</p>
											<p><v-icon>mdi-calendar</v-icon> {{ startingDate }}</p>
											<p><v-icon>mdi-clock-outline</v-icon> {{ duration }}</p>
											<p>
												<v-icon>mdi-map-marker</v-icon> {{ location.city }}
												<template v-if="location.country.key == 'ES'"> - {{ location.province }}</template>
												({{ location.country.name }})
											</p>
											<p style="white-space: pre-line">{{ description }}</p>
										</v-card-text>
									</v-card>
								</v-col>
								<v-col cols="12" lg="4">
									<v-card color="transparent">
										<v-card-title>
											<span>{{ $t('offers.benefits') }}</span>
										</v-card-title>
										<v-card-text>
											<p><v-icon>mdi-currency-eur</v-icon> {{ salary }}</p>
											<v-row>
												<v-col>
													<span>{{ $t('offers.thesisOptions') }}</span>
													<v-chip :ripple="false" v-for="thesisOption in thesisOptions" :key="thesisOption.key" class="mx-1">
														{{ thesisOption.name }}
													</v-chip>
												</v-col>
											</v-row>
											<p style="white-space: pre-line">{{ benefits }}</p>
										</v-card-text>
									</v-card>
								</v-col>
								<v-col cols="12" lg="4">
									<v-card color="transparent">
										<v-card-title>
											<span>{{ $t('offers.requisites') }}</span>
										</v-card-title>
										<v-card-text>
											<v-row>
												<v-col>
													<v-chip :ripple="false" v-for="sector in sectors" :key="`sector${sector.id}`" class="mx-1">
														{{ sector.name }}
													</v-chip>
												</v-col>
											</v-row>
											<p style="white-space: pre-line">{{ qualifications }}</p>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
						</v-card-text>

						<v-card-actions>
							<v-btn color="primary" @click="callCreateOffer()">
								{{ $t('offers.create') }}
							</v-btn>

							<v-btn text @click="stepperIndex--">
								{{ $t('search.back') }}
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Compressor from 'compressorjs'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function(letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function(match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	data() {
		return {
			loading: false,
			formAboutSubmitted: false,
			formBenefitsSubmitted: false,
			formNeedsSubmitted: false,
			formSupplementary: false,
			validAbout: false,
			validBenefits: false,
			validNeeds: false,
			validLogo: false,
			validSupplementary: false,
			stepperIndex: 1,
			search: null,
			category: '',
			externalLink: '',
			title: '',
			location: {
				city: '',
				country: null
			},
			description: '',
			startingDate: '',
			duration: '',
			offerType: null,
			fulltime: null,
			fulltimeOptions: [
				{ key: 0, name: this.$t('offers.parttime') },
				{ key: 1, name: this.$t('offers.fulltime') }
			],
			benefits: '',
			salary: '',
			thesisOptions: [],
			qualifications: '',
			sectors: [],
			logo: null,
			companyName: '',
			logoCompressed: null,
			logoUrl: null,
			rules: {
				externalLink: [
					(v) => {
						if (['2', '3'].includes(this.category)) return !!v || this.$t('rules.required')
						else return true
					},
					(v) => {
						if (['2', '3'].includes(this.category))
							return (v && v.length <= this.externalLinkMaxLength) || this.$t('rules.length', { length: this.externalLinkMaxLength })
						else return true
					}
				],
				title: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.titleMaxLength) || this.$t('rules.length', { length: this.titleMaxLength })
				],
				location: {
					city: [
						(v) => !!v || this.$t('rules.required'),
						(v) => (v && v.length <= this.cityMaxLength) || this.$t('rules.length', { length: this.cityMaxLength })
					],
					province: [
						(v) => !!v || this.$t('rules.required'),
						(v) => (v && this.availableSpainProvinces.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported')
					],
					country: [
						(v) => !!v || this.$t('rules.required'),
						(v) => (v && this.availableCountries.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported')
					]
				},
				startingDate: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.startingDateMaxLength) || this.$t('rules.length', { length: this.startingDateMaxLength })
				],
				duration: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.durationMaxLength) || this.$t('rules.length', { length: this.durationMaxLength })
				],
				description: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.descriptionMaxLength) || this.$t('rules.length', { length: this.descriptionMaxLength })
				],
				offerType: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && this.availableOfferTypes.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported')
				],
				fulltime: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && this.fulltimeOptions.map(({ key }) => key).includes(v.key)) || this.$t('rules.notSupported')
				],
				benefits: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.benefitsMaxLength) || this.$t('rules.length', { length: this.benefitsMaxLength })
				],
				salary: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.salaryMaxLength) || this.$t('rules.length', { length: this.salaryMaxLength })
				],
				thesisOptions: [
					(v) => !!v.length || this.$t('rules.required'),
					(v) => (v && this.allItemsInArray(v, this.availableThesisOptions)) || this.$t('rules.notSupportedAtleast')
				],
				qualifications: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.qualificationsMaxLength) || this.$t('rules.length', { length: this.qualificationsMaxLength })
				],
				sectors: [
					(v) => !!v.length || this.$t('rules.required'),
					(v) => (v && this.allItemsInArray(v, this.availableSectors)) || this.$t('rules.notSupportedAtleast')
				],
				name: [(v) => 0 < v.length <= this.nameMaxLength || this.$t('rules.length', { length: this.nameMaxLength })]
			}
		}
	},
	computed: {
		externalLinkMaxLength() {
			return 1024
		},
		titleMaxLength() {
			return 255
		},
		startingDateMaxLength() {
			return 64
		},
		durationMaxLength() {
			return 64
		},
		cityMaxLength() {
			return 128
		},
		descriptionMaxLength() {
			return 1024 * 4
		},
		benefitsMaxLength() {
			return 1024 * 2
		},
		salaryMaxLength() {
			return 64
		},
		qualificationsMaxLength() {
			return 1024 * 2
		},
		nameMaxLength() {
			return 128
		},
		...mapGetters({
			offer: 'offers/offer',
			company: 'companies/company',
			availableSectors: 'companies/availableSectors',
			availableOfferTypes: 'data/availableOfferTypes',
			availableCountries: 'data/availableCountries',
			availableThesisOptions: 'data/availableThesisOptions',
			availableSpainProvinces: 'data/availableSpainProvinces'
		})
	},
	watch: {
		search(val) {
			if (val) this.queryAvailableSectors(val)
		}
	},
	created() {
		this.fetchAvailableOfferTypes()
		this.fetchAvailableCountries()
		this.fetchAvailableThesisOptions()
		this.queryAvailableSectors('')
		this.fetchAvailableSpainProvinces()
		if (this.$route.query.reuse && this.$route.query.reuse == true && this.offer) {
			this.title = this.offer.title
			this.location = this.offer.info.location
			this.description = this.offer.info.description
			this.qualifications = this.offer.info.qualifications
			this.benefits = this.offer.info.benefits
			this.startingDate = this.offer.info.startingDate
			this.duration = this.offer.info.duration
			this.salary = this.offer.info.salary
			this.offerType = this.offer.info.type
			this.fulltime = this.offer.info.time
			this.thesisOptions = this.offer.info.thesisOptions
			this.sectors = this.offer.info.sectors
		}
		this.loading = false
	},
	methods: {
		allItemsInArray(items, array) {
			for (let index = 0; index < items.length; index++) {
				if (!array.map((option) => option.key).includes(items[index].key)) return false
			}
			return true
		},
		validateAbout() {
			this.$refs.formAbout.validate()
			this.formAboutSubmitted = true
			if (this.validAbout) {
				this.stepperIndex++
			}
			window.scrollTo(0, 0)
		},
		validateBenefits() {
			this.$refs.formBenefits.validate()
			this.formBenefitsSubmitted = true
			if (this.validBenefits) {
				this.stepperIndex++
			}
			window.scrollTo(0, 0)
		},
		validateNecessities() {
			this.$refs.formNecessities.validate()
			this.formNeedsSubmitted = true
			if (this.validNeeds) {
				this.stepperIndex++
			}
			window.scrollTo(0, 0)
		},
		queryAvailableSectors(search) {
			this.loading = true
			this.fetchAvailableSectors({ keywords: search }).then(() => {
				this.loading = false
			})
		},
		customFilter(item, queryText) {
			const text = removeAccents(item.name.toLowerCase())
			const searchText = removeAccents(queryText.toLowerCase())
			return text.includes(searchText)
		},
		onLogoUpload() {
			new Promise((resolve, reject) => {
				new Compressor(this.logo, {
					quality: 0.9,
					success: resolve,
					error: reject
				})
			})
				.then((result) => {
					this.logoCompressed = result
					this.logoUrl = URL.createObjectURL(this.logoCompressed)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		callUpdateLogo() {
			this.$refs.formLogo.validate()
			this.$refs.formAbout.validate()
			this.$refs.formNecessities.validate()
			this.$refs.formSupplementary.validate()
			this.$refs.formLogo.validate()
			if (!this.validAbout || !this.validBenefits || !this.validNeeds || !this.validSupplementary || !this.validLogo) return
			this.loading = true
			const formData = new FormData()
			formData.append('logo', this.logoCompressed, this.logoCompressed.name)
			return this.updateOfferLogo({ offerID: this.offer.id, formData }).then(() => {
				this.logo = null
				this.logoCompressed = null
				this.logoUrl = null
			})
		},
		callCreateOffer() {
			this.$refs.formAbout.validate()
			this.$refs.formNecessities.validate()
			this.$refs.formNecessities.validate()
			this.$refs.formSupplementary.validate()
			this.formAboutSubmitted = true
			this.formBenefitsSubmitted = true
			this.formNeedsSubmitted = true
			if (!this.validAbout || !this.validBenefits || !this.validNeeds || !this.validSupplementary) return
			this.loading = true
			this.createOffer({
				companyID: this.company.id,
				category: this.category,
				externalLink: this.externalLink,
				title: this.title,
				companyName: this.companyName,
				city: this.location.city,
				province: this.location.province,
				country: this.location.country,
				description: this.description,
				qualifications: this.qualifications,
				benefits: this.benefits,
				startingDate: this.startingDate,
				duration: this.duration,
				salary: this.salary,
				offerType: this.offerType,
				fulltime: this.fulltime.key,
				thesisOptions: this.thesisOptions,
				sectors: this.sectors
			}).then(({ success, offer }) => {
				if (success && this.logoCompressed) {
					this.callUpdateLogo().then(() => {
						this.loading = false
						this.$router.push({ name: 'Offer', params: { id: offer.id } })
					})
				} else if (success) {
					this.$router.push({ name: 'Offer', params: { id: offer.id } })
				} else {
					this.loading = false
				}
			})
		},
		...mapActions('offers', ['createOffer', 'updateOfferLogo']),
		...mapActions('cv', ['fetchAvailableSectors']),
		...mapActions('data', ['fetchAvailableOfferTypes', 'fetchAvailableCountries', 'fetchAvailableThesisOptions', 'fetchAvailableSpainProvinces'])
	}
}
</script>

<style scoped></style>
