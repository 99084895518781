export const namespaced = true

export const state = () => ({
	loading: false,
	event: null,
	events: [],
	eventTypes: []
})

export const getters = {
	loading: (state) => state.loading,
	event: (state) => state.event,
	events: (state) => state.events,
	eventTypes: (state) => state.eventTypes
}

export const actions = {
	setLoadingEvent({ commit }, loading) {
		commit('SET_LOADING', loading)
	},
	fetchEvent({ commit, dispatch }, eventID) {
		commit('SET_EVENT', null)
		return this.$api
			.get(`/events/${eventID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENT', data.event)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchEvents({ commit, dispatch }) {
		commit('SET_EVENTS', [])
		return this.$api
			.get(`/events`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENTS', data.events)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchEventsCompany({ commit, dispatch }, { companyID }) {
		commit('SET_EVENTS', [])
		return this.$api
			.get(`/events/company/${companyID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENTS', data.events)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchEventTypes({ commit, dispatch }) {
		commit('SET_EVENT_TYPES', [])
		return this.$api
			.get('/events/types')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENT_TYPES', data.eventTypes)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	createEvent({ commit, dispatch }, event) {
		return this.$api
			.put(`/events/create/${event.companyID}`, {
				typeID: event.type ? event.type.id : 0,
				title: event.title,
				companyName: event.companyName,
				location: event.location,
				website: event.website,
				description: event.description,
				startDate: event.startDate,
				endDate: event.endDate,
				startTime: event.startTime,
				endTime: event.endTime,
				capacity: event.capacity
			})
			.then(({ data }) => {
				if (data.success) {
					commit('CREATE_EVENT', data.event)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editEvent({ commit, dispatch }, event) {
		return this.$api
			.post(`/events/${event.id}/edit`, {
				typeID: event.type ? event.type.id : 0,
				title: event.title,
				location: event.location,
				website: event.website,
				description: event.description,
				startDate: event.startDate,
				endDate: event.endDate,
				startTime: event.startTime,
				endTime: event.endTime,
				capacity: event.capacity
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENT', data.event)
				}
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('dialog/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	updateEventLogo({ commit, dispatch }, { eventID, formData }) {
		return this.$api
			.post(`/events/${eventID}/logo`, formData)
			.then(({ data }) => {
				if (data.success) {
					commit('events/UPDATE_IMG_URL', data.imgURL, { root: true })
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	deleteEventLogo({ commit, dispatch }, eventID) {
		return this.$api
			.delete(`/events/${eventID}/logo`)
			.then(({ data }) => {
				if (data.success) {
					commit('events/UPDATE_IMG_URL', data.imgURL, { root: true })
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	cancelChange({ commit, dispatch }, eventsChangeID) {
		return this.$api
			.post(`/events/${eventsChangeID}/cancel_edit`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENT', data.event)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	finishEvent({ commit, dispatch }, eventID) {
		return this.$api
			.post(`/events/${eventID}/finish`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENT', data.event)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	cancelEvent({ commit, dispatch }, eventID) {
		return this.$api
			.post(`/events/${eventID}/cancel`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENT', data.event)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	closeEvent({ commit, dispatch }, eventID) {
		return this.$api
			.post(`/events/${eventID}/close`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENT', data.event)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	openEvent({ commit, dispatch }, eventID) {
		return this.$api
			.post(`/events/${eventID}/open`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENT', data.event)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	approveChange({ commit, dispatch }, { changeID, alertAttendances }) {
		return this.$api
			.post(`/events/${changeID}/approve`, {
				alert: alertAttendances
			})
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENT', data.event)
				}
				dispatch('dialog/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	refuseChange({ commit, dispatch }, changeID) {
		return this.$api
			.post(`/events/${changeID}/refuse`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENT', data.event)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	verifyEvent({ commit, dispatch }, eventID) {
		return this.$api
			.post(`/events/${eventID}/verify`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENT', data.event)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	activateEvent({ commit, dispatch }, eventID) {
		return this.$api
			.post(`/events/${eventID}/activate`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_EVENT', data.event)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_LOADING: (state, loading) => (state.loading = loading),
	SET_EVENT: (state, event) => (state.event = event),
	SET_EVENTS: (state, events) => (state.events = events),
	SET_EVENT_TYPES: (state, eventTypes) => (state.eventTypes = eventTypes),
	CREATE_EVENT: (state, event) => {
		state.event = event
		state.events.unshift(event)
	},
	UPDATE_IMG_URL: (state, imgURL) => (state.event.imgURL = imgURL)
}
