<template>
	<v-card max-width="800" class="mx-auto" rounded="xl">
		<v-card-title>
			<span class="headline">{{ $t('universities.university') }}</span>
		</v-card-title>
		<v-card-text>
			<v-form ref="form" v-model="valid">
				<v-text-field v-model="name" :label="$t('auth.name')" :counter="nameMaxLength" :rules="rules.name" outlined />
				<v-text-field v-model="acronym" :label="$t('courses.acronym')" :counter="acronymMaxLength" :rules="rules.acronym" outlined />
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-spacer />
			<v-btn color="blue darken-1" text @click="createNew()">{{ $t('settings.add') }}</v-btn>
			<v-btn color="blue darken-1" text @click="createNewAndAnother()">{{ $t('settings.addAndAnother') }}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UniversityAddForm',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true,
			valid: false,
			name: '',
			acronym: '',
			rules: {
				name: [
					(v) => !!v || 'Name is required.',
					(v) => (v && v.length <= this.nameMaxLength) || `Name must be less than ${this.nameMaxLength} characters`
				],
				acronym: [
					(v) => !!v || 'Acronym is required.',
					(v) => (v && v.length <= this.acronymMaxLength) || `Acronym must be less than ${this.acronymMaxLength} characters`
				]
			}
		}
	},
	computed: {
		nameMaxLength() {
			return 128
		},
		acronymMaxLength() {
			return 32
		},
		...mapGetters({
			university: 'universities/university'
		})
	},
	created() {
		this.loading = false
	},
	methods: {
		createNew() {
			this.$refs.form.validate()
			if (!this.valid) return
			return this.addUniversity({
				name: this.name,
				acronym: this.acronym
			}).then(({ data }) => {
				if (data.success && !this.remainAfterwards) {
					this.$router.push({ name: 'Studies' })
				}
				return data
			})
		},
		createNewAndAnother() {
			this.remainAfterwards = true
			this.createNew().then((data) => {
				if (data.success) {
					this.name = ''
					this.acronym = ''
					this.remainAfterwards = false
					window.scrollTo(0, 0)
				}
			})
		},
		...mapActions('universities', ['addUniversity'])
	}
}
</script>
