<template>
	<v-container fluid style="max-width: 1600px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('applications.myApplications') }}</span>
		</div>

		<v-divider class="my-2" />

		<v-row>
			<v-col cols="12" lg="8" md="12">
				<v-subheader>
					{{ $t('settings.numberOfApplications') }}: {{ applications.length }}/{{ user.limits ? user.limits.applications : '???' }}
				</v-subheader>
			</v-col>
			<v-col cols="12" lg="4" md="12">
				<v-btn color="info" rounded :to="{ name: 'Offers' }" class="float-right">
					{{ $t('offers.searchOffers') }}
				</v-btn>
			</v-col>
		</v-row>

		<div class="d-flex align-center">
			<v-icon class="mx-4">
				mdi-magnify
			</v-icon>
			<v-text-field
				v-model="title"
				:placeholder="`${$t('search.search')}: ${$t('auth.name')}, ${$t('companies.company')}, ${$t('companies.location')}`"
			/>
			<v-dialog v-model="filtersEditMode" max-width="1000px" @click:outside="filtersSwitchEditMode()">
				<template v-slot:activator="{ on }">
					<v-btn color="secondary" v-on="on" elevation="2" rounded small text>
						<v-icon class="mx-4">
							mdi-filter
						</v-icon>
						{{ $t('search.filter') }}
					</v-btn>
				</template>
				<v-card rounded="xl">
					<v-card-title>
						<span class="headline">{{ $t('search.filters') }}</span>
						<v-spacer />
						<v-btn icon color="secondary" @click="filtersSwitchEditMode()">
							<v-icon class="mx-4">
								mdi-close
							</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12" md="6" sm="12">
								<v-select
									v-model="filters.thesisOptions"
									:label="$t('offers.thesisOption')"
									:items="availableThesisOptions"
									item-text="name"
									item-value="key"
									chips
									deletable-chips
									multiple
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-0"
								/>
							</v-col>
							<v-col cols="12" md="6" sm="12">
								<v-select
									v-model="filters.offerTypes"
									:label="$t('offers.offerType')"
									:items="availableOfferTypes"
									item-text="name"
									item-value="key"
									chips
									deletable-chips
									multiple
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-0"
								/>
							</v-col>
							<v-col cols="12" md="6" sm="12">
								<v-text-field v-model="filters.location" :label="$t('companies.location')" outlined rounded hide-details="auto" class="pb-0" />
							</v-col>
							<v-col cols="12" md="6" sm="12">
								<v-select
									v-model="filters.fullParttime"
									:label="$t('offers.fullParttime')"
									:items="fullParttimeOptions"
									item-text="name"
									item-value="key"
									chips
									deletable-chips
									multiple
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-0"
								/>
							</v-col>
							<v-col cols="12" md="12" sm="12">
								<v-autocomplete
									v-model="filters.sectors"
									:label="$t('companies.sectors')"
									:items="availableSectors"
									item-text="name"
									item-value="key"
									chips
									deletable-chips
									multiple
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-0"
									clearable
								/>
							</v-col>
							<v-col cols="12" md="12" sm="12">
								<v-autocomplete
									v-model="filters.companies"
									:label="$t('companies.company')"
									:items="availableCompanies"
									item-text="name"
									item-value="name"
									chips
									deletable-chips
									multiple
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-0"
									clearable
								/>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>

		<v-fade-transition group hide-on-leave>
			<template v-if="loading">
				<v-skeleton-loader v-for="i in 5" :key="i" type="list-item-avatar-three-line" class="mx-auto mb-5 rounded-xl" max-width="1200" />
			</template>

			<template v-else-if="applications.length">
				<template v-for="application in filteredApplications">
					<MyApplicationCard :key="`application${application.id}`" :application="application" />
				</template>

				<template v-if="!filteredApplications.length">
					<h4 class="m-4 text-center" key="noResultsCriteria">
						{{ $t('search.noResultsCriteria') }}
					</h4>
				</template>
			</template>

			<template v-else>
				<div key="noItemsData" class="mx-auto">
					<div class="mt-2 mb-5 text-center">
						<v-icon size="128" role="img">
							mdi-package-variant
						</v-icon>
						<br />
						<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
					</div>
					<h4 class="m-4 text-center">
						{{ $t('applications.myApplicationsHelp') }}
					</h4>
				</div>
			</template>
		</v-fade-transition>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function(letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function(match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	metaInfo() {
		return {
			title: `${this.$t('nav.jobs')} - ${this.$t('applications.myApplications')}`
		}
	},
	data() {
		return {
			loading: false,
			filtersEditMode: false,
			title: '',
			fullParttimeOptions: [
				{ key: 0, name: this.$t('offers.parttime') },
				{ key: 1, name: this.$t('offers.fulltime') }
			],
			filters: {
				thesisOptions: [],
				offerTypes: [],
				location: '',
				fullParttime: [],
				companies: [],
				sectors: []
			}
		}
	},
	components: {
		MyApplicationCard: () => import('@/components/applications/MyApplicationCard.vue')
	},
	watch: {
		user(val) {
			if (val) {
				this.loading = true
				this.fetchApplicationsUser({ userID: val.id }).then(() => {
					this.loading = false
				})
			}
		}
	},
	computed: {
		filteredApplications() {
			return this.applications.filter(
				(application) =>
					(!this.filters.thesisOptions.length ||
						application.offer.info.thesisOptions.some((element) => this.filters.thesisOptions.map((e) => e.key).includes(element.key))) &&
					(!this.filters.offerTypes.length || this.filters.offerTypes.map((e) => e.key).includes(application.offer.info.type.key)) &&
					(!this.filters.fullParttime.length || this.filters.fullParttime.map((e) => e.key).includes(application.offer.info.time.key)) &&
					(!this.filters.companies.length ||
						this.filters.companies.map((e) => e.name).includes(application.offer.company.name) ||
						this.filters.companies.map((e) => e.name).includes(application.offer.companyName)) &&
					(!this.filters.sectors.length ||
						application.offer.info.sectors.some((element) => this.filters.sectors.map((e) => e.id).includes(element.id))) &&
					(removeAccents(application.offer.info.location.city)
						.toLowerCase()
						.includes(removeAccents(this.filters.location).toLowerCase()) ||
						removeAccents(application.offer.info.location.province)
							.toLowerCase()
							.includes(removeAccents(this.filters.location).toLowerCase()) ||
						removeAccents(application.offer.info.location.country.name)
							.toLowerCase()
							.includes(removeAccents(this.filters.location).toLowerCase())) &&
					(removeAccents(application.offer.title)
						.toLowerCase()
						.includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(application.offer.info.location.city)
							.toLowerCase()
							.includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(application.offer.info.location.province)
							.toLowerCase()
							.includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(application.offer.info.location.country.name)
							.toLowerCase()
							.includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(application.offer.company.name)
							.toLowerCase()
							.includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(application.offer.companyName)
							.toLowerCase()
							.includes(removeAccents(this.title).toLowerCase()))
			)
		},
		...mapGetters({
			user: 'users/user',
			applications: 'applications/applications',
			availableCompanies: 'companies/companies',
			availableOfferTypes: 'data/availableOfferTypes',
			availableThesisOptions: 'data/availableThesisOptions',
			availableSectors: 'cv/availableSectors'
		})
	},
	created() {
		this.fetchAvailableOfferTypes()
		this.fetchAvailableThesisOptions()
		this.fetchAvailableSectors({ keywords: '' })
		this.fetchCompaniesFilter()
		if (this.user?.id) {
			this.loading = true
			this.fetchApplicationsUser({ userID: this.user.id }).then(() => {
				this.loading = false
			})
		}
	},
	methods: {
		filtersSwitchEditMode() {
			this.filtersEditMode = !this.filtersEditMode
		},
		...mapActions('companies', ['fetchCompaniesFilter']),
		...mapActions('applications', ['fetchApplicationsUser']),
		...mapActions('cv', ['fetchAvailableSectors']),
		...mapActions('data', ['fetchAvailableOfferTypes', 'fetchAvailableThesisOptions'])
	}
}
</script>
