export const namespaced = true

export const state = () => ({
	courses: [],
	course: null
})

export const getters = {
	courses: (state) => state.courses,
	course: (state) => state.course
}

export const actions = {
	fetchCourses({ commit, dispatch }) {
		commit('SET_COURSES', [])
		return this.$api
			.get('/courses')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_COURSES', data.courses)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchCourse({ commit, dispatch }, courseID) {
		commit('SET_COURSE', null)
		commit('students/SET_STUDENTS', [], { root: true })
		return this.$api
			.get(`/courses/${courseID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_COURSE', data.course)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	addCourse({ dispatch }, course) {
		return this.$api
			.put('/courses/add', {
				nameEN: course.nameEN,
				nameES: course.nameES,
				nameCA: course.nameCA,
				nameDE: course.nameDE,
				nameFR: course.nameFR,
				shortDescriptionEN: course.shortDescriptionEN,
				shortDescriptionES: course.shortDescriptionES,
				shortDescriptionCA: course.shortDescriptionCA,
				shortDescriptionDE: course.shortDescriptionDE,
				shortDescriptionFR: course.shortDescriptionFR,
				longDescriptionEN: course.longDescriptionEN,
				longDescriptionES: course.longDescriptionES,
				longDescriptionCA: course.longDescriptionCA,
				longDescriptionDE: course.longDescriptionDE,
				longDescriptionFR: course.longDescriptionFR,
				gDriveFolderID: course.gDriveFolderID,
				gDriveID: course.gDriveID,
				facultyID: course.facultyID,
				degreeID: course.degreeID,
				semesters: course.semesters,
				mandatory: course.mandatory,
				code: course.code
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editName({ dispatch }, args) {
		return this.$api
			.post(`/courses/${args.courseID}/edit/name`, {
				nameEN: args.nameEN,
				nameES: args.nameES,
				nameCA: args.nameCA,
				nameDE: args.nameDE,
				nameFR: args.nameFR
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editAcademical({ dispatch }, { courseID, facultyID, degrees, code }) {
		return this.$api
			.post(`/courses/${courseID}/edit/academical`, {
				facultyID,
				degrees,
				code
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editGDrive({ dispatch }, args) {
		return this.$api
			.post(`/courses/${args.courseID}/edit/gDrive`, {
				gDriveFolderID: args.gDriveFolderID,
				gDriveID: args.gDriveID
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editShortDescription({ dispatch }, args) {
		return this.$api
			.post(`/courses/${args.courseID}/edit/shortDescription`, {
				shortDescriptionEN: args.shortDescriptionEN,
				shortDescriptionES: args.shortDescriptionES,
				shortDescriptionCA: args.shortDescriptionCA,
				shortDescriptionDE: args.shortDescriptionDE,
				shortDescriptionFR: args.shortDescriptionFR
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editLongDescription({ dispatch }, args) {
		return this.$api
			.post(`/courses/${args.courseID}/edit/longDescription`, {
				longDescriptionEN: args.longDescriptionEN,
				longDescriptionES: args.longDescriptionES,
				longDescriptionCA: args.longDescriptionCA,
				longDescriptionDE: args.longDescriptionDE,
				longDescriptionFR: args.longDescriptionFR
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchCoursesUser({ commit, dispatch }, { userID }) {
		commit('SET_COURSES', [])
		return this.$api
			.get(`/courses/user/${userID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_COURSES', data.courses)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	leaveCourse({ commit, dispatch }, { userID, courseID }) {
		return this.$api
			.post(`/courses/user/${userID}/${courseID}/leave`)
			.then(({ data }) => {
				if (data.success) {
					commit('LEAVE_COURSE', courseID)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	joinCourse({ commit, dispatch }, { userID, courseID }) {
		return this.$api
			.post(`/courses/user/${userID}/${courseID}/join`)
			.then(({ data }) => {
				if (data.success) {
					commit('JOIN_COURSE', data.course)
				} else {
					dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	share({ dispatch }, { userID, course, gDriveEmail }) {
		return this.$api
			.post(`/courses/user/${userID}/${course.id}/share`, {
				gDriveEmail
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	unshare({ dispatch }, { userID, course }) {
		return this.$api
			.post(`/courses/user/${userID}/${course.id}/unshare`)
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_COURSES: (state, courses) => (state.courses = courses),
	SET_COURSE: (state, course) => (state.course = course)
}
