export const namespaced = true

export const state = () => ({ universities: [], university: null })

export const getters = {
	universities: (state) => state.universities,
	university: (state) => state.university
}

export const actions = {
	fetchUniversities({ commit, dispatch }) {
		commit('SET_UNIVERSITIES', [])
		return this.$api
			.get('/universities')
			.then(({ data }) => {
				if (data.success) {
					commit('SET_UNIVERSITIES', data.universities)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	fetchUniversity({ commit, dispatch }, universityID) {
		commit('SET_UNIVERSITY', null)
		return this.$api
			.get(`/universities/${universityID}`)
			.then(({ data }) => {
				if (data.success) {
					commit('SET_UNIVERSITY', data.university)
				}
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	addUniversity({ dispatch }, { name, acronym }) {
		return this.$api
			.put('/universities/add', {
				name,
				acronym
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	editUniversity({ dispatch }, { universityID, name, acronym }) {
		return this.$api
			.post(`/universities/${universityID}/edit`, {
				name,
				acronym
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {
	SET_UNIVERSITIES: (state, universities) => (state.universities = universities),
	SET_UNIVERSITY: (state, university) => (state.university = university)
}
