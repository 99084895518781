<template>
	<v-container fluid>
		<h2 class="text-center">{{ $t('nav.configuration') }}</h2>

		<v-row>
			<v-col cols="12" lg="3" md="4">
				<v-card max-width="600" rounded="xl">
					<v-card-title>
						<span class="text-h5">Emails</span>
					</v-card-title>
					<v-card-text>
						<v-form>
							<template v-for="setting in settingsEmails">
								<template v-if="setting.type == 'bool'">
									<v-switch v-model="setting.value" :key="setting.id" required true-value="1" false-value="0" @change="setSetting(setting)">
										<template #label>
											<span style="word-break: break-all">{{ setting.name }}</span>
										</template>
									</v-switch>
								</template>
								<template v-else>
									<v-text-field
										v-model="setting.value"
										:key="setting.id"
										:type="setting.type"
										:label="`${setting.name} (${setting.unit.toUpperCase()})`"
										required
										@blur="setSetting(setting)"
									/>
								</template>
							</template>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" lg="3" md="4">
				<v-card max-width="600" rounded="xl">
					<v-card-title>
						<span class="text-h5">Auth</span>
					</v-card-title>
					<v-card-text>
						<v-form>
							<template v-for="setting in settingsAuth">
								<template v-if="setting.type == 'bool'">
									<v-switch v-model="setting.value" :key="setting.id" required true-value="1" false-value="0" @change="setSetting(setting)">
										<template #label>
											<span style="word-break: break-all">{{ setting.name }}</span>
										</template>
									</v-switch>
								</template>
								<template v-else>
									<v-text-field
										v-model="setting.value"
										:key="setting.id"
										:type="setting.type"
										:label="`${setting.name} (${setting.unit.toUpperCase()})`"
										required
										@blur="setSetting(setting)"
									/>
								</template>
							</template>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" lg="3" md="4">
				<v-card max-width="600" rounded="xl">
					<v-card-title>
						<span class="text-h5">Student</span>
					</v-card-title>
					<v-card-text>
						<v-form>
							<template v-for="setting in settingsStudent">
								<template v-if="setting.type == 'bool'">
									<v-switch v-model="setting.value" :key="setting.id" required true-value="1" false-value="0" @change="setSetting(setting)">
										<template #label>
											<span style="word-break: break-all">{{ setting.name }}</span>
										</template>
									</v-switch>
								</template>
								<template v-else>
									<v-text-field
										v-model="setting.value"
										:key="setting.id"
										:type="setting.type"
										:label="`${setting.name} (${setting.unit.toUpperCase()})`"
										required
										@blur="setSetting(setting)"
									/>
								</template>
							</template>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" lg="3" md="4">
				<v-card max-width="600" rounded="xl">
					<v-card-title>
						<span class="text-h5">Offers</span>
					</v-card-title>
					<v-card-text>
						<v-form>
							<template v-for="setting in settingsOffers">
								<template v-if="setting.type == 'bool'">
									<v-switch v-model="setting.value" :key="setting.id" required true-value="1" false-value="0" @change="setSetting(setting)">
										<template #label>
											<span style="word-break: break-all">{{ setting.name }}</span>
										</template>
									</v-switch>
								</template>
								<template v-else>
									<v-text-field
										v-model="setting.value"
										:key="setting.id"
										:type="setting.type"
										:label="`${setting.name} (${setting.unit.toUpperCase()})`"
										required
										@blur="setSetting(setting)"
									/>
								</template>
							</template>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" lg="3" md="4">
				<v-card max-width="600" rounded="xl">
					<v-card-title>
						<span class="text-h5">Course</span>
					</v-card-title>
					<v-card-text>
						<v-form>
							<template v-for="setting in settingsCourse">
								<template v-if="setting.type == 'bool'">
									<v-switch v-model="setting.value" :key="setting.id" required true-value="1" false-value="0" @change="setSetting(setting)">
										<template #label>
											<span style="word-break: break-all">{{ setting.name }}</span>
										</template>
									</v-switch>
								</template>
								<template v-else>
									<v-text-field
										v-model="setting.value"
										:key="setting.id"
										:type="setting.type"
										:label="`${setting.name} (${setting.unit.toUpperCase()})`"
										required
										@blur="setSetting(setting)"
									/>
								</template>
							</template>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" lg="3" md="4">
				<v-card max-width="600" rounded="xl">
					<v-card-title>
						<span class="text-h5">Companies</span>
					</v-card-title>
					<v-card-text>
						<v-form>
							<template v-for="setting in settingsCompanies">
								<template v-if="setting.type == 'bool'">
									<v-switch v-model="setting.value" :key="setting.id" required true-value="1" false-value="0" @change="setSetting(setting)">
										<template #label>
											<span style="word-break: break-all">{{ setting.name }}</span>
										</template>
									</v-switch>
								</template>
								<template v-else>
									<v-text-field
										v-model="setting.value"
										:key="setting.id"
										:type="setting.type"
										:label="`${setting.name} (${setting.unit.toUpperCase()})`"
										required
										@blur="setSetting(setting)"
									/>
								</template>
							</template>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" lg="3" md="4">
				<v-card max-width="600" rounded="xl">
					<v-card-title>
						<span class="text-h5">User Limits</span>
					</v-card-title>
					<v-card-text>
						<v-form>
							<template v-for="setting in settingsUsersLimit">
								<template v-if="setting.type == 'bool'">
									<v-switch v-model="setting.value" :key="setting.id" required true-value="1" false-value="0" @change="setSetting(setting)">
										<template #label>
											<span style="word-break: break-all">{{ setting.name }}</span>
										</template>
									</v-switch>
								</template>
								<template v-else>
									<v-text-field
										v-model="setting.value"
										:key="setting.id"
										:type="setting.type"
										:label="`${setting.name} (${setting.unit.toUpperCase()})`"
										required
										@blur="setSetting(setting)"
									/>
								</template>
							</template>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>

			<v-col cols="12" lg="3" md="4">
				<v-card max-width="600" rounded="xl">
					<v-card-title>
						<span class="text-h5">Activity</span>
					</v-card-title>
					<v-card-text>
						<v-form>
							<template v-for="setting in settingsActivity">
								<template v-if="setting.type == 'bool'">
									<v-switch v-model="setting.value" :key="setting.id" required true-value="1" false-value="0" @change="setSetting(setting)">
										<template #label>
											<span style="word-break: break-all">{{ setting.name }}</span>
										</template>
									</v-switch>
								</template>
								<template v-else>
									<v-text-field
										v-model="setting.value"
										:key="setting.id"
										:type="setting.type"
										:label="`${setting.name} (${setting.unit.toUpperCase()})`"
										required
										@blur="setSetting(setting)"
									/>
								</template>
							</template>
						</v-form>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<template>
			<v-form>
				<v-container>
					<v-row>
						<template v-for="setting in settingsOthers">
							<v-col cols="12" md="4" :key="setting.id">
								<template v-if="setting.type == 'bool'">
									<v-switch v-model="setting.value" :key="setting.id" required true-value="1" false-value="0" @change="setSetting(setting)">
										<template #label>
											<span style="word-break: break-all">{{ setting.name }}</span>
										</template>
									</v-switch>
								</template>
								<template v-else>
									<v-text-field
										v-model="setting.value"
										:key="setting.id"
										:type="setting.type"
										:label="`${setting.name} (${setting.unit.toUpperCase()})`"
										required
										@blur="setSetting(setting)"
									/>
								</template>
							</v-col>
						</template>
					</v-row>
				</v-container>
			</v-form>
		</template>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('nav.configuration')
		}
	},
	data() {
		return {
			settingsEditable: []
		}
	},
	computed: {
		settingsEmails() {
			return this.settingsEditable.filter((setting) => setting.name.startsWith('EMAIL_'))
		},
		settingsAuth() {
			return this.settingsEditable.filter((setting) => setting.name.startsWith('AUTH_'))
		},
		settingsStudent() {
			return this.settingsEditable.filter((setting) => setting.name.startsWith('STUDENT_'))
		},
		settingsCourse() {
			return this.settingsEditable.filter((setting) => setting.name.startsWith('COURSE_'))
		},
		settingsOffers() {
			return this.settingsEditable.filter((setting) => setting.name.startsWith('OFFERS_'))
		},
		settingsCompanies() {
			return this.settingsEditable.filter((setting) => setting.name.startsWith('COMPANIES_'))
		},
		settingsUsersLimit() {
			return this.settingsEditable.filter((setting) => setting.name.startsWith('USERS_LIMIT_'))
		},
		settingsActivity() {
			return this.settingsEditable.filter((setting) => setting.name.startsWith('LOG_'))
		},
		settingsOthers() {
			return this.settingsEditable.filter(
				(setting) =>
					!this.settingsEmails.includes(setting) &&
					!this.settingsAuth.includes(setting) &&
					!this.settingsStudent.includes(setting) &&
					!this.settingsCourse.includes(setting) &&
					!this.settingsOffers.includes(setting) &&
					!this.settingsCompanies.includes(setting) &&
					!this.settingsUsersLimit.includes(setting) &&
					!this.settingsActivity.includes(setting)
			)
		},

		...mapGetters({
			settings: 'config/settings'
		})
	},
	created() {
		this.fetchSettings().then(() => {
			this.settingsEditable = this.settings
		})
	},
	methods: {
		callSetSetting(setting) {
			this.setSetting(setting)
		},
		...mapActions('config', ['fetchSettings', 'setSetting'])
	}
}
</script>
