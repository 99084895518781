<template>
	<div>
		<div class="text-center">
			<v-pagination :value="page" :length="pages" :total-visible="20" @input="goToPage" />
		</div>

		<v-data-table
			:headers="headers"
			:items="users"
			:page.sync="page"
			:items-per-page="perPage"
			:server-items-length="totalData"
			:search="search"
			:loading="loading"
			hide-default-footer
			class="elevation-1"
		>
			<template #top>
				<div class="d-flex flex-wrap">
					<v-spacer />
					<div style="max-width: 100px">
						<v-select
							v-model="verified"
							:items="verifiedOptions"
							:label="$t('users.verified')"
							return-object
							item-text="name"
							item-value="value"
							@change="updateTable()"
						/>
					</div>
					<div style="max-width: 100px">
						<v-select v-model="perPage" type="number" :items="perPageOptions" solo @change="updateTable()" />
					</div>
					<div style="min-width: 400px">
						<v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search.search')" single-line hide-details @blur="updateTable()" />
					</div>
				</div>
			</template>
			<template #item.edit="{ item }">
				<v-btn icon :to="{ name: 'User', params: { id: item.id } }">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
			</template>
			<template #item.verified="{ item }">
				<v-icon :color="item.verified ? 'green' : 'red'">
					{{ item.verified ? 'mdi-check' : 'mdi-close-circle-outline' }}
				</v-icon>
			</template>
			<template #footer> Showing {{ (page - 1) * perPage + 1 }} - {{ page * perPage }} of {{ totalData }} </template>
		</v-data-table>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UsersTable',
	data() {
		return {
			loading: false,
			pages: 1,
			page: 1,
			perPage: 15,
			perPageOptions: [5, 10, 15, 25, 30, 40, 50],
			totalData: 0,
			headers: [
				{ text: 'Edit', value: 'edit' },
				{ text: 'User ID', value: 'id' },
				{ text: this.$t('auth.name'), value: 'name.full' },
				{ text: this.$t('auth.email'), value: 'email' },
				{ text: this.$t('users.verified'), value: 'verified' }
			],
			verified: null,
			verifiedOptions: [
				{ name: 'Any', value: '' },
				{ name: 'Yes', value: 'y' },
				{ name: 'No', value: 'n' }
			],
			search: ''
		}
	},
	computed: {
		...mapGetters({
			users: 'users/users'
		})
	},
	created() {
		this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
		this.perPage = this.$route.query.perPage ? parseInt(this.$route.query.perPage) : 15
		this.search = this.$route.query.search ? this.$route.query.search : ''
		if (this.$route.query.verified == 'y') {
			this.verified = { name: 'Yes', value: 'y' }
		} else if (this.$route.query.verified == 'n') {
			this.verified = { name: 'No', value: 'n' }
		} else {
			this.verified = { name: 'Any', value: '' }
		}
		this.updateTable()
	},
	methods: {
		goToPage(page) {
			this.page = page
			this.updateTable()
		},
		updateQuery() {
			this.$router.replace({
				name: this.$route.name,
				query: { page: this.page, perPage: this.perPage, search: this.search, verified: this.verified.value }
			})
		},
		updateTable() {
			this.fetchData(this.page, this.perPage, this.search, this.verified.value)
			this.updateQuery()
		},
		fetchData(page, perPage, search, verified) {
			this.loading = true
			this.fetchUsers({ page, perPage, search, verified })
				.then(({ pages, totalData }) => {
					this.pages = pages
					this.totalData = totalData
				})
				.then(() => {
					this.loading = false
				})
		},
		...mapActions('users', ['fetchUsers'])
	}
}
</script>
