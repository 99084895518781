export const namespaced = true

export const state = () => ({})

export const getters = {}

export const actions = {
	sendVerifyInvoiceEmail({ dispatch }, { companyID }) {
		return this.$api
			.post(`/invoices/verify/send/${companyID}`)
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	}
}

export const mutations = {}
