export const namespaced = true

export const state = () => ({
	accessToken: localStorage.getItem('accessToken') || null
})

export const getters = {
	authenticated: (state) => !!state.accessToken,
	accessToken: (state) => state.accessToken
}

export const actions = {
	refreshAccess({ commit, dispatch }) {
		return this.$api
			.post('/auth/refresh')
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				if (data.success) {
					this.$api.defaults.headers.common.Authorization = 'Bearer ' + data.accessToken
					this.$i18n.locale = data.currentUser.locale.key
					commit('auth/AUTH_SUCCESS', data.accessToken, { root: true })
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
					dispatch('user/fetchStyleTheme', null, { root: true })
				} else {
					commit('auth/AUTH_LOGOUT', null, { root: true })
					commit('user/REMOVE_CURRENT_USER', null, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				commit('auth/AUTH_LOGOUT', null, { root: true })
				commit('user/REMOVE_CURRENT_USER', null, { root: true })
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	login({ commit, dispatch }, { email, password, rememberMe }) {
		return this.$api
			.post('/auth/login', {
				email,
				password,
				rememberMe
			})
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				if (data.success) {
					this.$api.defaults.headers.common.Authorization = 'Bearer ' + data.accessToken
					this.$i18n.locale = data.currentUser.locale.key
					commit('auth/AUTH_SUCCESS', data.accessToken, { root: true })
					commit('user/SET_CURRENT_USER', data.currentUser, { root: true })
					dispatch('user/fetchStyleTheme', null, { root: true })
				} else {
					commit('auth/AUTH_LOGOUT', null, { root: true })
					commit('user/REMOVE_CURRENT_USER', null, { root: true })
				}
				return data
			})
			.catch((error) => {
				console.log(error)
				commit('auth/AUTH_LOGOUT', null, { root: true })
				commit('user/REMOVE_CURRENT_USER', null, { root: true })
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
			})
	},
	logout({ commit, dispatch }) {
		return this.$api
			.post('/auth/logout')
			.then(({ data }) => {
				dispatch('snackbar/push', { success: data.success, message: data.response }, { root: true })
				commit('auth/AUTH_LOGOUT', null, { root: true })
				commit('user/REMOVE_CURRENT_USER', null, { root: true })
				commit('user/SET_STYLE_THEME', 'light', { root: true })
				return data
			})
			.catch((error) => {
				console.log(error)
				dispatch('snackbar/push', { success: false, message: 'Server Error - Contact administrators' }, { root: true })
				commit('auth/AUTH_LOGOUT', null, { root: true })
				commit('user/REMOVE_CURRENT_USER', null, { root: true })
				commit('user/SET_STYLE_THEME', 'light', { root: true })
			})
	}
}

export const mutations = {
	AUTH_SUCCESS: (state, accessToken) => {
		state.accessToken = accessToken
		localStorage.setItem('accessToken', accessToken)
	},
	AUTH_LOGOUT: (state) => {
		state.accessToken = null
		localStorage.removeItem('accessToken')
	}
}
