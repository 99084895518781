<template>
	<v-container fluid style="max-width: 1400px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('nav.companies') }}</span>
		</div>

		<v-divider class="my-2" />

		<div class="d-flex align-center">
			<v-icon class="mx-4">
				mdi-magnify
			</v-icon>
			<v-text-field v-model="name" :placeholder="$t('search.search')" />
			<v-dialog v-model="filtersEditMode" max-width="1600px">
				<template v-slot:activator="{ on }">
					<v-btn color="secondary" v-on="on" elevation="2" rounded small text>
						<v-icon class="mx-4">
							mdi-filter
						</v-icon>
						{{ $t('search.filter') }}
					</v-btn>
				</template>
				<v-card rounded="xl">
					<v-card-title>
						<span class="headline">{{ $t('search.filters') }}</span>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12" md="12" sm="12">
								<v-autocomplete
									v-model="sectors"
									:label="$t('companies.sectors')"
									:items="availableSectors"
									item-text="name"
									item-value="key"
									chips
									multiple
									return-object
									outlined
								>
									<template v-slot:selection="data">
										<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
											{{ data.item.name }}
										</v-chip>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn color="blue darken-1" text @click="filtersSwitchEditMode()">
							{{ $t('search.close') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>

		<v-fade-transition group hide-on-leave>
			<template v-if="loading">
				<v-row key="loadingResults" no-gutters>
					<v-col cols="12" xl="3" lg="4" md="6" sm="12" v-for="i in 12" :key="`loadingResult${i}`" class="px-1">
						<v-skeleton-loader type="card" class="my-2 mx-auto flex-grow-1 rounded-xl" max-width="1200" />
					</v-col>
				</v-row>
			</template>

			<template v-else-if="companies.length">
				<v-row key="filteresResults" no-gutters>
					<v-col
						cols="12"
						xl="3"
						lg="4"
						md="6"
						sm="12"
						v-for="company in filteredCompanies"
						:key="`company${company.id}`"
						class="px-1 d-flex flex-column"
					>
						<CompanySearchCard :company="company" />
					</v-col>
				</v-row>

				<template v-if="!filteredCompanies.length">
					<h4 class="m-4 text-center" key="noResultsCriteria">
						{{ $t('search.noResultsCriteria') }}
					</h4>
				</template>
			</template>

			<template v-else>
				<div key="noItemsData" class="mx-auto">
					<div class="mt-2 mb-5 text-center">
						<v-icon size="128" role="img">
							mdi-package-variant
						</v-icon>
						<br />
						<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
					</div>
				</div>
			</template>
		</v-fade-transition>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function(letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function(match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	metaInfo() {
		return {
			title: this.$t('nav.companies')
		}
	},
	data() {
		return {
			loading: false,
			name: '',
			sectors: [],
			filtersEditMode: false
		}
	},
	components: {
		CompanySearchCard: () => import('@/components/companies/CompanySearchCard.vue')
	},
	computed: {
		filteredCompanies() {
			return this.companies.filter(
				(company) =>
					(!this.sectors.length || company.info.sectors.some((element) => this.sectors_id.includes(element.id))) &&
					removeAccents(company.name)
						.toLowerCase()
						.includes(removeAccents(this.name).toLowerCase())
			)
		},
		sectors_id() {
			return this.sectors.map((element) => element.id)
		},
		...mapGetters({
			companies: 'companies/companies',
			availableSectors: 'cv/availableSectors'
		})
	},
	created() {
		this.fetchAvailableSectors({ keywords: '' })
		this.loading = true
		this.fetchCompanies().then(() => {
			this.loading = false
		})
	},
	methods: {
		filtersSwitchEditMode() {
			this.filtersEditMode = !this.filtersEditMode
		},
		...mapActions('companies', ['fetchCompanies']),
		...mapActions('cv', ['fetchAvailableSectors'])
	}
}
</script>
