import Vue from 'vue'
import Vuex from 'vuex'

import * as applications from './modules/applications.js'
import * as attendances from './modules/attendances.js'
import * as auth from './modules/auth.js'
import * as companies from './modules/companies.js'
import * as cv from './modules/cv.js'
import * as data from './modules/data.js'
import * as documents from './modules/documents.js'
import * as events from './modules/events.js'
import * as feed from './modules/feed.js'
import * as gdriveemails from './modules/gdriveemails.js'
import * as offers from './modules/offers.js'
import * as sidebar from './modules/sidebar.js'
import * as dialog from './modules/dialog.js'
import * as snackbar from './modules/snackbar.js'
import * as settings from './modules/settings.js'
import * as invoices from './modules/invoices.js'
import * as courses from './modules/courses.js'
import * as domains from './modules/domains.js'
import * as universities from './modules/universities.js'
import * as faculties from './modules/faculties.js'
import * as degrees from './modules/degrees.js'
import * as gdrives from './modules/gdrives.js'
import * as students from './modules/students.js'
import * as studies from './modules/studies.js'
import * as config from './modules/config.js'
import * as emails from './modules/emails.js'
import * as subscriptions from './modules/subscriptions.js'
import * as users from './modules/users.js'
import * as user from './modules/user.js'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		applications,
		attendances,
		auth,
		companies,
		cv,
		data,
		documents,
		events,
		feed,
		gdriveemails,
		offers,
		sidebar,
		dialog,
		snackbar,
		settings,
		invoices,
		courses,
		domains,
		universities,
		faculties,
		degrees,
		gdrives,
		students,
		studies,
		config,
		emails,
		subscriptions,
		users,
		user
	},
	plugins: []
})
