<template>
	<v-dialog v-model="editMode" persistent max-width="600px">
		<template v-slot:activator="{ on }">
			<v-btn icon color="primary" v-on="on">
				<v-icon>mdi-pencil</v-icon>
			</v-btn>
		</template>
		<v-card rounded="xl">
			<v-card-title>
				<span class="headline">{{ $t('faculties.faculty') }}</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="form" v-model="valid">
					<v-text-field v-model="name" :label="$t('auth.name')" :counter="nameMaxLength" :rules="rules.name" outlined />
					<v-text-field
						:value="`[${faculty.university.acronym}] ${faculty.university.name}`"
						:label="$t('universities.university')"
						outlined
						readonly
					/>
					<v-text-field v-model="acronym" :label="$t('courses.acronym')" :counter="acronymMaxLength" :rules="rules.acronym" outlined />
					<v-text-field v-model="discord" :label="$t('faculties.discord')" :counter="discordMaxLength" :rules="rules.discord" outlined />
					<v-select
						v-model="domains"
						:items="availableDomains"
						:label="$t('faculties.studentEmailDomain')"
						item-text="name"
						item-value="id"
						return-object
						multiple
						chips
						prepend-icon="mdi-at"
						outlined
					/>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn color="blue darken-1" text @click="switchEditMode()">{{ $t('search.close') }}</v-btn>
				<v-btn color="blue darken-1" text @click="callUpdate()">{{ $t('settings.edit') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'FacultyEditForm',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: true,
			editMode: false,
			valid: false,
			name: '',
			acronym: '',
			discord: '',
			domains: [],
			rules: {
				name: [
					(v) => !!v || 'Name is required.',
					(v) => (v && v.length <= this.nameMaxLength) || `Name must be less than ${this.nameMaxLength} characters`
				],
				acronym: [
					(v) => !!v || 'Acronym is required.',
					(v) => (v && v.length <= this.acronymMaxLength) || `Acronym must be less than ${this.acronymMaxLength} characters`
				],
				discord: [(v) => 0 < v.length <= this.discordMaxLength || `Discord must be less than ${this.discordMaxLength} characters`]
			}
		}
	},
	computed: {
		nameMaxLength() {
			return 128
		},
		acronymMaxLength() {
			return 32
		},
		discordMaxLength() {
			return 128
		},
		...mapGetters({
			faculty: 'faculties/faculty',
			availableDomains: 'domains/domains'
		})
	},
	created() {
		this.name = this.faculty.name
		this.acronym = this.faculty.acronym
		this.discord = this.faculty.discord
		this.domains = this.faculty.domains
		this.fetchDomains().then(() => {
			this.loading = false
		})
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		callUpdate() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.editFaculty({
				facultyID: this.faculty.id,
				name: this.name,
				acronym: this.acronym,
				discord: this.discord,
				domains: this.domains
			}).then(() => {
				this.switchEditMode()
				this.loading = false
			})
		},
		...mapActions('faculties', ['editFaculty']),
		...mapActions('domains', ['fetchDomains'])
	}
}
</script>
